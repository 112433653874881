// src/components/SEO.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import keywordsData from '../config/keywords.json';  // Importar el archivo JSON de palabras clave

const SEO = () => {
  const [metaKeywords, setMetaKeywords] = useState('');

  useEffect(() => {
    if (keywordsData && keywordsData.keywords) {
      // Unir todas las palabras clave en una sola cadena separada por comas
      setMetaKeywords(keywordsData.keywords.join(', '));
    }
  }, []);

  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="theme-color" content="#6200EA" />
      <meta name="description" content="Consulta los últimos resultados de loterías como Lotería Real, La Primera, Lotería Gana Más y mucho más. Genera números aleatorios y explora estadísticas detalladas para mejorar tus chances de ganar." />
      <meta name="keywords" content={metaKeywords} />  {/* Aquí las palabras clave dinámicas */}
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Tutawin" />
      <link rel="canonical" href="https://tutawin.com/" />

      <meta property="og:title" content="Tutawin - Resultados de Lotería, Números Ganadores y Estadísticas" />
      <meta property="og:description" content="Descubre los últimos resultados de Lotería Real, La Primera, Lotería Gana Más y mucho más. ¡Explora estadísticas y genera tus números ganadores!" />
      <meta property="og:url" content="https://tutawin.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://tutawin.com/logo192.png" />

      <title>Tutawin - Lotería Real, Gana Más, Quiniela y Resultados en Tiempo Real</title>
    </Helmet>
  );
};

export default SEO;
