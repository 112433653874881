import axios from 'axios';
import { useState, useCallback } from 'react';

const API_URL = 'https://www.tutawin.com/api';

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'TutawinFrontend'
    }
});

const handleError = (error, customMessage) => {
    if (error.response) {
        console.error('Server error:', error.response.data);
        throw new Error(`${customMessage}: ${error.response.data.error || 'Unknown server error'}`);
    } else if (error.request) {
        console.error('No response from server:', error.request);
        throw new Error(`${customMessage}: No response from server`);
    } else {
        console.error('Request configuration error:', error.message);
        throw new Error(`${customMessage}: ${error.message}`);
    }
};

async function fetchAnguilaLottery(endpoint, options = {}) {
    try {
        let params = {};
        if (options.all) {
            params.all = 'true';
        } else if (options.startDate && options.endDate) {
            params.startDate = options.startDate;
            params.endDate = options.endDate;
        } else {
            // If no specific options are provided, default to fetching today's results
            const today = new Date().toISOString().split('T')[0];
            params.startDate = today;
            params.endDate = today;
        }

        const response = await axiosInstance.get(`/${endpoint}`, { params });
        return response.data;
    } catch (error) {
        handleError(error, `Error fetching ${endpoint} results`);
    }
}

export const getAnguilaManana = (options) => fetchAnguilaLottery('anguila-manana', options);
export const getAnguila12AM = (options) => fetchAnguilaLottery('anguila-12am', options);
export const getAnguilaTarde = (options) => fetchAnguilaLottery('anguila-tarde', options);
export const getAnguilaNoche = (options) => fetchAnguilaLottery('anguila-noche', options);
export const getCuartetaManana = (options) => fetchAnguilaLottery('cuarteta-manana', options);
export const getCuarteta12AM = (options) => fetchAnguilaLottery('cuarteta-medio-dia', options);
export const getCuartetaTarde = (options) => fetchAnguilaLottery('cuarteta-tarde', options);
export const getCuartetaNoche = (options) => fetchAnguilaLottery('cuarteta-noche', options);

export const useAnguilaApi = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const callApi = useCallback(async (apiFunction, options) => {
        setLoading(true);
        setError(null);
        try {
            const data = await apiFunction(options);
            setLoading(false);
            return data;
        } catch (err) {
            setError(err.message);
            setLoading(false);
            return [];
        }
    }, []);

    return {
        getAnguilaManana: (options) => callApi(getAnguilaManana, options),
        getAnguila12AM: (options) => callApi(getAnguila12AM, options),
        getAnguilaTarde: (options) => callApi(getAnguilaTarde, options),
        getAnguilaNoche: (options) => callApi(getAnguilaNoche, options),
        getCuartetaManana: (options) => callApi(getCuartetaManana, options), 
        getCuarteta12AM: (options) => callApi(getCuarteta12AM, options), 
        getCuartetaTarde: (options) => callApi(getCuartetaTarde, options), 
        getCuartetaNoche: (options) => callApi(getCuartetaNoche, options), 
        loading,
        error,
    };
};