'use client'

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Button, 
  Grid, 
  Paper, 
  Typography, 
  Box,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Zoom,
  Fade,
  Tooltip,
  IconButton,
  useMediaQuery,
  Container,
  Snackbar,
  Alert,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { styled, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { 
  HistoryOutlined, 
  AccessTimeOutlined, 
  Casino, 
  Refresh, 
  Star,
  AttachMoney as MoneyIcon,
  Nature as CloverIcon,
  EmojiEvents as TrophyIcon,
  Whatshot as FireIcon,
  Bolt as LightningIcon,
  Diamond as DiamondIcon,
  EmojiSymbols as DartIcon,
  RocketLaunch as RocketIcon,
  Stars as ShootingStarIcon,
  CardGiftcard as GiftIcon,
  VpnKey as KeyIcon,
  WbSunny as RainbowIcon,
  WbCloudy as SunBehindCloudIcon,
  EmojiObjects as LightbulbIcon,
  TrendingUp as UpTrendIcon,
  MilitaryTech as MedalIcon,
  Info as InfoIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { numberInterpretations } from './numberInterpretations';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#1E88E5' },
    secondary: { main: '#FFC107' },
    background: {
      default: '#ffffff',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: { fontWeight: 700 },
    h5: { fontWeight: 600 },
    h6: { fontWeight: 600 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const GENERATION_LIMIT = 2;
const COOLDOWN_MINUTES = 360;

const luckyIcons = [
  { icon: MoneyIcon, description: "Abundancia y éxito financiero" },
  { icon: CloverIcon, description: "Suerte y buen augurio" },
  { icon: Star, description: "Éxito y brillantez" },
  { icon: TrophyIcon, description: "Victoria y logros" },
  { icon: FireIcon, description: "Racha ganadora" },
  { icon: LightningIcon, description: "Energía y poder" },
  { icon: DiamondIcon, description: "Valor y rareza" },
  { icon: Casino, description: "Azar favorable" },
  { icon: DartIcon, description: "Precisión y acierto" },
  { icon: RocketIcon, description: "Progreso y grandes logros" },
  { icon: ShootingStarIcon, description: "Oportunidad única" },
  { icon: GiftIcon, description: "Recompensa inesperada" },
  { icon: KeyIcon, description: "Clave del éxito" },
  { icon: RainbowIcon, description: "Esperanza y positividad" },
  { icon: SunBehindCloudIcon, description: "Buena fortuna en camino" },
  { icon: LightbulbIcon, description: "Inspiración ganadora" },
  { icon: UpTrendIcon, description: "Crecimiento y progreso" },
  { icon: MedalIcon, description: "Reconocimiento y honor" },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  background: theme.palette.background.paper,
  borderRadius: '12px',
  transition: 'all 0.3s ease-in-out',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    maxWidth: '100%',
  },
}));

const CosmicItem = React.memo(function CosmicItem({ item, isRolling, isMobile }) {
  const [displayItem, setDisplayItem] = useState(item);
  const theme = useTheme();
  
  useEffect(() => {
    if (isRolling) {
      const interval = setInterval(() => {
        if (typeof item === 'string') {
          setDisplayItem(Math.floor(Math.random() * 100).toString().padStart(2, '0'));
        } else {
          setDisplayItem(luckyIcons[Math.floor(Math.random() * luckyIcons.length)]);
        }
      }, 50);
      return () => clearInterval(interval);
    } else {
      setDisplayItem(item);
    }
  }, [isRolling, item]);

  if (typeof displayItem === 'string') {
    return (
      <Box sx={{ 
        width: isMobile ? '80px' : '100px', 
        height: isMobile ? '60px' : '80px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
        },
      }}>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{
            color: theme.palette.common.white,
            fontWeight: 'bold',
            textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
          }}
        >
          {displayItem}
        </Typography>
      </Box>
    );
  } else {
    const IconComponent = displayItem.icon;
    return (
      <Tooltip title={displayItem.description} arrow>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: isMobile ? '80px' : '100px', 
          height: isMobile ? '60px' : '80px',
          background: `linear-gradient(135deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.main})`,
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
          },
        }}>
          <IconComponent
            sx={{ 
              fontSize: isMobile ? 40 : 60, 
              color: theme.palette.common.white,
              filter: 'drop-shadow(2px 2px 2px rgba(0,0,0,0.2))',
            }}
          />
        </Box>
      </Tooltip>
    );
  }
});

const SkeletonCosmicItem = () => (
  <Skeleton 
    variant="rectangular" 
    width={100} 
    height={80} 
    sx={{ 
      borderRadius: '8px',
    }} 
  />
);

export default function CosmicNumberGenerator() {
  const [cosmicItems, setCosmicItems] = useState(['--', '--', '--', '--']);
  const [history, setHistory] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationCount, setGenerationCount] = useState(0);
  const [nextAvailableTime, setNextAvailableTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isRolling, setIsRolling] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [userIP, setUserIP] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchUserIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    fetchUserIP();
  }, []);

  useEffect(() => {
    if (!userIP) return;

    const loadData = async () => {
      await new Promise(resolve => setTimeout(resolve, 1500)); // Simulating API call
      const storedCount = localStorage.getItem(`generationCount_${userIP}`);
      const storedHistory = localStorage.getItem(`history_${userIP}`);
      const storedNextAvailableTime = localStorage.getItem(`nextAvailableTime_${userIP}`);
      
      if (storedCount) setGenerationCount(parseInt(storedCount, 10));
      if (storedHistory) {
        try {
          setHistory(JSON.parse(storedHistory));
        } catch (error) {
          console.error("Error al cargar el historial desde localStorage", error);
        }
      }
      if (storedNextAvailableTime) {
        const nextTime = new Date(storedNextAvailableTime);
        if (nextTime > new Date()) {
          setNextAvailableTime(nextTime);
        } else {
          localStorage.removeItem(`nextAvailableTime_${userIP}`);
          localStorage.removeItem(`generationCount_${userIP}`);
          setGenerationCount(0);
        }
      }
      setLoading(false);
    };

    loadData();
  }, [userIP]);

  useEffect(() => {
    if (!userIP) return;

    localStorage.setItem(`generationCount_${userIP}`, generationCount.toString());
    localStorage.setItem(`history_${userIP}`, JSON.stringify(history));
    if (nextAvailableTime) {
      localStorage.setItem(`nextAvailableTime_${userIP}`, nextAvailableTime.toISOString());
    } else {
      localStorage.removeItem(`nextAvailableTime_${userIP}`);
    }
  }, [generationCount, history, nextAvailableTime, userIP]);

  useEffect(() => {
    if (nextAvailableTime) {
      const interval = setInterval(() => {
        const now = new Date();
        const diff = nextAvailableTime.getTime() - now.getTime();

        if (diff <= 0) {
          clearInterval(interval);
          setRemainingTime(null);
          setGenerationCount(0);
          setNextAvailableTime(null);
          localStorage.removeItem(`generationCount_${userIP}`);
          localStorage.removeItem(`nextAvailableTime_${userIP}`);
          setSnackbarMessage('¡Puedes invocar nuevos números cósmicos ahora!');
          setSnackbarOpen(true);
        } else {
          setRemainingTime(diff);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [nextAvailableTime, userIP]);

  const finishGeneration = useCallback((newItems) => {
    setIsGenerating(false);
    setTimeout(() => setIsRolling(false), 200);

    setHistory(prevHistory => {
      const updatedHistory = [newItems, ...prevHistory];
      localStorage.setItem(`history_${userIP}`, JSON.stringify(updatedHistory));
      return updatedHistory;
    });

    setGenerationCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount >= GENERATION_LIMIT) {
        const now = new Date();
        const cooldownEnd = new Date(now.getTime() + COOLDOWN_MINUTES * 60 * 1000);
        setNextAvailableTime(cooldownEnd);
        setSnackbarMessage(`Has alcanzado el límite de invocaciones. Próxima disponible en ${COOLDOWN_MINUTES / 60} horas.`);
        setSnackbarOpen(true);
      }
      return newCount;
    });
  }, [userIP]);

  const generateCosmicItems = useCallback(() => {
    if (generationCount >= GENERATION_LIMIT) return;

    setIsGenerating(true);
    setIsRolling(true);
    setCosmicItems(['--', '--', '--', '--']);
    
    setTimeout(() => {
      const numbers = new Set();
      while (numbers.size < 2) {
        numbers.add(Math.floor(Math.random() * 100).toString().padStart(2, '0'));
      }
      const icons = new Set();
      while (icons.size < 2) {
        icons.add(luckyIcons[Math.floor(Math.random() * luckyIcons.length)]);
      }
      const newItems = [...numbers, ...icons];
      shuffleArray(newItems);
      setCosmicItems(newItems);
      finishGeneration(newItems);
    }, 1500);
  }, [generationCount, finishGeneration]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const formatTime = useCallback((milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  }, []);

  const clearHistory = useCallback(() => {
    setHistory([]);
    localStorage.removeItem(`history_${userIP}`);
    setSnackbarMessage('Registro cósmico limpiado');
    setSnackbarOpen(true);
  }, [userIP]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleInfoClick = () => {
    setInfoDialogOpen(true);
  };

  const handleInfoClose = () => {
    setInfoDialogOpen(false);
  };

  const memoizedCosmicItems = useMemo(() => (
    cosmicItems.map((item, index) => (
      <Grid item xs={6} sm={3} key={`cosmic-item-${index}`}>
        <Fade in={true} style={{ transitionDelay: `${index * 100}ms` }}>
          <StyledPaper elevation={0}>
            <CosmicItem item={item} isRolling={isRolling} isMobile={isMobile} />
          </StyledPaper>
        </Fade>
      </Grid>
    ))
  ), [cosmicItems, isRolling, isMobile]);

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer maxWidth="md">
        <Box sx={{ 
          width: '100%', 
          padding: isMobile ? '10px' : '40px',
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
          },
        }}>
          <Zoom in={true} style={{ transitionDelay: '300ms' }}>
            <Paper 
              elevation={0}
              sx={{ 
                p: isMobile ? 2 : 4, 
                borderRadius: isMobile ? 0 : '16px', 
                overflow: 'hidden', 
                background: 'white',
              }}
            >
              <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" sx={{ color: theme.palette.primary.main, mb: 4, fontWeight: 'bold' }}>
                Generador de Números Cósmicos
                <Tooltip title="Información sobre los números y símbolos" arrow>
                  <IconButton onClick={handleInfoClick} size="small" sx={{ ml: 1 }}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Grid container spacing={3} sx={{ mb: 4 }}>
                {loading ? (
                  Array(4).fill(0).map((_, index) => (
                    <Grid item xs={6} sm={3} key={`skeleton-${index}`}>
                      <SkeletonCosmicItem />
                    </Grid>
                  ))
                ) : memoizedCosmicItems}
              </Grid>

              <Button 
                variant="contained" 
                color="primary" 
                fullWidth 
                onClick={generateCosmicItems}
                disabled={loading || isGenerating || generationCount >= GENERATION_LIMIT || Boolean(nextAvailableTime)}
                startIcon={isGenerating ? <CircularProgress size={24} color="inherit" /> : <Casino />}
                sx={{ 
                  py: 2, 
                  fontSize: '1.1rem', 
                  fontWeight: 'bold',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 10px rgba(0, 0, 0, 0.15)',
                  },
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                {isGenerating ? 'Invocando...' : 'Invocar Números Cósmicos'}
              </Button>

              {remainingTime && (
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <AccessTimeOutlined sx={{ mr: 1, color: theme.palette.text.secondary }} />
                  <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                    Próxima invocación en: {formatTime(remainingTime)}
                  </Typography>
                </Box>
              )}

              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: theme.palette.text.primary }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <HistoryOutlined sx={{ mr: 1 }} />
                    Registro Cósmico
                  </span>
                  <Tooltip title="Limpiar registro" arrow>
                    <span>
                      <IconButton
                        onClick={clearHistory}
                        disabled={history.length === 0}
                        size="small"
                      >
                        <Refresh />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Typography>
                <List>
                  {loading ? (
                    Array(3).fill(0).map((_, index) => (
                      <Skeleton 
                        key={`history-skeleton-${index}`}
                        variant="rectangular" 
                        height={60} 
                        sx={{ mb: 1, borderRadius: '8px' }} 
                      />
                    ))
                  ) : (
                    history.map((items, index) => (
                      <Fade in={true} key={index} style={{ transitionDelay: `${index * 50}ms` }}>
                        <ListItem sx={{
                          bgcolor: theme.palette.action.hover, 
                          borderRadius: '8px', 
                          mb: 1,
                          transition: 'all 0.3s ease-in-out',
                          '&:hover': {
                            bgcolor: theme.palette.action.selected,
                            transform: 'translateX(8px)',
                          },
                        }}>
                          <ListItemText 
                            primary={
                              <Box display="flex" alignItems="center" flexWrap="wrap">
                                {items.map((item, idx) => (
                                  <Box key={idx} mr={1} mb={1}>
                                    {typeof item === 'string' ? (
                                      <Tooltip title={numberInterpretations[item]?.interpretation || 'Significado desconocido'} arrow>
                                        <Typography 
                                          component="span" 
                                          sx={{ 
                                            fontWeight: 'bold',
                                            color: theme.palette.primary.main,
                                            fontSize: '1.1rem',
                                          }}
                                        >
                                          {item}
                                        </Typography>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title={item.description} arrow>
                                        <Box>
                                          <item.icon sx={{ fontSize: 24, color: theme.palette.secondary.main }} />
                                        </Box>
                                      </Tooltip>
                                    )}
                                  </Box>
                                ))}
                              </Box>
                            }
                            secondary={`Invocación #${history.length - index}`}
                            sx={{
                              '& .MuiListItemText-primary': { 
                                fontWeight: 'medium',
                              },
                              '& .MuiListItemText-secondary': {
                                color: theme.palette.text.secondary,
                                fontSize: '0.875rem'
                              }
                            }}
                          />
                        </ListItem>
                      </Fade>
                    ))
                  )}
                </List>
              </Box>
            </Paper>
          </Zoom>
        </Box>

        {/* Meanings section */}
        <Box sx={{ 
          mt: 4, 
          mb: 4,
          px: isMobile ? 2 : 0,
        }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: theme.palette.primary.main, textAlign: 'center', fontWeight: 'bold' }}>
            Significados de Números y Símbolos
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Significado de los Números</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    {Object.entries(numberInterpretations).map(([number, { dream, interpretation }]) => (
                      <Grid item xs={12} key={number}>
                        <Typography variant="body2">
                          <strong>{number} - {dream}:</strong> {interpretation}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Significado de los Símbolos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    {luckyIcons.map((icon, index) => (
                      <Grid item xs={12} key={index}>
                        <Box display="flex" alignItems="center">
                          <icon.icon sx={{ mr: 1, color: theme.palette.secondary.main }} />
                          <Typography variant="body2">{icon.description}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={infoDialogOpen} onClose={handleInfoClose}>
        <DialogTitle>Información sobre Números y Símbolos</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>Significado de los Números:</Typography>
          {Object.entries(numberInterpretations).map(([number, { dream, interpretation }]) => (
            <Typography key={number}>{number} - {dream}: {interpretation}</Typography>
          ))}
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Significado de los Símbolos:</Typography>
          {luckyIcons.map((icon, index) => (
            <Box key={index} display="flex" alignItems="center" mb={1}>
              <icon.icon sx={{ mr: 1 }} />
              <Typography>{icon.description}</Typography>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoClose} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}