import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Importamos todos los logos
import lotoPoolLogo from '../Imagen/logo/do-lotopool.png';
import lotoReal from '../Imagen/logo/do-lotoreal.png';
import lotopoolreal from '../Imagen/logo/do-lotopoolreal.png';
import pega4RealLogo from '../Imagen/logo/do-pe4real.png';
import loterianacionalnoche from '../Imagen/logo/do-loterianacionalnoche.png';
import loterianacionaltarde from '../Imagen/logo/do-loterianacionaltarde.png';
import juegamaspegamas from '../Imagen/logo/do-juegamaspegamas.png';
import superkinotv from '../Imagen/logo/do-superkinotv.png';
import pegamas3 from '../Imagen/logo/do-pega3mas.png';
import quinielapale from '../Imagen/logo/do-quinielapale.png';
import superpale from '../Imagen/logo/do-superpale.png';
import superpalereal from '../Imagen/logo/superpalereal.png';
import lotomas from '../Imagen/logo/do-lotomas.png';
import elquinielon from '../Imagen/logo/do-elquinielon.png';
import loto5 from '../Imagen/logo/do-loto5.png';
import loteriareal from '../Imagen/logo/do-loteriareal.png';
import tufechareal from '../Imagen/logo/do-tufechareal.png';
import nuevayolreal from '../Imagen/logo/do-nuevayolreal.png';
import laprimera from '../Imagen/logo/do-laprimeralotedom.png';
import quinielalotedom from '../Imagen/logo/do-quinielalotedom.png';
import agarra4lotedom from '../Imagen/logo/do-agarra4lotedom.png';
import superpalelotedom from '../Imagen/logo/do-superpalelotedom.png';
import quemaitomayor from '../Imagen/logo/do-quemaitomayor.png';
import megalotto from '../Imagen/logo/do-megalotto.png';
import mcrepartidera from '../Imagen/logo/do-mega-chances-repartidera.png';
import toca3 from '../Imagen/logo/do-toca-3.png';
import quinielaloteka from '../Imagen/logo/do-quinielaloteka.png';
import megachanceloteka from '../Imagen/logo/do-mega-chances.png';
import lasuerte from '../Imagen/logo/do-lasuerte.png';
import anquilamt from '../Imagen/logo/ang-anguila-manana.png';
import angcuartetamt from '../Imagen/logo/ang-anguila-cuarteta-manana.png';
import kinglotterypick3 from '../Imagen/logo/kinglottery/king-lottery-pick-3.png';
import kinglotterypick4noche from '../Imagen/logo/kinglottery/king-lottery-pick-4-noche.png';
import kinglotterypick3noche from '../Imagen/logo/kinglottery/kinglotterypick3noche.png';
import kinglotterypick4 from '../Imagen/logo/kinglottery/kinglotterypick4.png';
import philipsburgmediodia from '../Imagen/logo/kinglottery/philipsburg-medio-dia.png';
import philipsburgnoche from '../Imagen/logo/kinglottery/philipsburg-noche.png';
import quinielakinglotterydia from '../Imagen/logo/kinglottery/quiniela-king-lottery-dia.png';
import quinielakinglotterynoche from '../Imagen/logo/kinglottery/quiniela-king-lottery-noche.png';
import kinglotterylotopooldia from '../Imagen/logo/kinglottery/kinlotterylotopooldia.png';
import kinglotterylotopoolnoche from '../Imagen/logo/kinglottery/kinglotterylotopoolnoche.png';
import cash4LifeLogo from '../Imagen/logo/us-cash4life.png';
import NewYorkLotto from '../Imagen/logo/us-nylotto.png'
import megaMillionsLogo from '../Imagen/logo/us-megamillions.png';
import powerballLogo from '../Imagen/logo/us-powerball-2x.png';
import uslotterianymt from '../Imagen/logo/us-loterianewyork.png';
import usflorida from '../Imagen/logo/us-loteriaflorida.png';
import quinielaleidsa from '../Imagen/logo/do-quinielaleidsa.png';
import take5 from '../Imagen/logo/us-take5.png'
import nywin4 from '../Imagen/logo/us-win4ny.png'
import pick10 from '../Imagen/logo/us-pick10.png'

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  width: '100%',
  height: '80px',
  position: 'relative',
  overflow: 'hidden',
}));

const LotteryLogoImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  transition: 'all 0.3s ease-in-out',
  filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.1))',
  '&:hover': {
    transform: 'scale(1.05)',
    filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))',
  },
}));

const lotteryLogos = {
  'Loto Pool': lotoPoolLogo,
  'Loto Real': lotoReal,
  'Pega 4 Real': pega4RealLogo,
  'Cash4Life': cash4LifeLogo,
  'Mega Millions': megaMillionsLogo,
  'Powerball': powerballLogo,
  'Florida Noche': usflorida,
  'Florida Día': usflorida,
  'King Lottery Noche': quinielakinglotterynoche,
  'King Lottery Día': quinielakinglotterydia,
  'Pick 3 Día': kinglotterypick3,
  'Pick 4 Día': kinglotterypick4,
  'Pick 3 Noche': kinglotterypick3noche,
  'Pick 4 Noche': kinglotterypick4noche,
  'Philipsburg Medio Día': philipsburgmediodia,
  'Philipsburg Noche': philipsburgnoche,
  'Loto Pool Noche': kinglotterylotopoolnoche,
  'Loto Pool Día': kinglotterylotopooldia,
  'Lotería Nacional': loterianacionalnoche,
  'Gana Más': loterianacionaltarde,
  'Pega Más': juegamaspegamas,
  'Juega Más Pega Más': juegamaspegamas,
  'Super Kino TV': superkinotv,
  'Megalotto': megalotto,
  'Quini Loteka': quinielaloteka,
  'MC Repartidera': mcrepartidera,
  'Toca 3': toca3,
  'Pega 3 Más': pegamas3,
  'Quiniela Pale': quinielapale,
  'Super Pale': superpale,
  'Loto Más': lotomas,
  'El Quinielón Día': elquinielon,
  'El Quinielón Noche': elquinielon,
  'Loto 5': loto5,
  'Primera Noche': laprimera,
  'La Primera Día': laprimera,
  'Quiniela Lotedom': quinielalotedom,
  'Agarra 4': agarra4lotedom,
  'Super Pale Lotedom': superpalelotedom,
  'El Quemaito Mayor': quemaitomayor,
  'La Suerte 12:30': lasuerte,
  'La Suerte 18:00': lasuerte,  
  'Anguila Mañana': anquilamt,
  'Anguila Medio Día': anquilamt,
  'Anguila Tarde': anquilamt,
  'Anguila Noche': anquilamt,
  'Anguila Cuarteta Mañana': angcuartetamt,
  'Cuarteta Medio Día': angcuartetamt,
  'Cuarteta Noche': angcuartetamt,
  'Cuarteta Tarde': angcuartetamt,
  'Cuarteta Mañana': angcuartetamt,
  'New York Tarde': uslotterianymt,
  'New York Noche': uslotterianymt,
  'Win 4 NY': nywin4,
  'Take 5': take5,
  'Pick 10': pick10,
  'Mega Chances': megachanceloteka,
  'Loto Pool Real': lotopoolreal,
  'Lotería Real': loteriareal,
  'Nueva York Real': nuevayolreal,
  'New York Lotto': NewYorkLotto,
  'Loto Pool Leidsa': lotoPoolLogo,
  'Loto - Super Loto Más': lotomas,
  'Super Pale Leidsa': superpale,
  'Super Pale Real': superpalereal,
  'Tu Fecha Real': tufechareal,
  'Quiniela Leidsa': quinielaleidsa,
};

const LotteryLogo = ({ name }) => {
  const logoSrc = lotteryLogos[name];

  if (!logoSrc) {
    return null;
  }

  return (
    <LogoContainer>
      <LotteryLogoImage src={logoSrc} alt={`Logo de ${name}`} />
    </LogoContainer>
  );
};

export default LotteryLogo;