import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, useMediaQuery, useTheme, Divider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LaunchIcon from '@mui/icons-material/Launch';
import { lotteries } from '../components/LotteryData';
import LotteryLogo from '../components/LotteryLogo';
import CountryInfo from '../components/CountryInfo';
import TimerProgressBar from '../components/TimerProgressBar';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #207735, #03490a)',
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ResultNumber = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50px',
  height: '50px',
  margin: theme.spacing(0.5),
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: theme.palette.primary.dark,
  },
}));

const DateDisplay = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  fontWeight: 'medium',
  marginBottom: theme.spacing(1),
}));

const specificLotteries = ['Lotería Nacional', 'Gana Más', 'Pega Más'];

const LoteriaNacional = () => {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const selectedLotteries = lotteries.filter(lottery => specificLotteries.includes(lottery.name));
        const fetchedResults = await Promise.all(
          selectedLotteries.map(async (lottery) => {
            const data = await lottery.fetchFunction();
            return { [lottery.name]: data };
          })
        );
        setResults(Object.assign({}, ...fetchedResults));
      } catch (error) {
        setError('Error al obtener los resultados de las loterías. Intenta nuevamente más tarde.');
        console.error('Error fetching results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'Fecha no disponible';
    try {
      const [day, month, year] = dateString.split('-');
      const date = new Date(year, month - 1, day);
      const formattedDate = date.toLocaleDateString('es-ES', { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      });
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    } catch (error) {
      console.error('Error al formatear la fecha:', error);
      return 'Fecha inválida';
    }
  };

  const renderLotteryCard = (lottery) => (
    <Grid item xs={12} sm={6} lg={4} key={lottery.name}>
      <StyledCard>
      <CardHeader>
        <CountryInfo country={lottery.country} />
           <Typography variant="h6" component="div">
           {lottery.name}
           </Typography>
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          <LotteryLogo name={lottery.name} />
          
          <Divider sx={{ width: '100%', my: 2 }} />
          
          <DateDisplay>
            <Typography variant="subtitle2" fontWeight="bold" color="primary" gutterBottom>
              Último Sorteo
            </Typography>
          </DateDisplay>
          
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="circular" width={50} height={50} style={{ margin: 4 }} />
                ))}
              </Box>
            </>
          ) : !results[lottery.name] ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            results[lottery.name].map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <DateDisplay>
                  <CalendarTodayIcon sx={{ fontSize: 16, mr: 0.5 }} />
                  {formatDate(result.date)}
                </DateDisplay>
                <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
                  {result.numbers.map((number, idx) => (
                    <ResultNumber key={idx} sx={{ fontSize: isSmallScreen ? '1.3rem' : '1.5rem' }}>
                      {number}
                    </ResultNumber>
                  ))}
                </Box>
              </Box>
            ))
          )}
          
          <Divider sx={{ width: '100%', my: 2 }} />
          
          <Box sx={{ width: '100%' }}>
            <TimerProgressBar drawTimes={lottery.drawTimes} name={lottery.name} />
          </Box>
        </CardContent>
      </StyledCard>
    </Grid>
  );

  const selectedLotteries = lotteries.filter(lottery => specificLotteries.includes(lottery.name));

  return (
    <Box sx={{ flexGrow: 1, width: '100%', maxWidth: '1200px', margin: '0 auto', p: { xs: 2, sm: 3 } }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          mb: 4, 
          fontWeight: 'bold', 
          color: 'rgb(58, 71, 84)', 
          textAlign: 'center', 
          fontSize: { xs: '1.5rem', sm: '2rem' },
          textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        Resultados de Loterías
      </Typography>
      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {selectedLotteries.map(renderLotteryCard)}
        </Grid>
      )}

      <Box mt={6} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          endIcon={<LaunchIcon />}
          href="https://loterianacional.gob.do/"
          target="_blank"
          rel="noopener noreferrer"
          size="large"
        >
          Visitar sitio oficial de Lotería Nacional
        </Button>
      </Box>
    </Box>
  );
};

export default LoteriaNacional;