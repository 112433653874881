import React, { useState, useEffect } from 'react';
import { Box, IconButton, useMediaQuery, useTheme, Tooltip } from '@mui/material';
import { Whatshot as FlameIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import { motion, AnimatePresence } from 'framer-motion';

const StyledBolo = styled(motion.div, {
    shouldForwardProp: (prop) => prop !== 'ismobile'
  })`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(135deg, #FFA500, #FF4500);
    color: white;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(255, 69, 0, 0.4);
    cursor: pointer;
    overflow: visible;
    position: relative;
    width: ${(props) => (props.ismobile ? '32px' : '40px')};
    height: ${(props) => (props.ismobile ? '32px' : '40px')};
    font-size: ${(props) => (props.ismobile ? '0.8rem' : '1rem')};
    margin: 5px;
  
    &::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;
      transform: scale(0.5);
      pointer-events: none;
    }
  
    &:hover::before {
      opacity: 1;
      transform: scale(1);
    }
  
    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 69, 0, 0.7);
      }
      70% {
        box-shadow: 0 0 0 10px rgba(255, 69, 0, 0);
      }
      100% {
        box-shadow: 0 0 0 0 rgba(255, 69, 0, 0);
      }
    }
  
    animation: pulse 2s infinite;
  `;
  

const AnimatedBolo = ({ number, index, isMobile }) => {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0 }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
          delay: index * 0.1
        }}
      >
        <StyledBolo
          ismobile={isMobile}
          whileHover={{ 
            scale: 1.1, 
            boxShadow: '0 6px 12px rgba(255, 69, 0, 0.6)',
            background: 'linear-gradient(135deg, #FF4500, #FFA500)',
          }}
          whileTap={{ scale: 0.95 }}
        >
          {number}
        </StyledBolo>
      </motion.div>
    );
  };

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { 
      when: "beforeChildren",
      staggerChildren: 0.1,
      delayChildren: 0.3
    }
  }
};

const StyledFlameIcon = styled(FlameIcon)`
  @keyframes flameAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  animation: flameAnimation 2s infinite;
`;

export default function Hotdaynumber() {
  const [hotNumbers, setHotNumbers] = useState(() => {
    const savedNumbers = localStorage.getItem('hotNumbers');
    return savedNumbers ? JSON.parse(savedNumbers) : Array(6).fill('00');
  });
  const [showNumbers, setShowNumbers] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const generateHotNumbers = () => {
    const newHotNumbers = Array(6).fill(null).map(() =>
      String(Math.floor(Math.random() * 100)).padStart(2, '0')
    );
    setHotNumbers(newHotNumbers);
    localStorage.setItem('hotNumbers', JSON.stringify(newHotNumbers));
    localStorage.setItem('lastGenerationTime', new Date().toISOString());
  };

  useEffect(() => {
    const checkAndUpdateNumbers = () => {
      const now = new Date();
      const lastGenerationTime = localStorage.getItem('lastGenerationTime');
      
      if (!lastGenerationTime) {
        generateHotNumbers();
        return;
      }

      const lastGeneration = new Date(lastGenerationTime);
      const timeDifference = now - lastGeneration;
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      if (hoursDifference >= 24 || (now.getHours() === 22 && now.getMinutes() >= 30 && lastGeneration.getHours() !== 22)) {
        generateHotNumbers();
      } else {
        const savedNumbers = localStorage.getItem('hotNumbers');
        if (savedNumbers) {
          setHotNumbers(JSON.parse(savedNumbers));
        }
      }
    };

    checkAndUpdateNumbers();
    const interval = setInterval(checkAndUpdateNumbers, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setShowNumbers(!isMobile);
  }, [isMobile]);

  const toggleNumbers = () => {
    setShowNumbers(!showNumbers);
  };

  return (
    <Box 
      sx={{ 
        width: '100%', 
        overflow: 'visible',
        my: { xs: 0.5, sm: 1 },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: { xs: 1, sm: 2 },
      }}
    >
      <Tooltip title="Números Más Calientes del Día" arrow placement="top">
        <IconButton
          onClick={toggleNumbers}
          sx={{
            color: 'primary.main',
            fontSize: { xs: '1.75rem', sm: '1.5rem', md: '2rem' },
            background: isMobile ? 'rgba(255, 69, 0, 0.1)' : 'transparent',
            borderRadius: '50%',
            padding: isMobile ? '8px' : '4px',
            '&:hover': {
              background: 'rgba(255, 69, 0, 0.2)',
            },
          }}
        >
          <StyledFlameIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <AnimatePresence mode="wait">
        {showNumbers && (
          <motion.div
            key="numbers-container"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ 
              overflow: 'visible',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: isMobile ? '5px' : '10px'
            }}
          >
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'center', 
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: { xs: 0.5, sm: 1 },
                margin: '-5px', // Compensate for the margin on StyledBolo
              }}
            >
              {hotNumbers.map((number, index) => (
                <AnimatedBolo key={index} number={number} index={index} isMobile={isMobile} />
              ))}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}