import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, Container, Divider, Button, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import EventIcon from '@mui/icons-material/Event';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  getPick3Dia,
  getPick3Noche,
  getPhilipsburgDia,
  getPhilipsburgNoche,
  getPick4Noche,
  getLotoPoolNoche,
  getPick4Dia,
  getLotoPoolMedioDia,
  getKingLotteryNoche,
  getKingLotteryDia
} from '../services/api';
import TimerProgressBar from '../components/TimerProgressBar';
import JackpotDisplay from '../components/JackpotDisplay';
import LotteryLogo from '../components/LotteryLogo';
import CountryInfo from '../components/CountryInfo';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  boxShadow: '2px 2px 5px #d1d1d1, -2px -2px 5px #ffffff',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '4px 4px 10px #c1c1c1, -4px -4px 10px #ffffff',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0 auto',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #FFD700, #FFA500)',
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const ResultNumber = styled(Box)(({ theme, count }) => ({
  margin: theme.spacing(0.5),
  fontSize: count > 2 ? '1.2rem' : '1.5rem',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  height: count > 2 ? '60px' : '48px',
  width: count > 2 ? '60px' : '48px',
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: count > 2 ? theme.spacing(1) : 0,
}));

const DateChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  marginTop: theme.spacing(2),
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
}));

const KingLottery = () => {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const [
          pick3Dia,
          pick3Noche,
          philipsburgDia,
          philipsburgNoche,
          pick4Noche,
          lotoPoolNoche,
          pick4Dia,
          lotoPoolMedioDia,
          kingLotteryNoche,
          kingLotteryDia
        ] = await Promise.all([
          getPick3Dia(),
          getPick3Noche(),
          getPhilipsburgDia(),
          getPhilipsburgNoche(),
          getPick4Noche(),
          getLotoPoolNoche(),
          getPick4Dia(),
          getLotoPoolMedioDia(),
          getKingLotteryNoche(),
          getKingLotteryDia()
        ]);

        setResults({
          'Pick 3 Día': pick3Dia,
          'Pick 3 Noche': pick3Noche,
          'Philipsburg Medio Día': philipsburgDia,
          'Philipsburg Noche': philipsburgNoche,
          'Pick 4 Noche': pick4Noche,
          'Loto Pool Noche': lotoPoolNoche,
          'Pick 4 Día': pick4Dia,
          'Loto Pool Medio Día': lotoPoolMedioDia,
          'King Lottery Noche': kingLotteryNoche,
          'King Lottery Día': kingLotteryDia
        });
      } catch (error) {
        setError('Error al obtener los resultados de King Lottery. Intenta nuevamente más tarde.');
        console.error('Error fetching King Lottery results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const lotteryDrawTimes = {
    'Pick 3 Día': [{ time: '12:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'Pick 3 Noche': [{ time: '20:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'Philipsburg Medio Día': [{ time: '13:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'Philipsburg Noche': [{ time: '20:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'Pick 4 Noche': [{ time: '20:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'Loto Pool Noche': [{ time: '21:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'Pick 4 Día': [{ time: '12:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'Loto Pool Medio Día': [{ time: '13:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'King Lottery Noche': [{ time: '21:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'King Lottery Día': [{ time: '13:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
  };

  const formatDate = (dateString) => {
    if (!dateString || dateString === 'No disponible') return 'Fecha no disponible';
    const [day, month, year] = dateString.split('-');
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('es-ES', { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  const renderLotteryCard = (title, data, icon) => (
    <Grid item xs={12} sm={6} md={4}>
      <StyledCard>
        <CardHeader>
          <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {icon}
            {title}
          </Typography>
          <CountryInfo country="Sint Maarten" />
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          <LotteryLogo name={title} />
          
          <Divider sx={{ width: '100%', my: 2 }} />
          
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="rectangular" width={48} height={48} style={{ margin: 4, borderRadius: 8 }} />
                ))}
              </Box>
            </>
          ) : !data || data.length === 0 ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            data.map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <DateChip>
                  <Typography variant="subtitle2" fontWeight="bold" color="primary" gutterBottom>
                    Último Sorteo
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <EventIcon fontSize="small" />
                    <Typography variant="caption" sx={{ ml: 0.5 }}>{formatDate(result.date)}</Typography>
                  </Box>
                </DateChip>
                
                <Box display="flex" flexWrap="wrap" justifyContent="center" my={2}>
                  {result.numbers.map((number, idx) => (
                    <ResultNumber 
                      key={idx} 
                      count={result.numbers.length}
                    >
                      {number}
                    </ResultNumber>
                  ))}
                </Box>
              </Box>
            ))
          )}
          
          <Divider sx={{ width: '100%', my: 2 }} />
          
          <Box sx={{ width: '100%' }}>
            <TimerProgressBar 
              drawTimes={lotteryDrawTimes[title]} 
              name={title}
            />
          </Box>
          <JackpotDisplay name={title} amount="Varía" />
        </CardContent>
      </StyledCard>
    </Grid>
  );

  return (
    <Container maxWidth="lg" disableGutters={isMobile}>
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        p: { xs: 1, sm: 3 },
        minHeight: '100vh',
      }}>
        <Typography 
          variant="h2" 
          component="h1"
          gutterBottom 
          sx={{ 
            mb: 4, 
            fontWeight: 700, 
            color: '#424d58',
            textAlign: 'center', 
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          Resultados de King Lottery
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : (
          <Grid container spacing={isMobile ? 2 : 3} justifyContent="center">
            {renderLotteryCard('Pick 3 Día', results['Pick 3 Día'], <WbSunnyIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Pick 3 Noche', results['Pick 3 Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Philipsburg Medio Día', results['Philipsburg Medio Día'], <WbTwilightIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Philipsburg Noche', results['Philipsburg Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Pick 4 Noche', results['Pick 4 Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Loto Pool Noche', results['Loto Pool Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Pick 4 Día', results['Pick 4 Día'], <WbSunnyIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Loto Pool Medio Día', results['Loto Pool Medio Día'], <WbTwilightIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('King Lottery Noche', results['King Lottery Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('King Lottery Día', results['King Lottery Día'], <WbSunnyIcon sx={{ mr: 1 }} />)}
          </Grid>
        )}
        <Divider sx={{ my: 3 }} />
        <Box mt={4} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            endIcon={<LaunchIcon />}
            href="https://www.kinglotterysxm.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visitar sitio oficial de King Lottery
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default KingLottery;