import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, useMediaQuery, useTheme, Divider, Container, Tooltip, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getPega3MasLeidsa, getQuinielaLoteka, getLotoPoolLeidsa, getSuperKinoTVLeidsa, getLotomasLeidsa, getSuperPaleLeidsa } from '../services/api';
import EventIcon from '@mui/icons-material/Event';
import LaunchIcon from '@mui/icons-material/Launch';
import TimerProgressBar from '../components/TimerProgressBar';
import JackpotDisplay from '../components/JackpotDisplay';
import LotteryLogo from '../components/LotteryLogo';
import CountryInfo from '../components/CountryInfo';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #031f3f, #041c39)',
  color: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ResultNumber = styled(Box)(({ theme, customcolor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50px',
  height: '50px',
  margin: theme.spacing(0.5),
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: customcolor || theme.palette.primary.main,
  borderRadius: '50%',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: customcolor 
      ? (customcolor === '#002D62' ? '#001F3F' : customcolor === '#BF0D3E' ? '#8B0000' : theme.palette.primary.dark)
      : theme.palette.primary.dark,
  },
}));

const DateChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(2),
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
  },
}));

const lotteries = [
  { 
    name: 'Loto - Super Loto Más', 
    fetchFunction: getLotomasLeidsa, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '20:55', days: ['miércoles', 'sábado'] },
    ],
    hasJackpot: true,
  },
  { 
    name: 'Super Pale', 
    fetchFunction: getSuperPaleLeidsa, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '20:50', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] },
      { time: '15:50', days: ['domingo'] },
    ],
    hasJackpot: false,
  },
  { 
    name: 'Quiniela Leidsa', 
    fetchFunction: getQuinielaLoteka, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '20:50', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] },
      { time: '15:50', days: ['domingo'] },
    ],
    hasJackpot: false,
  },
  { 
    name: 'Pega 3 Más', 
    fetchFunction: getPega3MasLeidsa, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '20:50', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] },
      { time: '15:50', days: ['domingo'] },
    ],
    hasJackpot: false,
  },
  { 
    name: 'Loto Pool', 
    fetchFunction: getLotoPoolLeidsa, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '20:50', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] },
      { time: '15:50', days: ['domingo'] },
    ],
    hasJackpot: true,
  },
  { 
    name: 'Super Kino TV', 
    fetchFunction: getSuperKinoTVLeidsa, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '20:50', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] },
      { time: '15:50', days: ['domingo'] },
    ],
    hasJackpot: true,
  },
];

const LotteryCard = ({ result, formatDate }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledCard>
      <CardHeader>
        <CountryInfo country={result.country} />
        {result.hasJackpot ? (
          <JackpotDisplay name={result.name} />
        ) : (
          <Typography variant="h6" component="h2" sx={{ flex: 1, textAlign: 'right' }}>
            {result.name}
          </Typography>
        )}
      </CardHeader>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
        <LotteryLogo name={result.name} />
        
        <Divider sx={{ width: '100%', my: 2 }} />
        
        <DateChip>
          <Typography variant="subtitle2" fontWeight="bold" color="primary" gutterBottom>
            Último Sorteo
          </Typography>
          <Box display="flex" alignItems="center">
            <EventIcon fontSize="small" />
            <Typography variant="caption">{formatDate(result.date)}</Typography>
          </Box>
        </DateChip>
        
        <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
          {result.numbers.filter(number => number !== '').map((number, idx) => {
            let customcolor = undefined;
            if (result.name === 'Loto - Super Loto Más') {
              if (idx === result.numbers.length - 3) {
                customcolor = '#002D62';
              } else if (idx === result.numbers.length - 2) {
                customcolor = '#BF0D3E';
              }
            }
            return (
              <Tooltip key={idx} title={customcolor ? (customcolor === '#002D62' ? 'Loto Mas' : 'Super Loto') : ''}>
                <ResultNumber customcolor={customcolor} sx={{ fontSize: isSmallScreen ? '1.3rem' : '1.5rem' }}>
                  {number}
                </ResultNumber>
              </Tooltip>
            );
          })}
        </Box>
        
        <Divider sx={{ width: '100%', my: 2 }} />
        
        <Box sx={{ width: '100%' }}>
          <TimerProgressBar 
            drawTimes={result.drawTimes} 
            name={result.name}
          />
        </Box>
      </CardContent>
    </StyledCard>
  );
};

const Leidsa = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const fetchedResults = await Promise.all(
          lotteries.map(async (lottery) => {
            const data = await lottery.fetchFunction();
            return {
              ...lottery,
              numbers: Array.isArray(data) ? data[0].numbers : data.numbers,
              date: Array.isArray(data) ? data[0].date : data.date,
            };
          })
        );
        setResults(fetchedResults);
      } catch (error) {
        setError('Error al obtener los resultados de Leidsa. Intenta nuevamente más tarde.');
        console.error('Error fetching Leidsa results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString || dateString === 'No disponible') return 'Fecha no disponible';
    const [day, month, year] = dateString.split('-');
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('es-ES', { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography 
        variant="h2" 
        component="h1"
        gutterBottom 
        align="center" 
        sx={{ 
          fontWeight: 700, 
          color: 'rgb(58, 71, 84)',
          mb: 4,
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
        }}
      >
        Resultados de Leidsa
      </Typography>

      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {loading
            ? lotteries.map((_, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Skeleton variant="rectangular" height={300} />
                </Grid>
              ))
            : results.map((result, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <LotteryCard result={result} formatDate={formatDate} />
                </Grid>
              ))
          }
        </Grid>
      )}

      <Box mt={6} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          endIcon={<LaunchIcon />}
          href="https://www.leidsa.com/"
          target="_blank"
          rel="noopener noreferrer"
          size="large"
        >
          Visitar sitio oficial de Leidsa
        </Button>
      </Box>
    </Container>
  );
};

export default Leidsa;