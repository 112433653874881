import axios from 'axios';

// Definir la URL base de la API
const API_URL = 'https://www.tutawin.com/api';

// Header personalizado para identificar solicitudes legítimas desde el frontend
const axiosConfig = {
  headers: {
    'x-requested-with': 'TutawinFrontend' // Header especial para distinguir solicitudes del frontend
  }
};

// Función para agregar un jackpot desde el frontend
export const addJackpot = async (lotteryType, drawDate, jackpotAmount) => {
  try {
    // Validar los datos de entrada
    if (!lotteryType || !jackpotAmount) {
      throw new Error('Lottery type and jackpot amount are required');
    }

    // Validar el formato de la fecha (si está presente)
    if (drawDate && !/^\d{4}-\d{2}-\d{2}$/.test(drawDate)) {
      throw new Error('Draw date must be in YYYY-MM-DD format');
    }

    // Asegurar que el monto del jackpot sea un número válido
    const amount = Number(jackpotAmount);
    if (isNaN(amount) || amount <= 0) {
      throw new Error('Jackpot amount must be a valid positive number');
    }

    // Crear el objeto de datos para enviar
    const jackpotData = {
      lotteryType,
      jackpot: amount
    };

    // Agregar drawDate solo si está presente
    if (drawDate) {
      jackpotData.draw_date = drawDate;
    }

    // Usar la URL completa para la solicitud POST
    const response = await axios.post(
      `${API_URL}/jackpot/add-jackpot`,
      jackpotData,
      axiosConfig
    );
    return response.data;
  } catch (error) {
    console.error('Error adding jackpot:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Function to get the jackpot of a specific lottery
export const getJackpot = async (lotteryType) => {
  try {
    if (!lotteryType) {
      throw new Error('Lottery type is required');
    }

    const response = await axios.get(`${API_URL}/jackpot/${encodeURIComponent(lotteryType)}`, axiosConfig);
    console.log('Jackpot response data:', response.data);
    
    if (response.data && response.data.jackpot) {
      return response.data.jackpot;
    } else {
      console.warn(`Unexpected response format for ${lotteryType}:`, response.data);
      return null;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.warn(`Jackpot data not found for ${lotteryType}`);
    } else {
      console.error('Error fetching jackpot:', error.message);
    }
    return null;
  }
};
  
