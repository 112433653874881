import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Tooltip, Box, CircularProgress, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import MoneyBagIcon from '@mui/icons-material/LocalMall';
import { getJackpot } from '../services/jackpotapi';

const StyledJackpotDisplay = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
}));

const JackpotText = styled(Box)(({ theme }) => ({
  fontFamily: "'Digital-7', 'Segment7Standard', monospace",
  fontSize: '1.3rem',
  fontWeight: 'bold',
  color: theme.palette.success.main,
  marginLeft: theme.spacing(1.5),
  textShadow: '0 0 10px rgba(0, 0, 0, 0.6)',
  letterSpacing: '2px',
}));

const StyledMoneyBagIcon = styled(MoneyBagIcon)(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.palette.warning.main,
  animation: `pulse 2s infinite ease-in-out`,
  filter: 'drop-shadow(0 0 5px rgba(255, 215, 0, 0.7))',
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.05)' },
    '100%': { transform: 'scale(1)' },
  },
}));

const DigitalChar = styled(Box)({
  display: 'inline-block',
  width: '0.6em',
  textAlign: 'center',
});

const ALLOWED_LOTTERIES = {
  nylotto: 'New York Lotto',
  LotomasLeidsa: 'Loto - Super Loto Más',
  LotoPoolLeidsa: 'Loto Pool',
  Pega4Real: 'Pega 4 Real',
  megamillions: 'Mega Millions',
  powerball: 'Powerball',
  cash4life: 'Cash4Life'
};

const LotomasLeidsaTooltip = () => (
  <Box>
    <Typography color="inherit" variant="subtitle2" fontWeight="bold">Detalles del acumulado:</Typography>
    <Typography color="inherit" variant="body2">Loto: RD$75,000,000.00</Typography>
    <Typography color="inherit" variant="body2">Loto Más: RD$150,000,000.00</Typography>
    <Typography color="inherit" variant="body2">Super Más: RD$250,000,000.00</Typography>
  </Box>
);

function JackpotDisplay({ name }) {
  const [lastTwoDigits, setLastTwoDigits] = useState(0);
  const [jackpot, setJackpot] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const incrementDigits = useCallback(() => {
    setLastTwoDigits((prev) => (prev + 1) % 100);
  }, []);

  useEffect(() => {
    if (!ALLOWED_LOTTERIES[name]) {
      setError('Lottery type not supported');
      setLoading(false);
      return;
    }

    let isMounted = true;
    const fetchJackpot = async () => {
      try {
        setLoading(true);
        setError(null);
        const fetchedJackpot = await getJackpot(name);
        if (isMounted) {
          if (fetchedJackpot) {
            setJackpot(fetchedJackpot);
          } else {
            setError(`No jackpot data available for ${ALLOWED_LOTTERIES[name]}`);
          }
        }
      } catch (error) {
        console.error(`Error fetching jackpot for ${name}:`, error);
        if (isMounted) {
          setError(`Failed to fetch jackpot for ${ALLOWED_LOTTERIES[name]}`);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchJackpot();
    const interval = setInterval(incrementDigits, 100);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [name, incrementDigits]);

  const tooltipContent = useMemo(() => {
    return name === 'LotomasLeidsa' 
      ? <LotomasLeidsaTooltip /> 
      : "Monto acumulado para el próximo sorteo";
  }, [name]);

  const formattedJackpot = useMemo(() => {
    if (!jackpot) return '';
    return jackpot.slice(0, -2) + lastTwoDigits.toString().padStart(2, '0');
  }, [jackpot, lastTwoDigits]);

  if (!ALLOWED_LOTTERIES[name]) {
    return null;
  }

  if (loading) {
    return <CircularProgress size={24} aria-label="Loading jackpot data" />;
  }

  if (error) {
    return <Typography color="error" role="alert">{error}</Typography>;
  }

  if (!jackpot) {
    return <Typography color="textSecondary">Jackpot data unavailable</Typography>;
  }

  return (
    <Card>
      <CardContent>
        <Tooltip title={tooltipContent} arrow placement="top">
          <StyledJackpotDisplay>
            <StyledMoneyBagIcon aria-hidden="true" />
            <JackpotText aria-label={`Current jackpot for ${ALLOWED_LOTTERIES[name]}: ${formattedJackpot}`}>
              {formattedJackpot.split('').map((char, index) => (
                <DigitalChar key={index}>
                  {char}
                </DigitalChar>
              ))}
            </JackpotText>
          </StyledJackpotDisplay>
        </Tooltip>
      </CardContent>
    </Card>
  );
}

export default JackpotDisplay;