import React, { useState, useEffect } from 'react';
import { Box, Typography, Tooltip, LinearProgress, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Link } from 'react-router-dom';
import { slugify } from '../services/slugify';

const ProgressWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

const TimeInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const NextDrawTime = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 'medium',
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(0.5),
}));

const TimeLeft = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  textAlign: 'center',
}));

const JackpotInfo = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 'medium',
  color: theme.palette.success.main,
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(0.5),
}));

const ProgressLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(0.5),
}));

const ProgressContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  flexGrow: 1,
  marginRight: theme.spacing(1),
  height: 8,
  borderRadius: 4,
  '& .MuiLinearProgress-bar': {
    borderRadius: 4,
  },
}));

function TimerProgressBar({ drawTimes, name, jackpot }) {
  const [timeLeft, setTimeLeft] = useState(0);
  const [progress, setProgress] = useState(0);
  const [nextDrawTime, setNextDrawTime] = useState('');

  // Generar el slug a partir del nombre
  const slug = slugify(name);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const currentDay = now.toLocaleDateString('es-ES', { weekday: 'long' }).toLowerCase();
      let nextDraw = null;
      let minDifference = Infinity;

      if (Array.isArray(drawTimes)) {
        drawTimes.forEach(({ time, days }) => {
          if (days.includes(currentDay)) {
            const [hours, minutes] = time.split(':').map(Number);
            const drawTime = new Date(now);
            drawTime.setHours(hours, minutes, 0, 0);

            if (drawTime <= now) {
              drawTime.setDate(drawTime.getDate() + 1);
            }

            const difference = drawTime.getTime() - now.getTime();
            if (difference < minDifference) {
              minDifference = difference;
              nextDraw = drawTime;
              setNextDrawTime(
                drawTime.toLocaleDateString('es-ES', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              );
            }
          }
        });
      }

      if (!nextDraw) {
        let daysToAdd = 1;
        while (!nextDraw && daysToAdd <= 7) {
          const nextDay = new Date(now);
          nextDay.setDate(nextDay.getDate() + daysToAdd);
          const nextDayName = nextDay.toLocaleDateString('es-ES', { weekday: 'long' }).toLowerCase();

          for (const { time, days } of drawTimes || []) {
            if (days.includes(nextDayName)) {
              const [hours, minutes] = time.split(':').map(Number);
              nextDraw = new Date(nextDay);
              nextDraw.setHours(hours, minutes, 0, 0);
              setNextDrawTime(
                nextDraw.toLocaleDateString('es-ES', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              );
              break;
            }
          }

          daysToAdd++;
        }
      }

      if (nextDraw) {
        const difference = nextDraw.getTime() - now.getTime();
        const totalSeconds = Math.floor(difference / 1000);
        const secondsInDay = 24 * 60 * 60;

        setTimeLeft(totalSeconds);
        setProgress(100 - (totalSeconds / secondsInDay) * 100);
      } else {
        setTimeLeft(0);
        setProgress(0);
        setNextDrawTime('No disponible');
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [drawTimes]);

  const formatTimeLeft = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return (
      <>
        {days > 0 && <span>{days}d </span>}
        {hours > 0 && <span>{hours}h </span>}
        {minutes > 0 && <span>{minutes}m </span>}
        <span>{remainingSeconds}s</span>
      </>
    );
  };

  const hasJackpot = ['Mega Millions', 'Powerball', 'Cash4Life', 'Loto - Super Loto Más'].includes(name);

  return (
    <ProgressWrapper>
      <TimeInfo>
        <NextDrawTime>
          <AccessTimeIcon sx={{ fontSize: '1rem', marginRight: '4px' }} />
          Próximo sorteo
        </NextDrawTime>
        <TimeLeft>{nextDrawTime}</TimeLeft>
        <TimeLeft>{formatTimeLeft(timeLeft)}</TimeLeft>
        {hasJackpot && jackpot && (
          <JackpotInfo>
            <AttachMoneyIcon sx={{ fontSize: '1rem', marginRight: '4px' }} />
            Acumulado: {jackpot}
          </JackpotInfo>
        )}
      </TimeInfo>
      <ProgressContainer>
        <Tooltip
          title={
            <React.Fragment>
              <Typography color="inherit">Próximo sorteo: {nextDrawTime}</Typography>
              <Typography color="inherit">Tiempo restante: {formatTimeLeft(timeLeft)}</Typography>
              {hasJackpot && jackpot && (
                <Typography color="inherit">Acumulado: {jackpot}</Typography>
              )}
            </React.Fragment>
          }
          arrow
        >
          <StyledLinearProgress variant="determinate" value={progress} />
        </Tooltip>
        <Tooltip title="Ver estadísticas" arrow>
          {/* Componente donde se utiliza el enlace */}
          <IconButton
            component={Link}
            to={`/estadisticas/${encodeURIComponent(slug)}`}
            size="small"
            color="primary"
          >
            <BarChartIcon />
          </IconButton>
        </Tooltip>
      </ProgressContainer>
      <ProgressLabel>{`${Math.round(progress)}% completado`}</ProgressLabel>
    </ProgressWrapper>
  );
}

export default TimerProgressBar;
