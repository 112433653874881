import React, { useEffect, useRef } from 'react';
import { Drawer, List, ListItemIcon, ListItemText, useMediaQuery, useTheme, Box, SvgIcon, Backdrop } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarIcon from '@mui/icons-material/Star';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import CasinoIcon from '@mui/icons-material/Casino';

const DRAWER_WIDTH = 240;
const MINI_DRAWER_WIDTH = 72;

const StyledDrawer = styled(Drawer)(({ theme, open, ismobile }) => ({
  width: open ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: open ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
    transition: theme.transitions.create(['width', 'backdrop-filter'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    background: 'linear-gradient(180deg, rgba(1, 27, 54, 0.6), rgba(44, 44, 44, 0.8))',
    backdropFilter: 'blur(10px)',
    borderRight: '1px solid rgba(180, 200, 220, 0.5)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
    [theme.breakpoints.down('sm')]: {
      top: 56,
    },
  },
}));

const ScrollContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(255, 255, 255, 0.5)',
  },
}));

const CrownIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11H5zm14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14v1z" />
  </SvgIcon>
);

const KingLotteryIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 1L9 4L6 2L4 5L2 3L1 7L3 9L5 15L7 17H17L19 15L21 9L23 7L22 3L20 5L18 2L15 4L12 1ZM12 5L14 8L17 8L15 10L16 13L12 11L8 13L9 10L7 8L10 8L12 5Z" />
  </SvgIcon>
);

const LeidsaIcon = (props) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10" fill="currentColor" />
    <circle cx="12" cy="12" r="6" fill="none" stroke="white" strokeWidth="2" />
  </SvgIcon>
);

const LotekaIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-5-9h10v2H7v-2z" />
  </SvgIcon>
);

const SuerteDominicanaIcon = (props) => (
  <SvgIcon {...props}>
    <circle cx="6" cy="12" r="4" fill="currentColor" />
    <circle cx="12" cy="12" r="4" fill="currentColor" />
    <circle cx="18" cy="12" r="4" fill="currentColor" />
  </SvgIcon>
);

const AmericanasIcon = (props) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10" fill="currentColor" />
    <polygon points="12,5 13,10 18,10 14,13 16,18 12,15 8,18 10,13 6,10 11,10" fill="white" />
  </SvgIcon>
);

const StyledListItem = styled(RouterLink)(({ theme, isselected }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: 48,
  justifyContent: 'initial',
  padding: theme.spacing(1, 2.5),
  borderRadius: '8px',
  margin: '4px 8px',
  transition: 'all 0.3s ease',
  textDecoration: 'none',
  backgroundColor: isselected === 'true' ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
  backdropFilter: isselected === 'true' ? 'blur(20px)' : 'none',
  boxShadow: isselected === 'true' ? '0 4px 15px rgba(0, 0, 0, 0.2)' : 'none',
  '&:hover': {
    backgroundColor: isselected === 'true' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(20px)',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
  },
}));

const Sidebar = ({ open, onToggle }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const location = useLocation();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !isLargeScreen && open) {
        onToggle();
      }
    };

    if (!isLargeScreen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isLargeScreen, open, onToggle]);

  const handleItemClick = () => {
    if (!isLargeScreen) {
      onToggle();
    }
  };

  const menuItems = [
    { text: 'Inicio', icon: <HomeIcon />, path: '/' },
    { text: 'Lotería Nacional', icon: <PublicIcon />, path: '/loteria-nacional' },
    { text: 'Lotería Real', icon: <CrownIcon />, path: '/loteria-real' },
    { text: 'Leidsa', icon: <LeidsaIcon />, path: '/leidsa' },
    { text: 'La Primera', icon: <StarHalfIcon />, path: '/la-primera' },
    { text: 'Loteka', icon: <LotekaIcon />, path: '/loteka' },
    { text: 'La Suerte Dominicana', icon: <SuerteDominicanaIcon />, path: '/la-suerte-dominicana' },
    { text: 'Americanas', icon: <AmericanasIcon />, path: '/americanas' },
    { text: 'Anguila', icon: <StarIcon />, path: '/anguila' },
    { text: 'King Lottery', icon: <KingLotteryIcon />, path: '/king-lottery' },
    { text: 'Lotedom', icon: <CasinoIcon />, path: '/lotedom' },
    { text: 'Números Random', icon: <ShuffleIcon />, path: '/gna' }
  ];

  const drawerContent = (
    <ScrollContainer>
      <List>
        {menuItems.map((item) => {
          const isSelected = location.pathname === item.path;
          return (
            <StyledListItem
              to={item.path}
              key={item.text}
              isselected={isSelected.toString()}
              onClick={handleItemClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: isSelected ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)',
                }}
              >
                {item.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={item.text}
                  sx={{
                    opacity: 1,
                    color: isSelected ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)',
                    '& .MuiTypography-root': {
                      fontWeight: isSelected ? 'bold' : 'normal',
                    },
                  }}
                />
              )}
            </StyledListItem>
          );
        })}
      </List>
    </ScrollContainer>
  );

  return (
    <>
      <StyledDrawer
        variant={isLargeScreen ? "permanent" : "temporary"}
        open={isLargeScreen ? open : open}
        onClose={onToggle}
        ismobile={(!isLargeScreen).toString()}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          ref: sidebarRef,
        }}
      >
        {drawerContent}
      </StyledDrawer>
      {!isLargeScreen && open && (
        <Backdrop
          open={open}
          onClick={onToggle}
          sx={{ zIndex: theme.zIndex.drawer - 1 }}
        />
      )}
    </>
  );
};

export default Sidebar;