// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

// Componentes y Páginas
import Layout from './components/Layout';
import Home from './components/Home';
import Gna from './components/Gna';
import NotFoundPage from './pages/404';
import Visitas from './pages/visit';
import LoteriaNacional from './pages/LoteriaNacional';
import LoteriaReal from './pages/LoteriaReal';
import Leidsa from './pages/Leidsa';
import LaPrimera from './pages/LaPrimera';
import Loteka from './pages/Loteka';
import LaSuerteDominicana from './pages/LaSuerteDominicana';
import LoteDom from './pages/LoteDom';
import Americanas from './pages/Americanas'; 
import Anguila from './pages/Anguila';
import KingLottery from './pages/KingLottery';
import LotteryPage from './pages/LotteryPage';
import Addjackpot from './pages/AddJacpot';
import LotteryStatistics from './components/LotteryStatistics';
import { registerVisit } from './services/visitapi';
import SEO from './components/SEO'; 

// Tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const App = () => {
  useEffect(() => {
    const recordVisit = async () => {
      try {
        const response = await registerVisit();
        console.log(response.message);
      } catch (error) {
        console.error('Error al registrar la visita:', error);
      }
    };

    recordVisit();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* Añadimos el componente SEO para gestionar las etiquetas meta */}
      <SEO />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/visitantes" element={<Visitas />} />
            <Route path="/gna" element={<Gna />} />
            <Route path="/loteria-nacional" element={<LoteriaNacional />} />
            <Route path="/loteria-real" element={<LoteriaReal />} />
            <Route path="/leidsa" element={<Leidsa />} />
            <Route path="/la-primera" element={<LaPrimera />} />
            <Route path="/loteka" element={<Loteka />} />
            <Route path="/la-suerte-dominicana" element={<LaSuerteDominicana />} />
            <Route path="/lotedom" element={<LoteDom />} />
            <Route path="/americanas" element={<Americanas />} />
            <Route path="/anguila" element={<Anguila />} />
            <Route path="/king-lottery" element={<KingLottery />} />
            <Route path="/lottery/:lotteryName" element={<LotteryPage />} />
            <Route path="/estadisticas/:slug" element={<LotteryStatistics />} />
            <Route path="/jp404" element={<Addjackpot />} />
            <Route path="*" element={<NotFoundPage />} /> {/* Página 404 para rutas no encontradas */}
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
