import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

export default function ResponsibleGamingMessage() {
  const theme = useTheme();

  return (
    <Box sx={{ mt: 6, textAlign: 'center' }}>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        Juega con responsabilidad. Los juegos de azar deben ser solo para entretenimiento.
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>
        Si tienes problemas con el juego, busca ayuda profesional.
      </Typography>
    </Box>
  );
}