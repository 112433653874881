import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { 
  Container, Typography, Divider, Paper, Box, 
  Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Skeleton, useMediaQuery, useTheme, Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CasinoIcon from '@mui/icons-material/Casino';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PercentIcon from '@mui/icons-material/Percent';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { howToPlay } from '../components/howToPlay';
import LotteryLogo from '../components/LotteryLogo';
import TimerProgressBar from '../components/TimerProgressBar';
import JackpotDisplay from '../components/JackpotDisplay';
import CountryInfo from '../components/CountryInfo';
import { lotteries } from '../components/LotteryData';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3),
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.5rem',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  fontSize: '0.8rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
}));

const NumberBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  margin: theme.spacing(2, 0),
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(1.5),
  },
}));

const LotteryNumber = styled(Box)(({ theme, specialColor }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: specialColor || theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  fontSize: '1rem',
  borderRadius: '50%',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2), inset 0 -2px 4px rgba(0,0,0,0.1), inset 0 2px 4px rgba(255,255,255,0.3)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    width: '200%',
    height: '200%',
    background: 'radial-gradient(circle, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 70%)',
    transform: 'rotate(45deg)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5%',
    left: '5%',
    width: '20%',
    height: '20%',
    borderRadius: '50%',
    backgroundColor: 'rgba(255,255,255,0.6)',
    filter: 'blur(2px)',
  },
  [theme.breakpoints.up('md')]: {
    width: 56,
    height: 56,
    fontSize: '1.25rem',
  },
}));

const DateDisplay = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  fontSize: '0.9rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
}));

const DrawTimeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateX(5px)',
    boxShadow: theme.shadows[2],
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
}));

const DayTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  marginRight: theme.spacing(1),
  fontSize: '0.9rem',
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(2),
    fontSize: '1rem',
  },
}));

const TimeTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: theme.palette.text.secondary,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.1rem',
  },
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#3f4b56',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3),
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
}));

const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    marginBottom: 0,
  },
}));

const JackpotWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const FlagWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  overflow: 'hidden',
  borderRadius: '50%',
  border: '2px solid #fff',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  marginTop: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    width: '40px',
    height: '40px',
    marginLeft: theme.spacing(2),
    marginTop: 0,
  },
}));

const LotteryResults = ({ name }) => {
  const [lotteryData, setLotteryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchLotteryData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const selectedLottery = lotteries.find(lottery => lottery.name === name);
      if (!selectedLottery) {
        throw new Error('Lotería no encontrada');
      }

      const data = await selectedLottery.fetchFunction();
      const latestResult = Array.isArray(data) && data.length > 0 ? data[0] : null;

      setLotteryData({
        ...selectedLottery,
        numbers: latestResult?.numbers || [],
        date: latestResult?.date || 'Fecha no disponible',
        jackpot: latestResult?.jackpot || null,
      });
    } catch (error) {
      setError('Error al obtener los datos de la lotería.');
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [name]);

  useEffect(() => {
    fetchLotteryData();
  }, [fetchLotteryData]);

  const getSpecialColor = useCallback((lotteryName, index) => {
    const colorMap = {
      'Mega Millions': { 5: '#FFD700', 6: '#008000' },
      'Powerball': { 5: '#FFD700', 6: '#008000' },
      'Loto - Super Loto Más': { 6: '#0000FF', 7: '#FF0000' },
      'Nueva York Real': { 3: '#8B4513' },
      'Tu Fecha Real': { all: '#FFA500' },
      'Megalotto': { 6: '#FF0000', 7: '#FFD700' },
      'Cash4Life': { 5: '#a98640' },
    };

    const lotteryColors = colorMap[lotteryName];
    if (lotteryColors) {
      if (lotteryColors.all) return lotteryColors.all;
      return lotteryColors[index] || null;
    }
    return null;
  }, []);

  const renderLotteryNumbers = useCallback(() => {
    if (!lotteryData || !lotteryData.numbers.length) return null;
    
    return (
      <NumberBox>
        {lotteryData.numbers.map((number, idx) => (
          <LotteryNumber
            key={idx}
            specialColor={getSpecialColor(lotteryData.name, idx)}
          >
            {number}
          </LotteryNumber>
        ))}
      </NumberBox>
    );
  }, [lotteryData, getSpecialColor]);

  if (loading) {
    return (
      <Box sx={{ py: 4 }}>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Skeleton variant="text" width="60%" sx={{ mt: 2 }} />
        <Skeleton variant="text" width="40%" sx={{ mt: 1 }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ py: 4 }}>
        <Typography variant="h5" align="center" color="error">{error}</Typography>
      </Box>
    );
  }

  if (!lotteryData || !lotteryData.numbers || lotteryData.numbers.length === 0) {
    return (
      <Box sx={{ py: 4 }}>
        <Typography variant="h5" align="center">No se encontraron resultados.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, position: 'relative' }}>
      <LotteryLogo name={lotteryData.name} />
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#47515a', mb: 1, mt: 2, textTransform: 'uppercase' }}>
        ÚLTIMO RESULTADO
      </Typography>
      <DateDisplay>
        {lotteryData.date}
      </DateDisplay>
      {renderLotteryNumbers()}
      <Box sx={{ width: '100%' }}>
        <TimerProgressBar drawTimes={lotteryData.drawTimes} name={lotteryData.name} jackpot={lotteryData.jackpot} />
      </Box>
    </Box>
  );
};

const LotteryPage = () => {
  const { lotteryName } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [lotteryInfo, setLotteryInfo] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchLotteryInfo = async () => {
      setLoading(true);
      const info = Object.values(howToPlay).find(lottery => lottery.slug === lotteryName);
      setLotteryInfo(info);
      setLoading(false);
    };
    fetchLotteryInfo();
  }, [lotteryName]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Skeleton variant="text" width="60%" sx={{ mt: 2 }} />
        <Skeleton variant="text" width="40%" sx={{ mt: 1 }} />
        <Skeleton variant="rectangular" width="100%" height={400} sx={{ mt: 4 }} />
      </Container>
    );
  }

  if (!lotteryInfo) {
    return <Navigate to="/404" replace />;
  }

  const { name, description, instructions, prizeDetails, drawTimes, country } = lotteryInfo;

  return (
    <Container maxWidth="lg" sx={{ py: 2, px: 2, [theme.breakpoints.up('md')]: { py: 4, px: 3 } }}>
      <HeaderWrapper>
        <TitleWrapper>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: 'white', mr: 2, mb: isMobile ? 2 : 0, fontSize: { xs: '1.5rem', md: '2.125rem' } }}>
            {name}
          </Typography>
          {country && (
            <Tooltip title={`País: ${country.name}, Código: ${country.code}`}>
              <FlagWrapper>
                <CountryInfo country={country} />
              </FlagWrapper>
            </Tooltip>
          )}
        </TitleWrapper>
        <JackpotWrapper>
          <JackpotDisplay name={name} />
        </JackpotWrapper>
      </HeaderWrapper>

      <StyledPaper elevation={3}>
        <Typography variant="body1" sx={{ mb: 4, textAlign: 'left', fontSize: { xs: '0.9rem', md: '1rem' } }}>
          {description}
        </Typography>

        <Divider sx={{ mb: 4 }} />

        <LotteryResults name={name} />
      </StyledPaper>

      <Tabs 
        value={activeTab} 
        onChange={handleTabChange} 
        centered 
        sx={{ mb: 3 }}
        variant={isMobile ? "fullWidth" : "standard"}
      >
        <StyledTab label="Cómo Jugar" />
        <StyledTab label="Probabilidades" />
        <StyledTab label="Premios" />
      </Tabs>

      {activeTab === 0 && (
        <StyledPaper elevation={3}>
          <SectionTitle variant="h4" component="h2">
            <CasinoIcon />
            ¿Cómo jugar?
          </SectionTitle>
          {instructions && instructions.length > 0 ? (
            <ul>
              {instructions.map((instruction, index) => (
                <li key={index}>
                  <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>{instruction}</Typography>
                </li>
              ))}
            </ul>
          ) : (
            <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>No hay instrucciones disponibles.</Typography>
          )}
          <Box mt={4}>
            <SectionTitle variant="h5" component="h3">
              <AccessTimeIcon />
              Horarios de Sorteo
            </SectionTitle>
            {drawTimes && drawTimes.length > 0 ? (
              drawTimes.map((drawTime, index) => (
                <DrawTimeBox key={index}>
                  <CalendarTodayIcon color="primary" sx={{ fontSize: { xs: 20, md: 24 }, mr: { xs: 1, md: 2 } }} />
                  <Box>
                    <DayTypography variant="subtitle1">
                      {drawTime.days.join(', ')}
                    </DayTypography>
                    <TimeTypography variant="body1">
                      {drawTime.time}
                    </TimeTypography>
                  </Box>
                </DrawTimeBox>
              ))
            ) : (
              <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', md: '0.875rem' } }}>No hay horarios de sorteo disponibles.</Typography>
            )}
          </Box>
        </StyledPaper>
      )}

      {activeTab === 1 && prizeDetails?.probabilities && (
        <StyledPaper elevation={3}>
          <SectionTitle variant="h4" component="h2">
            <PercentIcon />
            Probabilidades de ganar
          </SectionTitle>
          {prizeDetails.probabilities && prizeDetails.probabilities.length > 0 ? (
            <TableContainer component={Paper}>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead>
                  <TableRow>
                    <TableCell>Categoría</TableCell>
                    <TableCell>Probabilidad</TableCell>
                    <TableCell>Porcentaje</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prizeDetails.probabilities.map((prob, index) => (
                    <TableRow key={index}>
                      <TableCell>{prob.category}</TableCell>
                      <TableCell>{prob.odds}</TableCell>
                      <TableCell>{prob.percentage}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', md: '0.875rem' } }}>No hay información de probabilidades disponible.</Typography>
          )}
        </StyledPaper>
      )}

      {activeTab === 2 && prizeDetails?.prizes && (
        <StyledPaper elevation={3}>
          <SectionTitle variant="h4" component="h2">
            <EmojiEventsIcon />
            Premios
          </SectionTitle>
          {prizeDetails.prizes && prizeDetails.prizes.length > 0 ? (
            <TableContainer component={Paper}>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead>
                  <TableRow>
                    <TableCell>Categoría</TableCell>
                    <TableCell>Premio Base</TableCell>
                    <TableCell>Power Play</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prizeDetails.prizes.map((prize, index) => (
                    <TableRow key={index}>
                      <TableCell>{prize.category}</TableCell>
                      <TableCell>{prize.basePrize}</TableCell>
                      <TableCell>{prize.powerPlay || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', md: '0.875rem' } }}>No hay información de premios disponible.</Typography>
          )}
        </StyledPaper>
      )}
    </Container>
  );
};

export default LotteryPage;