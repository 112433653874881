import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, useMediaQuery, useTheme, Container, Divider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getLoteDom, getAgarra4Lotedom, getQuemaito, getSuperPaleLotedom } from '../services/api';
import EventIcon from '@mui/icons-material/Event';
import LaunchIcon from '@mui/icons-material/Launch';
import TimerProgressBar from '../components/TimerProgressBar';
import JackpotDisplay from '../components/JackpotDisplay';
import LotteryLogo from '../components/LotteryLogo';
import CountryInfo from '../components/CountryInfo';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  boxShadow: '2px 2px 5px #d1d1d1, -2px -2px 5px #ffffff',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '4px 4px 10px #c1c1c1, -4px -4px 10px #ffffff',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0 auto',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #3f51b5, #2196f3)',
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const ResultNumber = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50px',
  height: '50px',
  margin: theme.spacing(0.5),
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: theme.palette.primary.dark,
  },
}));

const DateChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(2),
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
  },
}));

const Lotedom = () => {
  const [results, setResults] = useState({
    quiniela: [],
    agarra4: [],
    quemaito: [],
    superPale: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const [quiniela, agarra4, quemaito, superPale] = await Promise.all([
          getLoteDom(),
          getAgarra4Lotedom(),
          getQuemaito(),
          getSuperPaleLotedom(),
        ]);
        setResults({ quiniela, agarra4, quemaito, superPale });
      } catch (error) {
        setError('Error al obtener los resultados de Lotedom. Intenta nuevamente más tarde.');
        console.error('Error fetching Lotedom results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const lotteryDrawTimes = {
    'Quiniela Lotedom': [{ time: '20:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'Agarra 4': [{ time: '20:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
    'El Quemaito Mayor': [
      { time: '10:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] },
      { time: '14:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] },
      { time: '18:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] },
    ],
    'Super Pale': [{ time: '20:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
  };

  const renderLotteryCard = (title, data) => (
    <Grid item xs={12} sm={6} md={4}>
      <StyledCard>
        <CardHeader>
          <CountryInfo country="República Dominicana" />
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          <LotteryLogo name={title} />
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="rectangular" width={48} height={48} style={{ margin: 4, borderRadius: 8 }} />
                ))}
              </Box>
            </>
          ) : data.length === 0 ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            data.map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {result.name}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
                  {result.numbers.map((number, idx) => (
                    <ResultNumber key={idx} sx={{ fontSize: isSmallScreen ? '1.3rem' : '1.5rem' }}>
                      {number}
                    </ResultNumber>
                  ))}
                </Box>
                <DateChip>
                  <Typography variant="subtitle2" fontWeight="bold" color="primary" gutterBottom>
                    Último Sorteo
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <EventIcon fontSize="small" />
                    <Typography variant="caption" sx={{ ml: 0.5 }}>{result.date}</Typography>
                  </Box>
                </DateChip>
              </Box>
            ))
          )}
          <Divider sx={{ width: '100%', my: 2 }} />
          <Box sx={{ width: '100%' }}>
            <TimerProgressBar 
              drawTimes={lotteryDrawTimes[title]} 
              name={title}
            />
          </Box>
          <JackpotDisplay name={title} amount="Varía" />
        </CardContent>
      </StyledCard>
    </Grid>
  );

  return (
    <Container maxWidth="lg" disableGutters={isSmallScreen}>
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        p: { xs: 1, sm: 3 },
        minHeight: '100vh',
      }}>
        <Typography 
          variant="h2" 
          component="h1"
          gutterBottom 
          sx={{ 
            mb: 4, 
            fontWeight: 'bold', 
            color: 'rgb(58, 71, 84)', 
            textAlign: 'center', 
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          Resultados de Lotedom
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : (
          <Grid container spacing={isSmallScreen ? 2 : 3} justifyContent="center">
            {renderLotteryCard('Quiniela Lotedom', results.quiniela)}
            {renderLotteryCard('Agarra 4', results.agarra4)}
            {renderLotteryCard('El Quemaito Mayor', results.quemaito)}
            {renderLotteryCard('Super Pale', results.superPale)}
          </Grid>
        )}
        <Divider sx={{ my: 3 }} />
        <Box mt={4} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            endIcon={<LaunchIcon />}
            href="https://www.lotedom.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visitar sitio oficial de Lotedom
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Lotedom;