import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, useTheme, Container, Divider, Button, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAnguila12AM, getAnguilaManana, getAnguilaTarde, getAnguilaNoche, getCuartetaManana, getCuarteta12AM, getCuartetaTarde, getCuartetaNoche } from '../services/anguilaapi';
import EventIcon from '@mui/icons-material/Event';
import LaunchIcon from '@mui/icons-material/Launch';
import CountryInfo from '../components/CountryInfo';
import TimerProgressBar from '../components/TimerProgressBar';
import JackpotDisplay from '../components/JackpotDisplay';
import LotteryLogo from '../components/LotteryLogo';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  boxShadow: '2px 2px 5px #d1d1d1, -2px -2px 5px #ffffff',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '4px 4px 10px #c1c1c1, -4px -4px 10px #ffffff',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0 auto',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #00BCD4, #007B8E)',
  color: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ResultNumber = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  margin: theme.spacing(0.5),
  fontSize: '1.2rem',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: theme.palette.primary.dark,
  },
}));

const DateChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(2),
  backgroundColor: '#f0f0f0',
  border: '1px dashed #00BCD4',
  borderRadius: '16px',
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
  },
}));

const lotteryDrawTimes = {
  'Anguila Mañana': [{ time: '10:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
  'Anguila Medio Día': [{ time: '13:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
  'Anguila Tarde': [{ time: '16:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
  'Anguila Noche': [{ time: '20:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
  'Cuarteta Mañana': [{ time: '10:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
  'Cuarteta Medio Día': [{ time: '13:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
  'Cuarteta Tarde': [{ time: '16:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
  'Cuarteta Noche': [{ time: '20:00', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'] }],
};

const LotteryCard = React.memo(({ title, data, loading }) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <StyledCard>
        <CardHeader>
          <CountryInfo country="Anguila" />
          <Typography variant="h6" component="h2" sx={{ flex: 1, textAlign: 'center' }}>
            {title}
          </Typography>
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          <LotteryLogo name={title} />
          
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="rectangular" width={40} height={40} style={{ margin: 4, borderRadius: theme.spacing(1) }} />
                ))}
              </Box>
            </>
          ) : !data || data.length === 0 ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            data.map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {result.name}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
                  {result.numbers.map((number, idx) => (
                    <ResultNumber key={idx}>
                      {number}
                    </ResultNumber>
                  ))}
                </Box>
                <DateChip>
                  <EventIcon fontSize="small" />
                  <Typography variant="caption">{result.date}</Typography>
                </DateChip>
              </Box>
            ))
          )}
          
          <Divider sx={{ width: '100%', my: 2 }} />
          
          <Box sx={{ width: '100%' }}>
            <TimerProgressBar 
              drawTimes={lotteryDrawTimes[title]} 
              name={title}
            />
          </Box>
          
          <JackpotDisplay name={title} amount="Varía" />
        </CardContent>
      </StyledCard>
    </Grid>
  );
});

const Anguila = () => {
  const [results, setResults] = useState({
    anguilaManana: [],
    anguila12AM: [],
    anguilaTarde: [],
    anguilaNoche: [],
    cuartetaManana: [],
    cuarteta12AM: [],
    cuartetaTarde: [],
    cuartetaNoche: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const [
          anguilaManana,
          anguila12AM,
          anguilaTarde,
          anguilaNoche,
          cuartetaManana,
          cuarteta12AM,
          cuartetaTarde,
          cuartetaNoche
        ] = await Promise.all([
          getAnguilaManana(),
          getAnguila12AM(),
          getAnguilaTarde(),
          getAnguilaNoche(),
          getCuartetaManana(),
          getCuarteta12AM(),
          getCuartetaTarde(),
          getCuartetaNoche(),
        ]);
  
        setResults({
          anguilaManana: Array.isArray(anguilaManana) ? anguilaManana : [],
          anguila12AM: Array.isArray(anguila12AM) ? anguila12AM : [],
          anguilaTarde: Array.isArray(anguilaTarde) ? anguilaTarde : [],
          anguilaNoche: Array.isArray(anguilaNoche) ? anguilaNoche : [],
          cuartetaManana: Array.isArray(cuartetaManana) ? cuartetaManana : [],
          cuarteta12AM: Array.isArray(cuarteta12AM) ? cuarteta12AM : [],
          cuartetaTarde: Array.isArray(cuartetaTarde) ? cuartetaTarde : [],
          cuartetaNoche: Array.isArray(cuartetaNoche) ? cuartetaNoche : [],
        });
      } catch (error) {
        setError('Error al obtener los resultados de Anguila. Intenta nuevamente más tarde.');
        console.error('Error fetching Anguila results:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchResults();
  }, []);

  return (
    <Container maxWidth="lg" disableGutters={isMobile}>
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        p: { xs: 1, sm: 3 },
        minHeight: '100vh',
      }}>
        <Typography 
          variant="h2" 
          component="h1"
          gutterBottom 
          sx={{ 
            mb: 4, 
            fontWeight: 'bold', 
            color: 'rgb(58, 71, 84)',
            textAlign: 'center', 
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          Resultados de Anguila
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : (
          <Grid container spacing={isMobile ? 2 : 3} justifyContent="center">
            <LotteryCard title='Anguila Mañana' data={results.anguilaManana} loading={loading} />
            <LotteryCard title='Anguila Medio Día' data={results.anguila12AM} loading={loading} />
            <LotteryCard title='Anguila Tarde' data={results.anguilaTarde} loading={loading} />
            <LotteryCard title='Anguila Noche' data={results.anguilaNoche} loading={loading} />
            <LotteryCard title='Cuarteta Mañana' data={results.cuartetaManana} loading={loading} />
            <LotteryCard title='Cuarteta Medio Día' data={results.cuarteta12AM} loading={loading} />
            <LotteryCard title='Cuarteta Tarde' data={results.cuartetaTarde} loading={loading} />
            <LotteryCard title='Cuarteta Noche' data={results.cuartetaNoche} loading={loading} />
          </Grid>
        )}
        <Divider sx={{ my: 3 }} />
        <Box mt={4} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            endIcon={<LaunchIcon />}
            href="https://www.anguilalottery.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visitar sitio oficial de Lotería de Anguila
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Anguila;
