import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Button, 
  Card, 
  CardContent, 
  Typography, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Container,
  Grid,
  Chip,
  Snackbar,
  Alert,
  CircularProgress,
  Slider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  MonetizationOn, 
  Share, 
  AttachMoney, 
  EmojiEvents, 
  BarChart,
  Info,
  CheckCircleOutline,
  IosShare
} from '@mui/icons-material';
import { motion, AnimatePresence } from "framer-motion";

const STORAGE_KEY = 'loteriaGameState';
const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  width: '2.5rem',
  height: '2.5rem',
  padding: 0,
  minWidth: 0,
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('sm')]: {
    width: '3rem',
    height: '3rem',
  },
  [theme.breakpoints.up('md')]: {
    width: '3.5rem',
    height: '3.5rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.1)',
  border: '1px solid rgba(255,255,255,0.3)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.2)',
  },
}));

const GlassButton = styled(Button)(({ theme }) => ({
  color: '#ffffff',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '20px',
  padding: '8px 16px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
  },
}));

export default function LoteriaPremium() {
  const [numbers] = useState(() => Array.from({length: 100}, (_, i) => i));
  const [gameState, setGameState] = useState({
    points: 1000,
    selectedNumbers: {},
    lastResult: [],
    stats: { wins: 0, losses: 0 },
    hasReceivedBonus: false
  });
  const [winningNumbers, setWinningNumbers] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [message, setMessage] = useState("");
  const [currentBet, setCurrentBet] = useState(0);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isLoading, setIsLoading] = useState(true);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);

  const saveGameState = useCallback(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({
      state: gameState,
      timestamp: Date.now()
    }));
  }, [gameState]);

  useEffect(() => {
    const loadGameState = () => {
      const savedState = localStorage.getItem(STORAGE_KEY);
      if (savedState) {
        const { state, timestamp } = JSON.parse(savedState);
        if (Date.now() - timestamp < SESSION_DURATION) {
          setGameState(state);
        } else {
          localStorage.removeItem(STORAGE_KEY);
        }
      }
      setIsLoading(false);
    };

    loadGameState();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      saveGameState();
    }
  }, [gameState, saveGameState, isLoading]);

  const selectNumber = useCallback((num) => {
    setCurrentNumber(num);
    setIsDialogOpen(true);
  }, []);

  const setBet = useCallback(() => {
    if (currentNumber !== null && currentBet > 0 && currentBet <= gameState.points) {
      setGameState(prev => ({
        ...prev,
        points: prev.points - currentBet,
        selectedNumbers: {
          ...prev.selectedNumbers,
          [currentNumber]: currentBet
        }
      }));
      setCurrentNumber(null);
      setCurrentBet(0);
      setIsDialogOpen(false);
    }
  }, [currentNumber, currentBet, gameState.points]);

  const giveReward = useCallback(() => {
    const reward = 100;
    setGameState(prev => ({ ...prev, points: prev.points + reward }));
    setSnackbar({ open: true, message: `¡Te has quedado sin puntos! Aquí tienes ${reward} puntos de regalo.`, severity: 'info' });
  }, []);

  const calculateWinnings = useCallback((winning) => {
    let winnings = 0;
    let winMessage = "Resultados:\n";

    Object.entries(gameState.selectedNumbers).forEach(([num, bet]) => {
      const numInt = parseInt(num);
      if (winning[2] === numInt) winnings += bet * 55;
      if (winning[1] === numInt) winnings += bet * 12;
      if (winning[0] === numInt) winnings += bet * 7;
    });

    if (winnings > 0) {
      setGameState(prev => ({
        ...prev,
        points: prev.points + winnings,
        stats: { ...prev.stats, wins: prev.stats.wins + 1 },
        selectedNumbers: {}
      }));
      winMessage += `¡Felicidades! Has ganado ${winnings} puntos. 😃`;
      setSnackbar({ open: true, message: `¡Ganaste ${winnings} puntos! 😃`, severity: 'success' });
    } else {
      setGameState(prev => ({
        ...prev,
        stats: { ...prev.stats, losses: prev.stats.losses + 1 },
        selectedNumbers: {}
      }));
      winMessage += "Lo siento, no has ganado esta vez. 😔";
      setSnackbar({ open: true, message: "No hubo suerte esta vez. ¡Inténtalo de nuevo! 😔", severity: 'error' });
      if (gameState.points === 0) {
        giveReward();
      }
    }

    setMessage(winMessage);
  }, [gameState.selectedNumbers, gameState.points, giveReward]);

  const spinTombola = useCallback(() => {
    setIsSpinning(true);
    setMessage("Girando la tómbola...");
    setWinningNumbers([]);

    const winning = Array.from({length: 3}, () => Math.floor(Math.random() * 100));

    const interval = 1000; // 1 second between each number reveal

    setTimeout(() => {
      setWinningNumbers([winning[2]]);
      setTimeout(() => {
        setWinningNumbers([winning[2], winning[1]]);
        setTimeout(() => {
          setWinningNumbers(winning);
          setIsSpinning(false);
          calculateWinnings(winning);
          setGameState(prev => ({ ...prev, lastResult: winning }));
        }, interval);
      }, interval);
    }, interval);
  }, [calculateWinnings]);

  const handleShare = useCallback(() => {
    const shareUrl = window.location.href;
    const shareTitle = 'Juega Lotería Premium conmigo! 🎰';

    if (navigator.share) {
      navigator.share({
        title: shareTitle,
        url: shareUrl,
      }).then(() => {
        console.log('Thanks for sharing!');
        if (!gameState.hasReceivedBonus) {
          const bonus = 500;
          setGameState(prev => ({ 
            ...prev, 
            points: prev.points + bonus,
            hasReceivedBonus: true 
          }));
          setSnackbar({ open: true, message: `¡Gracias por compartir! Has ganado un bono de ${bonus} puntos.`, severity: 'success' });
        } else {
          setSnackbar({ open: true, message: "¡Gracias por seguir compartiendo el juego!", severity: 'success' });
        }
      }).catch(console.error);
    } else {
      navigator.clipboard.writeText(shareUrl).then(() => {
        setSnackbar({ open: true, message: 'Enlace copiado al portapapeles!', severity: 'success' });
      }).catch(console.error);
    }
  }, [gameState.hasReceivedBonus]);

  const handleCloseSnackbar = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  }, []);

  const memoizedContent = useMemo(() => (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={2}>
        <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0,0,0,0.1)', color: '#414c57' }}>
          Lotería Premium
        </Typography>
        <IconButton onClick={() => setIsInfoDialogOpen(true)} color="primary">
          <Info />
        </IconButton>
      </Box>
      
      <Card sx={{ mb: 2, bgcolor: 'rgb(27 255 0 / 12%)', border: '1px solid rgba(255,255,255,0.3)', boxShadow: 'none', backdropFilter: 'blur(10px)', width: '100%', maxWidth: { xs: '100%', sm: 400, md: 500 }, position: 'relative', overflow: 'hidden'}}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box display="flex" alignItems="center">
              <MonetizationOn sx={{ color: 'warning.main', mr: 1 }} />
              <Typography variant="h5" component="span" sx={{ fontWeight: 'bold', color: '#434e58' }}>
                {gameState.points} puntos
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <BarChart sx={{ color: 'success.main', mr: 1 }} />
              <Typography variant="body1" component="span" sx={{ color: '#434e58' }}>
                {gameState.stats.wins}G - {gameState.stats.losses}P
              </Typography>
            </Box>
          </Box>
          {gameState.lastResult.length > 0 && (
            <Box>
              <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold', mb: 1, color: '#434e58' }}>
                Último resultado:
              </Typography>
              <Box display="flex" justifyContent="center" gap={1}>
                {gameState.lastResult.map((num, i) => (
                  <Chip
                    key={i}
                    label={`${i === 2 ? '1ra: ' : i === 1 ? '2da: ' : '3ra: '}${num.toString().padStart(2, '0')}`}
                    color={i === 2 ? "error" : i === 1 ? "secondary" : "warning"}
                    size="small"
                  />
                ))}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
      
      <Card sx={{ mb: 2, bgcolor: 'rgb(27 255 0 / 12%)', border: '1px solid rgba(255,255,255,0.3)', boxShadow: 'none', backdropFilter: 'blur(10px)', width: '100%', maxWidth: { xs: '100%', sm: 600, md: 800, lg: 1000 }, position: 'relative', overflow: 'hidden'}}>
        <CardContent>
          <Grid container spacing={1} justifyContent="center">
            {numbers.map(num => (
              <Grid item key={num}>
                <StyledButton
                  variant="contained"
                  onClick={() => selectNumber(num)}
                  sx={{
                    bgcolor: gameState.selectedNumbers[num] ? 'success.main' : 'background.paper',
                    color: gameState.selectedNumbers[num] ? 'white' : 'text.primary',
                    '&:hover': {
                      bgcolor: gameState.selectedNumbers[num] ? 'success.dark' : 'action.hover',
                      transform: 'translateY(-2px)',
                      boxShadow: 2,
                    },
                    ...(winningNumbers.includes(num) && {
                      bgcolor: 'warning.main',
                      color: 'text.primary',
                      '&:hover': {
                        bgcolor: 'warning.dark',
                      },
                    }),
                    transition: 'all 0.3s ease',
                    boxShadow: 1,
                  }}
                >
                  <Typography variant="body2" fontWeight="bold">{num.toString().padStart(2, '0')}</Typography>
                  {gameState.selectedNumbers[num] && (
                    <Typography variant="caption" sx={{ fontSize: '0.6rem', mt: 0.5, color: 'white' }}>
                      {gameState.selectedNumbers[num]}
                    </Typography>
                  )}
                </StyledButton>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      
      <Button 
        variant="contained" 
        onClick={spinTombola} 
        disabled={isSpinning || Object.keys(gameState.selectedNumbers).length === 0}
        sx={{ 
          mb: 2, 
          bgcolor: 'primary.main',
          color: 'white',
          fontWeight: 'bold',
          padding: '10px 20px',
          fontSize: '1.1rem',
          textTransform: 'none',
          boxShadow: 3,
          '&:hover': {
            bgcolor:  'primary.dark',
          },
        }}
      >
        {isSpinning ? 'Girando...' : 'Girar Tómbola'}
      </Button>
      
      <AnimatePresence>
        {isSpinning && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1, rotate: 360 }}
            exit={{ opacity: 0, scale: 0.5, rotate: 0 }}
            transition={{ duration: 0.5, repeat: Infinity, ease: "linear" }}
            style={{ marginBottom: '1rem' }}
          >
            <Box 
              sx={{ 
                width: { xs: 100, sm: 150, md: 200 }, 
                height: { xs: 100, sm: 150, md: 200 }, 
                borderRadius: '50%', 
                border: '4px solid',
                borderColor: 'primary.main',
                borderTopColor: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                animation: 'spin 1s linear infinite',
                '@keyframes spin': {
                  '0%': {
                    transform: 'rotate(0deg)',
                  },
                  '100%': {
                    transform: 'rotate(360deg)',
                  },
                },
              }}
            >
              <Box 
                sx={{ 
                  width: '75%', 
                  height: '75%', 
                  borderRadius: '50%', 
                  border: '4px solid',
                  borderColor: 'secondary.main',
                  borderRightColor: 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  animation: 'spin 2s linear infinite',
                }}
              >
                <Box 
                  sx={{ 
                    width: '50%', 
                    height: '50%', 
                    borderRadius: '50%', 
                    border: '4px solid',
                    borderColor: 'error.main',
                    borderBottomColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.paper',
                    animation: 'spin 3s linear infinite',
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {Math.floor(Math.random() * 100).toString().padStart(2, '0')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      
      <Box mb={2} textAlign="center">
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#434e58' }}>
          Números Ganadores
        </Typography>
        <Box display="flex" justifyContent="center" gap={2}>
          {winningNumbers.map((num, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.5 }}
            >
              <Chip
                icon={index === 2 ? <EmojiEvents /> : null}
                label={`${index === 2 ? '1ra: ' : index === 1 ? '2da: ' : '3ra: '}${num.toString().padStart(2, '0')}`}
                color={index === 2 ? "error" : index === 1 ? "secondary" : "warning"}
                sx={{ 
                  fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' },
                  height: 'auto',
                  '& .MuiChip-label': { padding: '10px' },
                  '& .MuiChip-icon': { fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } },
                }}
              />
            </motion.div>
          ))}
        </Box>
      </Box>
      
      {message && (
        <Card sx={{ mb: 2, bgcolor: 'rgb(0 239 61 / 50%)', border: '1px solid rgba(255,255,255,0.3)', boxShadow: 'none', backdropFilter: 'blur(10px)', width: '100%', maxWidth: { xs: '100%', sm: 400, md: 500 }, position: 'relative', overflow: 'hidden'}}>
          <CardContent>
            <Typography variant="body1" align="center" sx={{ whiteSpace: 'pre-line', color: '#404b56' }}>
              {message}
            </Typography>
          </CardContent>
        </Card>
      )}
      
      <Tooltip title="Compartir">
        <GlassButton
          onClick={handleShare}
          startIcon={<IosShare />}
          sx={{
            mb: 2,
            bgcolor: 'secondary.main',
            color: 'white',
            fontWeight: 'bold',
            padding: '10px 20px',
            fontSize: '1rem',
            textTransform: 'none',
            boxShadow: 3,
            '&:hover': {
              bgcolor: 'secondary.dark',
            },
          }}
        >
          Compartir juego
        </GlassButton>
      </Tooltip>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Apostar al número {currentNumber !== null ? currentNumber.toString().padStart(2, '0') : ''}</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Selecciona el monto de tu apuesta:
          </Typography>
          <Slider
            value={currentBet}
            onChange={(_, newValue) => setCurrentBet(newValue)}
            aria-labelledby="bet-slider"
            valueLabelDisplay="auto"
            step={10}
            marks
            min={0}
            max={gameState.points}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
            <AttachMoney sx={{ color: 'primary.main', mr: 1 }} />
            <Typography variant="h6">
              Apuesta: {currentBet} puntos
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={setBet} color="primary" variant="contained">
            Apostar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isInfoDialogOpen} onClose={() => setIsInfoDialogOpen(false)}>
        <DialogTitle>Cómo jugar Lotería Premium</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            Bienvenido a Lotería Premium, un juego de entretenimiento sin apuestas reales. Aquí tienes algunas instrucciones y sugerencias positivas:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutline color="primary" />
              </ListItemIcon>
              <ListItemText primary="Este es un juego de entretenimiento sin dinero real involucrado." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutline color="primary" />
              </ListItemIcon>
              <ListItemText primary="Selecciona números y haz 'apuestas' con tus puntos virtuales." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutline color="primary" />
              </ListItemIcon>
              <ListItemText primary="Gira la tómbola para ver si ganas más puntos." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutline color="primary" />
              </ListItemIcon>
              <ListItemText primary="Juega responsablemente y diviértete sin riesgos." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutline color="primary" />
              </ListItemIcon>
              <ListItemText primary="Comparte el juego con amigos para obtener puntos extra." />
            </ListItem>
          </List>
          <Typography paragraph>
            Recuerda, el objetivo principal es divertirse. Si sientes que el juego ya no es entretenido, toma un descanso o busca otras actividades.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsInfoDialogOpen(false)} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  ), [gameState, winningNumbers, isSpinning, message, currentNumber, currentBet, snackbar, selectNumber, spinTombola, handleShare, setBet, handleCloseSnackbar, isDialogOpen, isInfoDialogOpen, numbers]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ 
      minHeight: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center',
      padding: 2,
      color: 'white',
      backgroundColor: 'rgb(0 81 27 / 2%)', // Color esmeralda
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage: `
          linear-gradient(to right, rgba(255,255,255,0.2) 1px, transparent 1px),
          linear-gradient(to bottom, rgba(255,255,255,0.2) 1px, transparent 1px)
        `,
        backgroundSize: '20px 20px',
        pointerEvents: 'none',
      }
    }}>
      {memoizedContent}
    </Container>
  );
}