import axios from 'axios';

// Definir la URL base de la API
const API_URL = 'https://www.tutawin.com/api';

// Header personalizado para identificar solicitudes legítimas desde el frontend
const axiosConfig = {
    headers: {
        'x-requested-with': 'TutawinFrontend' // Header especial para distinguir solicitudes del frontend
    }
};

// Función para obtener todas las loterías
export async function fetchAllLotteries() {
    try {
        const response = await axios.get(`${API_URL}/lotteries`, axiosConfig);
        return response.data; // axios ya maneja automáticamente la conversión a JSON
    } catch (error) {
        // Captura y proporciona más detalles sobre el error
        if (error.response) {
            // El servidor respondió con un estado que no está en el rango 2xx
            console.error('Error en la respuesta del servidor:', error.response.data);
            throw new Error('Error en el servidor al obtener los resultados de lotería');
        } else if (error.request) {
            // La solicitud se hizo pero no hubo respuesta
            console.error('No hubo respuesta del servidor:', error.request);
            throw new Error('No hubo respuesta del servidor al obtener los resultados de lotería');
        } else {
            // Error al configurar la solicitud
            console.error('Error al configurar la solicitud:', error.message);
            throw new Error('Error en la solicitud al obtener los resultados de lotería');
        }
    }
}

// Función para obtener las loterías por fecha
export async function fetchLotteriesByDate(date) {
    try {
        if (!date) {
            // Si no se proporciona una fecha, obtener todas las loterías
            return await fetchAllLotteries();
        }

        const formattedDate = new Date(date).toISOString().split('T')[0]; // Formato 'YYYY-MM-DD'
        const response = await axios.get(`${API_URL}/lotteries?date=${formattedDate}`, axiosConfig);
        return response.data;
    } catch (error) {
        // Manejo detallado del error
        if (error.response) {
            console.error('Error en la respuesta del servidor:', error.response.data);
            throw new Error('Error en el servidor al obtener los resultados para la fecha especificada');
        } else if (error.request) {
            console.error('No hubo respuesta del servidor:', error.request);
            throw new Error('No hubo respuesta del servidor al obtener los resultados para la fecha especificada');
        } else {
            console.error('Error al configurar la solicitud:', error.message);
            throw new Error('Error en la solicitud al obtener los resultados para la fecha especificada');
        }
    }
}



// Funciones específicas para cada tipo de lotería
export async function getGanaMas() {
    try {
      const response = await axios.get(`${API_URL}/gana-mas`, axiosConfig); // Corrección en la llamada a Axios y uso de backticks
      return response.data;
    } catch (error) {
      console.error('Error fetching Gana Mas:', error);
      return [];
    }
  }  
  
  // Función para obtener Pega Mas
export async function getPegaMas() {
    try {
      const response = await axios.get(`${API_URL}/pega-mas`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Pega Mas:', error);
      return [];
    }
  }
  
  // Función para obtener Nacional
  export async function getNacional() {
    try {
      const response = await axios.get(`${API_URL}/nacional`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Nacional:', error);
      return [];
    }
  }
  
  // Función para obtener Quiniela Pale
  export async function getQuinielaPale() {
    try {
      const response = await axios.get(`${API_URL}/quiniela-pale`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Quiniela Pale:', error);
      return [];
    }
  }
  
  // Función para obtener Loto Pool Leidsa
  export async function getLotoPoolLeidsa() {
    try {
      const response = await axios.get(`${API_URL}/loto-pool-leidsa`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Loto Pool Leidsa:', error);
      return [];
    }
  }
  
  
  export async function getPega3MasLeidsa() {
    try {
      const response = await axios.get(`${API_URL}/pega3mas-leidsa`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Pega 3 Mas Leidsa:', error);
      return [];
    }
  }
  
  
 // Función para obtener Super Pale Leidsa
export async function getSuperPaleLeidsa() {
    try {
      const response = await axios.get(`${API_URL}/super-pale-leidsa`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Super Pale Leidsa:', error);
      return [];
    }
  }
  
  // Función para obtener Super Kino TV Leidsa
  export async function getSuperKinoTVLeidsa() {
    try {
      const response = await axios.get(`${API_URL}/super-kino-tv-leidsa`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Super Kino TV Leidsa:', error);
      return [];
    }
  }
  
  // Función para obtener Lotomas Leidsa
  export async function getLotomasLeidsa() {
    try {
      const response = await axios.get(`${API_URL}/lotomas-leidsa`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Lotomas Leidsa:', error);
      return [];
    }
  }
  
  // Función para obtener Quiniela Loteka
  export async function getQuinielaLoteka() {
    try {
      const response = await axios.get(`${API_URL}/quiniela-loteka`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Quiniela Loteka:', error);
      return [];
    }
  }
  
  // Función para obtener Real
  export async function getReal() {
    try {
      const response = await axios.get(`${API_URL}/real`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Real:', error);
      return [];
    }
  }
  
  // Función para obtener NY Real
  export async function getNYReal() {
    try {
      const response = await axios.get(`${API_URL}/ny-real`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching NY Real:', error);
      return [];
    }
  }
  
  // Función para obtener Loto Pool Real
  export async function getLotoPoolReal() {
    try {
      const response = await axios.get(`${API_URL}/loto-pool-real`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Loto Pool Real:', error);
      return [];
    }
  }
  
  // Función para obtener Super Pale Real
  export async function getSuperPaleReal() {
    try {
      const response = await axios.get(`${API_URL}/super-pale-real`, axiosConfig); // Uso correcto de axios.get
      return response.data;
    } catch (error) {
      console.error('Error fetching Super Pale Real:', error);
      return [];
    }
  }
  
  
  export async function getMegaChanceLoteka() {
    try {
      const response = await axios.get(`${API_URL}/mega-chances`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Mega Chance Loteka:', error);
      return [];
    }
  }
  
  export async function getToca3Loteka() {
    try {
      const response = await axios.get(`${API_URL}/toca3-loteka`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Toca 3 Loteka:', error);
      return [];
    }
  }
  
  export async function getMCRepatideraLoteka() {
    try {
      const response = await axios.get(`${API_URL}/mc-repatidera-loteka`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching MC Repatidera Loteka:', error);
      return [];
    }
  }
  
  export async function getMegaLotoLoteka() {
    try {
      const response = await axios.get(`${API_URL}/mega-loto-loteka`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Mega Loto Loteka:', error);
      return [];
    }
  }
  
  export async function getPrimeraDia() {
    try {
      const response = await axios.get(`${API_URL}/primera-dia`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Primera Dia:', error);
      return [];
    }
  }
  
  export async function getPrimeraNoche() {
    try {
      const response = await axios.get(`${API_URL}/primera-noche`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Primera Noche:', error);
      return [];
    }
  }
  
  export async function getLotoReal() {
    try {
      const response = await axios.get(`${API_URL}/loto-real`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Loto Real:', error);
      return [];
    }
  }
  
  export async function getQuinielita() {
    try {
      const response = await axios.get(`${API_URL}/quinielita`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Quinielon Dia:', error);
      return [];
    }
  }
  
  export async function getQuinielonDia() {
    try {
      const response = await axios.get(`${API_URL}/quinielon-dia`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Quinielon Dia:', error);
      return [];
    }
  }
  
  export async function getQuinielonNoche() {
    try {
      const response = await axios.get(`${API_URL}/quinielon-noche`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Quinielon Noche:', error);
      return [];
    }
  }
  
  export async function getLoto5() {
    try {
      const response = await axios.get(`${API_URL}/loto5`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Loto 5:', error);
      return [];
    }
  }
  
  export async function getSuerteDia() {
    try {
      const response = await axios.get(`${API_URL}/suerte-dia`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Suerte Dia:', error);
      return [];
    }
  }
  
  export async function getSuerteNoche() {
    try {
      const response = await axios.get(`${API_URL}/suerte-noche`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Suerte Noche:', error);
      return [];
    }
  }
  
  export async function getLoteDom() {
    try {
      const response = await axios.get(`${API_URL}/lote-dom`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Lote Dom:', error);
      return [];
    }
  }
  
  export async function getQuemaito() {
    try {
      const response = await axios.get(`${API_URL}/quemaito`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Quemaito:', error);
      return [];
    }
  }
  
  export async function getSuperPaleLotedom() {
    try {
      const response = await axios.get(`${API_URL}/super-pale-lotedom`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Super Pale Lotedom:', error);
      return [];
    }
  }
  
  export async function getAgarra4Lotedom() {
    try {
      const response = await axios.get(`${API_URL}/agarra4-lotedom`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Agarra 4 Lotedom:', error);
      return [];
    }
  }
  
  export async function getPega4Real() {
    try {
      const response = await axios.get(`${API_URL}/pega4-real`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Pega 4 Real:', error);
      return [];
    }
  }
  
  export async function getPick3Dia() {
    try {
      const response = await axios.get(`${API_URL}/pick-3-dia`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Pick 3 Dia:', error);
      return [];
    }
  }
  
  export async function getPick4Dia() {
    try {
      const response = await axios.get(`${API_URL}/pick-4-dia`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Pick 4 Dia:', error);
      return [];
    }
  }
  
  export async function getPick4Noche() {
    try {
      const response = await axios.get(`${API_URL}/pick-4-noche`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Pick 4 Noche:', error);
      return [];
    }
  }
  
  export async function getPick3Noche() {
    try {
      const response = await axios.get(`${API_URL}/pick-3-noche`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Pick 3 Noche:', error);
      return [];
    }
  }
  
  export async function getKingLotteryDia() {
    try {
      const response = await axios.get(`${API_URL}/quiniela-dia`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching King Lottery Dia:', error);
      return [];
    }
  }
  
  export async function getKingLotteryNoche() {
    try {
      const response = await axios.get(`${API_URL}/quiniela-noche`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching King Lottery Noche:', error);
      return [];
    }
  }
  
  export async function getLotoPoolMedioDia() {
    try {
      const response = await axios.get(`${API_URL}/loto-pool-medio-dia`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Loto Pool Medio Dia:', error);
      return [];
    }
  }
  
  export async function getLotoPoolNoche() {
    try {
      const response = await axios.get(`${API_URL}/loto-pool-noche`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Loto Pool Noche:', error);
      return [];
    }
  }
  
  export async function getPhilipsburgNoche() {
    try {
      const response = await axios.get(`${API_URL}/philipsburg-noche`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Philipsburg Noche:', error);
      return [];
    }
  }
  
  export async function getPhilipsburgDia() {
    try {
      const response = await axios.get(`${API_URL}/philipsburg-medio-dia`, axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching Philipsburg Dia:', error);
      return [];
    }
  }

//Api Americana

export async function getFloridaDia() {
  try {
    const response = await axios.get(`${API_URL}/florida-dia`, axiosConfig);
    return response.data;
  } catch (error) {
    console.error('Error fetching Florida Dia:', error);
    return [];
  }
}

export async function getFloridaNoche() {
  try {
    const response = await axios.get(`${API_URL}/florida-noche`, axiosConfig);
    return response.data;
  } catch (error) {
    console.error('Error fetching Florida Noche:', error);
    return [];
  }
}

export async function getNewYorkDia() {
  try {
    const response = await axios.get(`${API_URL}/newyork-dia`, axiosConfig);
    return response.data;
  } catch (error) {
    console.error('Error fetching New York Dia:', error);
    return [];
  }
}

export async function getNewYorkNoche() {
  try {
    const response = await axios.get(`${API_URL}/newyork-noche`, axiosConfig);
    return response.data;
  } catch (error) {
    console.error('Error fetching New York Noche:', error);
    return [];
  }
}

