import {
  getNacional,
  getGanaMas,
  getPegaMas,
  getLotoPoolReal,
  getPega4Real,
  getQuinielita,
  getLotoPoolLeidsa,
  getQuinielaPale,
  getNYReal,
  getReal,
  getLotoReal,
  getSuperPaleReal,
  getPega3MasLeidsa,
  getSuperKinoTVLeidsa,
  getLotomasLeidsa,
  getSuperPaleLeidsa,
  getQuinielonDia,
  getPrimeraNoche,
  getPrimeraDia,
  getLoto5,
  getQuinielonNoche,
  getToca3Loteka,
  getMCRepatideraLoteka,
  getQuinielaLoteka,
  getMegaLotoLoteka,
  getMegaChanceLoteka,
  getSuerteDia,
  getSuerteNoche,
  getAgarra4Lotedom,
  getQuemaito,
  getSuperPaleLotedom,
  getFloridaDia,
  getNewYorkDia,
  getNewYorkNoche,
  getFloridaNoche,
  getKingLotteryNoche,
  getKingLotteryDia,
  getPick3Dia,
  getPick4Dia,
  getPick4Noche,
  getPick3Noche,
  getPhilipsburgDia,
  getPhilipsburgNoche,
  getLotoPoolNoche,
  getLotoPoolMedioDia,
  
} from '../services/api';
import {
  getPowerball,
  getCash4life,
  getMegaMillions,
  getPick10,
  getTake5,
  getWin4NY,
  getNYLotto
} from '../services/usaapi';
import {
  getAnguila12AM,
  getAnguilaManana,
  getAnguilaTarde,
  getAnguilaNoche
} from '../services/anguilaapi';


export const lotteries = [
  // Powerball
  {
    name: 'Powerball',
    slug: 'powerball',
    fetchFunction: getPowerball,
    country: 'Estados Unidos',
    drawTimes: [
      { time: '22:59', days: ['lunes', 'miércoles', 'sábado'] }
    ],
    hasMultiplier: true,
    minimumJackpot: '20000000',
    ticketPrice: '2',
    odds: {
      jackpot: '1 en 292,201,338',
      anyPrize: '1 en 24.87'
    },
    numberRange: {
      main: { min: 1, max: 69 },
      powerball: { min: 1, max: 26 }
    },
    prizeCategories: [
      { match: '5 + PB', prize: 'Jackpot' },
      { match: '5', prize: '1,000,000' },
      { match: '4 + PB', prize: '50,000' },
      { match: '4', prize: '100' },
      { match: '3 + PB', prize: '100' },
      { match: '3', prize: '7' },
      { match: '2 + PB', prize: '7' },
      { match: '1 + PB', prize: '4' },
      { match: 'PB', prize: '4' }
    ],
    details: {
      description: 'Powerball es una de las loterías más populares en los Estados Unidos, conocida por sus enormes jackpots.',
      jackpotInfo: 'El jackpot comienza en $20 millones y crece hasta que alguien lo gana.',
      drawInfo: 'Los sorteos se realizan tres veces por semana: lunes, miércoles y sábados a las 10:59 PM ET.',
      multiplierInfo: 'El multiplicador Power Play puede multiplicar los premios no jackpot hasta 10 veces.'
    },
    howToPlay: [
      'Selecciona 5 números del 1 al 69 para las bolas blancas.',
      'Selecciona 1 número del 1 al 26 para la bola roja Powerball.',
      'Opcionalmente, agrega Power Play por $1 adicional por jugada para multiplicar los premios no jackpot.',
      'Gana el jackpot acertando todos los 6 números.',
      'Hay 9 formas de ganar, desde acertar solo el Powerball hasta acertar todos los números.'
    ],
    multiplierRules: [
      'Power Play multiplica los premios no jackpot por 2, 3, 4, 5 o 10 veces.',
      'El multiplicador 10x está disponible cuando el jackpot anunciado es $150 millones o menos.',
      'El premio de $1 millón siempre se duplica a $2 millones con Power Play.',
      'El jackpot no se multiplica con Power Play.'
    ],
    claimPeriod: '90 días a 1 año, dependiendo del estado donde se compró el boleto.',
    taxInfo: 'Los premios están sujetos a impuestos federales y posiblemente estatales.',
    officialWebsite: 'https://www.powerball.com/'
  },

  // Mega Millions
{
  name: 'Mega Millions',
  slug: 'mega-millions',
  fetchFunction: getMegaMillions,
  country: 'Estados Unidos',
  drawTimes: [{ time: '23:00', days: ['martes', 'viernes'] }],
  hasMultiplier: true,
  minimumJackpot: '20000000', // $20 millones
  ticketPrice: '2', // $2 por jugada básica
  odds: {
    jackpot: '1 en 302,575,350',
    anyPrize: '1 en 24'
  },
  numberRange: {
    main: { min: 1, max: 70 }, // Números principales
    megaBall: { min: 1, max: 25 } // Mega Ball
  },
  prizeCategories: [
    { match: '5 + MB', prize: 'Jackpot' },
    { match: '5', prize: '1,000,000' },
    { match: '4 + MB', prize: '10,000' },
    { match: '4', prize: '500' },
    { match: '3 + MB', prize: '200' },
    { match: '3', prize: '10' },
    { match: '2 + MB', prize: '10' },
    { match: '1 + MB', prize: '4' },
    { match: 'MB', prize: '2' }
  ],
  details: {
    description: 'Mega Millions es una de las loterías más grandes de Estados Unidos, conocida por sus jackpots multimillonarios.',
    jackpotInfo: 'El jackpot comienza en $20 millones y crece hasta que alguien lo gana.',
    drawInfo: 'Los sorteos se realizan dos veces por semana: martes y viernes a las 11:00 PM ET.',
    multiplierInfo: 'El Megaplier puede multiplicar los premios no jackpot hasta 5 veces.'
  },
  howToPlay: [
    'Selecciona 5 números del 1 al 70 para las bolas blancas.',
    'Selecciona 1 número del 1 al 25 para la bola dorada Mega Ball.',
    'Opcionalmente, agrega Megaplier por $1 adicional por jugada para multiplicar los premios no jackpot.',
    'Gana el jackpot acertando todos los 6 números.',
    'Hay 9 formas de ganar, desde acertar solo el Mega Ball hasta acertar todos los números.'
  ],
  multiplierRules: [
    'Megaplier multiplica los premios no jackpot por 2, 3, 4 o 5 veces.',
    'El multiplicador se sortea antes de cada sorteo de Mega Millions.',
    'El premio de $1 millón puede llegar hasta $5 millones con Megaplier.',
    'El jackpot no se multiplica con Megaplier.',
    'El costo adicional para agregar Megaplier es de $1 por jugada.'
  ],
  claimPeriod: '90 días a 1 año, dependiendo del estado donde se compró el boleto.',
  taxInfo: 'Los premios están sujetos a impuestos federales y posiblemente estatales. En algunos casos, se aplican retenciones automáticas para premios mayores.',
  officialWebsite: 'https://www.megamillions.com/',
  additionalInfo: {
    participatingStates: 'Mega Millions se juega en 45 estados, el Distrito de Columbia y las Islas Vírgenes de EE.UU.',
    californiaException: 'En California, los premios no son fijos y se determinan en base a las ventas y el número de ganadores.',
    responsibleGaming: 'Se recomienda jugar de manera responsable y verificar las regulaciones locales.'
  }
},


  // Cash4Life
{
  name: 'Cash4Life',
  slug: 'cash4life',
  fetchFunction: getCash4life, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'Estados Unidos',
  drawTimes: [
    {
      time: '21:00',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false, // Cash4Life no tiene opción de multiplicador
  minimumJackpot: '1,000 dólares diarios de por vida', // Premio mayor
  ticketPrice: '2', // $2 por jugada básica
  odds: {
    jackpot: '1 en 21,846,048',
    anyPrize: '1 en 8',
  },
  numberRange: {
    main: { min: 1, max: 60 }, // Números principales
    cashBall: { min: 1, max: 4 }, // Cash Ball
  },
  prizeCategories: [
    { match: '5 + CB', prize: '1,000 dólares diarios de por vida' },
    { match: '5', prize: '1,000 dólares semanales de por vida' },
    { match: '4 + CB', prize: '2,500' },
    { match: '4', prize: '500' },
    { match: '3 + CB', prize: '100' },
    { match: '3', prize: '25' },
    { match: '2 + CB', prize: '10' },
    { match: '2', prize: '4' },
    { match: '1 + CB', prize: '2' },
    { match: 'CB', prize: '2' },
  ],
  details: {
    description:
      'Cash4Life es una lotería multiestatal en Estados Unidos que ofrece la oportunidad de ganar 1,000 dólares diarios o semanales de por vida.',
    jackpotInfo:
      'El premio mayor es de 1,000 dólares diarios de por vida o una opción de pago en efectivo de una suma global.',
    drawInfo: 'Los sorteos se realizan diariamente a las 21:00 hora del Este (ET).',
    multiplierInfo: 'Cash4Life no ofrece una opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona 5 números del 1 al 60.',
    'Elige un número Cash Ball del 1 al 4.',
    'Puedes seleccionar tus propios números o usar la opción Quick Pick para números al azar.',
    'Compra tu boleto por $2.',
    'Espera al sorteo diario a las 21:00 ET para ver si ganaste.',
    'Gana el premio mayor acertando los 5 números principales y el Cash Ball.',
    'Hay 9 formas de ganar, desde acertar solo el Cash Ball hasta acertar todos los números.',
  ],
  multiplierRules: null, // No aplica, ya que no hay multiplicador
  claimPeriod:
    'Los premios deben reclamarse dentro de los 180 días posteriores a la fecha del sorteo.',
  taxInfo:
    'Los premios están sujetos a impuestos federales y estatales. Se retendrá un porcentaje del premio para cubrir los impuestos aplicables.',
  officialWebsite:
    'https://www.njlottery.com/en-us/games/drawgames/cash4life.html',
  additionalInfo: {
    participatingStates:
      'Cash4Life se juega en varios estados, incluyendo Nueva York, Nueva Jersey, Florida, Pensilvania, y más.',
    responsibleGaming:
      'Se recomienda jugar de manera responsable y verificar las regulaciones locales.',
  },
},

  // Pick 10
{
  name: 'Pick 10',
  slug: 'pick-10',
  fetchFunction: getPick10, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'Estados Unidos',
  drawTimes: [
    {
      time: '20:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false, // No hay opción de multiplicador en Pick 10
  minimumJackpot: '500,000', // Premio máximo fijo de $500,000
  ticketPrice: '1', // $1 por jugada
  odds: {
    jackpot: '1 en 8,911,711',
    anyPrize: '1 en 17',
  },
  numberRange: {
    main: { min: 1, max: 80 }, // Números principales
  },
  prizeCategories: [
    { match: '10', prize: '500,000' },
    { match: '9', prize: '6,000' },
    { match: '8', prize: '300' },
    { match: '7', prize: '40' },
    { match: '6', prize: '12' },
    { match: '5', prize: '2' },
    { match: '0', prize: '4' }, // Premio por no acertar ningún número
  ],
  details: {
    description:
      'Pick 10 es un juego de lotería tipo keno ofrecido por la Lotería de Nueva York, donde los jugadores eligen 10 números y se extraen 20 números en cada sorteo.',
    jackpotInfo:
      'El premio máximo es de $500,000 por acertar los 10 números seleccionados.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 20:30 hora del Este (ET).',
    multiplierInfo: 'Pick 10 no ofrece una opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona 10 números del 1 al 80.',
    'Puedes optar por Quick Pick para una selección aleatoria de números.',
    'Cada jugada cuesta $1.',
    'Puedes jugar hasta 7 sorteos consecutivos con el mismo boleto.',
    'Espera al sorteo diario a las 20:30 ET para ver si ganaste.',
    'Gana el premio máximo acertando los 10 números que seleccionaste.',
    'También puedes ganar premios por acertar entre 0 y 9 números, según la tabla de premios.',
  ],
  multiplierRules: null, // No aplica, ya que no hay multiplicador
  claimPeriod:
    'Los premios deben reclamarse dentro de un año a partir de la fecha del sorteo.',
  taxInfo:
    'Los premios superiores a $5,000 están sujetos a impuestos federales y estatales.',
  officialWebsite:
    'https://nylottery.ny.gov/draw-games/pick-10',
  additionalInfo: {
    responsibleGaming:
      'Se recomienda jugar de manera responsable y verificar las regulaciones locales.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},

  // Take 5
{
  name: 'Take 5',
  slug: 'take-5',
  fetchFunction: getTake5, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'Estados Unidos',
  drawTimes: [
    {
      time: '14:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
    {
      time: '22:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false, // Take 5 no tiene opción de multiplicador
  minimumJackpot: 'Variable', // El jackpot depende de las ventas y el número de ganadores
  ticketPrice: '1', // $1 por jugada
  odds: {
    jackpot: '1 en 575,757',
    anyPrize: '1 en 8.77',
  },
  numberRange: {
    main: { min: 1, max: 39 }, // Números principales
  },
  prizeCategories: [
    { match: '5', prize: 'Jackpot' },
    { match: '4', prize: '500' }, // Aproximado, depende de las ventas
    { match: '3', prize: '25' },  // Aproximado, depende de las ventas
    { match: '2', prize: 'Boleto Quick Pick Gratis' }, // Boleto gratis
  ],
  details: {
    description:
      'Take 5 es un juego de lotería diario ofrecido por la Lotería de Nueva York, donde los jugadores seleccionan 5 números del 1 al 39.',
    jackpotInfo:
      'El jackpot es variable y depende de las ventas y el número de ganadores. No se acumula.',
    drawInfo:
      'Los sorteos se realizan dos veces al día, todos los días, a las 14:30 y 22:30 ET.',
    multiplierInfo: 'Take 5 no ofrece una opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona 5 números del 1 al 39.',
    'Puedes optar por Quick Pick para una selección aleatoria de números.',
    'Cada jugada cuesta $1.',
    'Espera al sorteo para ver si ganaste.',
    'Gana el jackpot acertando los 5 números seleccionados.',
    'También puedes ganar premios por acertar 2, 3 o 4 números.',
  ],
  multiplierRules: null, // No aplica
  claimPeriod:
    'Los premios deben reclamarse dentro de un año a partir de la fecha del sorteo.',
  taxInfo:
    'Los premios superiores a $5,000 están sujetos a impuestos federales y estatales.',
  officialWebsite: 'https://nylottery.ny.gov/draw-games/take-5',
  additionalInfo: {
    responsibleGaming:
      'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},

  // Win 4 NY
{
  name: 'Win 4 NY',
  slug: 'win-4-ny',
  fetchFunction: getWin4NY, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'Estados Unidos',
  drawTimes: [
    {
      time: '14:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
    {
      time: '22:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false, // Win 4 no tiene opción de multiplicador
  minimumJackpot: '5,000', // Premio máximo de $5,000
  ticketPrice: '0.5 - 1', // $0.50 o $1 por jugada, dependiendo del tipo de apuesta
  odds: {
    jackpot: '1 en 10,000', // Para apuestas de números exactos
    anyPrize: 'Varía según el tipo de apuesta',
  },
  numberRange: {
    main: { min: 0, max: 9 }, // Números individuales del 0 al 9 para cada posición
  },
  prizeCategories: [
    // Dependiendo del tipo de apuesta
    { type: 'Straight (Orden Exacto)', prize: '5,000' },
    { type: 'Box (Cualquier Orden)', prize: 'Varía' },
    { type: 'Straight/Box', prize: 'Varía' },
    { type: 'Combination', prize: 'Varía' },
  ],
  details: {
    description:
      'Win 4 es un juego de lotería diario ofrecido por la Lotería de Nueva York, donde los jugadores seleccionan un número de 4 dígitos y eligen el tipo de apuesta.',
    jackpotInfo:
      'El premio máximo es de $5,000 por una apuesta Straight (orden exacto) de $1.',
    drawInfo:
      'Los sorteos se realizan dos veces al día, todos los días, a las 14:30 y 22:30 ET.',
    multiplierInfo: 'Win 4 no ofrece una opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona un número de 4 dígitos, del 0000 al 9999.',
    'Elige el tipo de apuesta: Straight, Box, Straight/Box o Combination.',
    'Decide el monto de la apuesta: $0.50 o $1, dependiendo del tipo de juego.',
    'Espera al sorteo para ver si ganaste.',
    'Gana el premio máximo acertando los 4 dígitos en el orden exacto (apuesta Straight).',
    'Las apuestas Box permiten ganar si los números coinciden en cualquier orden.',
  ],
  multiplierRules: null, // No aplica
  claimPeriod:
    'Los premios deben reclamarse dentro de un año a partir de la fecha del sorteo.',
  taxInfo:
    'Los premios superiores a $5,000 están sujetos a impuestos federales y estatales.',
  officialWebsite: 'https://nylottery.ny.gov/draw-games/win4',
  additionalInfo: {
    responsibleGaming:
      'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    typesOfPlay: [
      'Straight (Orden Exacto): Ganas si tus números coinciden en el orden exacto.',
      'Box (Cualquier Orden): Ganas si tus números coinciden en cualquier orden.',
      'Straight/Box: Combina apuestas Straight y Box.',
      'Combination: Juega todas las posibles combinaciones de tus números (equivalente a múltiples apuestas Straight).',
    ],
  },
},

  // New York Lotto
{
  name: 'New York Lotto',
  slug: 'new-york-lotto',
  fetchFunction: getNYLotto, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'Estados Unidos',
  drawTimes: [
    {
      time: '20:15',
      days: ['miércoles', 'sábado'],
    },
  ],
  hasMultiplier: false, // New York Lotto no tiene opción de multiplicador
  minimumJackpot: '2,000,000', // Jackpot comienza en $2 millones
  ticketPrice: '1', // $1 por jugada, incluye dos entradas
  odds: {
    jackpot: '1 en 45,057,474',
    anyPrize: '1 en 46.02',
  },
  numberRange: {
    main: { min: 1, max: 59 }, // Números principales
  },
  prizeCategories: [
    { match: '6', prize: 'Jackpot', odds: '1 en 45,057,474' },
    { match: '5 + Número Bónus', prize: 'Segundo Premio', odds: '1 en 7,509,579' },
    { match: '5', prize: 'Tercer Premio', odds: '1 en 144,415' },
    { match: '4', prize: 'Cuarto Premio', odds: '1 en 2,179' },
    { match: '3', prize: 'Quinto Premio', odds: '1 en 96' },
  ],
  details: {
    description:
      'New York Lotto es el juego de lotería insignia del estado de Nueva York, donde los jugadores seleccionan 6 números del 1 al 59. Se extrae un número de bonificación para el segundo premio.',
    jackpotInfo:
      'El jackpot comienza en $2 millones y aumenta cada vez que no hay un ganador del premio mayor.',
    drawInfo:
      'Los sorteos se realizan dos veces por semana, los miércoles y sábados a las 20:15 ET.',
    multiplierInfo: 'New York Lotto no ofrece una opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona 6 números del 1 al 59.',
    'Puedes optar por Quick Pick para una selección aleatoria de números.',
    'Cada jugada cuesta $1 e incluye dos conjuntos de números (dos oportunidades por $1).',
    'Espera al sorteo para ver si ganaste.',
    'Gana el jackpot acertando los 6 números principales.',
    'El número de bonificación se utiliza para el segundo premio; se extrae después de los seis números principales.',
    'También puedes ganar premios por acertar 3, 4 o 5 números, con o sin el número de bonificación.',
  ],
  multiplierRules: null, // No aplica, ya que no hay multiplicador
  claimPeriod:
    'Los premios deben reclamarse dentro de un año a partir de la fecha del sorteo.',
  taxInfo:
    'Los premios superiores a $5,000 están sujetos a impuestos federales y estatales.',
  officialWebsite: 'https://nylottery.ny.gov/draw-games/lotto',
  additionalInfo: {
    responsibleGaming:
      'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    bonusNumber:
      'El número de bonificación se extrae del mismo conjunto de números y se utiliza para determinar el segundo premio para aquellos que hayan acertado 5 números más el número de bonificación.',
  },
},

  /// New York Tarde (Numbers Midday)
{
  name: 'New York Tarde',
  slug: 'new-york-tarde',
  fetchFunction: getNewYorkDia, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'Estados Unidos',
  drawTimes: [
    {
      time: '14:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false, // No hay opción de multiplicador
  minimumJackpot: '500', // Premio máximo de $500
  ticketPrice: '0.5 - 1', // $0.50 o $1 por jugada, dependiendo del tipo de apuesta
  odds: {
    jackpot: '1 en 1,000', // Para apuestas de números exactos (Straight)
    anyPrize: 'Varía según el tipo de apuesta',
  },
  numberRange: {
    main: { min: 0, max: 9 }, // Cada dígito es de 0 a 9
  },
  prizeCategories: [
    // Dependiendo del tipo de apuesta
    { type: 'Straight (Orden Exacto)', prize: '500' },
    { type: 'Box (Cualquier Orden)', prize: 'Varía' },
    { type: 'Straight/Box', prize: 'Varía' },
    { type: 'Combination', prize: 'Varía' },
    { type: 'Pairs', prize: 'Varía' },
  ],
  details: {
    description:
      'Numbers es un juego diario de la Lotería de Nueva York donde los jugadores eligen un número de 3 dígitos y un tipo de apuesta.',
    jackpotInfo:
      'El premio máximo es de $500 por una apuesta Straight de $1 (orden exacto).',
    drawInfo:
      'Los sorteos se realizan dos veces al día, todos los días, a las 14:30 (Tarde) y 22:30 (Noche) ET.',
    multiplierInfo: 'Numbers no ofrece una opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona un número de 3 dígitos del 000 al 999.',
    'Elige el tipo de apuesta: Straight, Box, Straight/Box, Combination o Pairs.',
    'Decide el monto de la apuesta: $0.50 o $1, dependiendo del tipo de juego.',
    'Puedes jugar hasta 7 sorteos consecutivos con los mismos números.',
    'Espera al sorteo para ver si ganaste.',
    'Gana el premio máximo acertando los 3 dígitos en el orden exacto (apuesta Straight).',
    'Las apuestas Box permiten ganar si los números coinciden en cualquier orden.',
  ],
  multiplierRules: null, // No aplica
  claimPeriod: 'Los premios deben reclamarse dentro de un año a partir de la fecha del sorteo.',
  taxInfo: 'Los premios superiores a $5,000 están sujetos a impuestos federales y estatales.',
  officialWebsite: 'https://nylottery.ny.gov/draw-games/numbers',
  additionalInfo: {
    responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    typesOfPlay: [
      'Straight (Orden Exacto): Ganas si tus números coinciden en el orden exacto.',
      'Box (Cualquier Orden): Ganas si tus números coinciden en cualquier orden.',
      'Straight/Box: Combina apuestas Straight y Box en una sola jugada.',
      'Combination: Juega todas las posibles combinaciones de tus números (equivale a múltiples apuestas Straight).',
      'Pairs: Apuesta a los dos primeros dígitos (Front Pair) o los dos últimos dígitos (Back Pair).',
    ],
  },
},

  // New York Noche (Numbers Evening)
{
  name: 'New York Noche',
  slug: 'new-york-noche',
  fetchFunction: getNewYorkNoche, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'Estados Unidos',
  drawTimes: [
    {
      time: '22:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: '500',
  ticketPrice: '0.5 - 1',
  odds: {
    jackpot: '1 en 1,000',
    anyPrize: 'Varía según el tipo de apuesta',
  },
  numberRange: {
    main: { min: 0, max: 9 },
  },
  prizeCategories: [
    { type: 'Straight (Orden Exacto)', prize: '500' },
    { type: 'Box (Cualquier Orden)', prize: 'Varía' },
    { type: 'Straight/Box', prize: 'Varía' },
    { type: 'Combination', prize: 'Varía' },
    { type: 'Pairs', prize: 'Varía' },
  ],
  details: {
    description:
      'Numbers es un juego diario de la Lotería de Nueva York donde los jugadores eligen un número de 3 dígitos y un tipo de apuesta.',
    jackpotInfo:
      'El premio máximo es de $500 por una apuesta Straight de $1 (orden exacto).',
    drawInfo:
      'Los sorteos se realizan dos veces al día, todos los días, a las 14:30 (Tarde) y 22:30 (Noche) ET.',
    multiplierInfo: 'Numbers no ofrece una opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona un número de 3 dígitos del 000 al 999.',
    'Elige el tipo de apuesta: Straight, Box, Straight/Box, Combination o Pairs.',
    'Decide el monto de la apuesta: $0.50 o $1, dependiendo del tipo de juego.',
    'Puedes jugar hasta 7 sorteos consecutivos con los mismos números.',
    'Espera al sorteo para ver si ganaste.',
    'Gana el premio máximo acertando los 3 dígitos en el orden exacto (apuesta Straight).',
    'Las apuestas Box permiten ganar si los números coinciden en cualquier orden.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de un año a partir de la fecha del sorteo.',
  taxInfo: 'Los premios superiores a $5,000 están sujetos a impuestos federales y estatales.',
  officialWebsite: 'https://nylottery.ny.gov/draw-games/numbers',
  additionalInfo: {
    responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    typesOfPlay: [
      'Straight (Orden Exacto): Ganas si tus números coinciden en el orden exacto.',
      'Box (Cualquier Orden): Ganas si tus números coinciden en cualquier orden.',
      'Straight/Box: Combina apuestas Straight y Box en una sola jugada.',
      'Combination: Juega todas las posibles combinaciones de tus números (equivale a múltiples apuestas Straight).',
      'Pairs: Apuesta a los dos primeros dígitos (Front Pair) o los dos últimos dígitos (Back Pair).',
    ],
  },
},

  // Florida Día (Pick 3 Midday)
{
  name: 'Florida Día',
  slug: 'florida-dia',
  fetchFunction: getFloridaDia, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'Estados Unidos',
  drawTimes: [
    {
      time: '13:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: true, // Florida Pick 3 ofrece la opción "Fireball"
  minimumJackpot: '500', // Premio máximo de $500
  ticketPrice: '0.5 - 1', // $0.50 o $1 por jugada
  odds: {
    jackpot: '1 en 1,000',
    anyPrize: 'Varía según el tipo de apuesta',
  },
  numberRange: {
    main: { min: 0, max: 9 },
  },
  prizeCategories: [
    { type: 'Straight (Orden Exacto)', prize: '500' },
    { type: 'Box (Cualquier Orden)', prize: '160' },
    { type: 'Straight/Box', prize: '330' },
    { type: 'Combo', prize: '500' },
    { type: 'Front Pair / Back Pair', prize: '50' },
  ],
  details: {
    description:
      'Pick 3 es un juego de lotería diario ofrecido por la Lotería de Florida, donde los jugadores seleccionan un número de 3 dígitos y eligen el tipo de apuesta.',
    jackpotInfo:
      'El premio máximo es de $500 por una apuesta Straight de $1 (orden exacto).',
    drawInfo:
      'Los sorteos se realizan dos veces al día, todos los días, a las 13:30 (Día) y 21:45 (Noche) ET.',
    multiplierInfo: 'La opción "Fireball" permite reemplazar un número para crear nuevas combinaciones ganadoras.',
  },
  howToPlay: [
    'Selecciona un número de 3 dígitos del 000 al 999.',
    'Elige el tipo de apuesta: Straight, Box, Straight/Box, Combo, Front Pair o Back Pair.',
    'Decide el monto de la apuesta: $0.50 o $1.',
    'Opcionalmente, agrega "Fireball" por un costo adicional para más oportunidades de ganar.',
    'Espera al sorteo para ver si ganaste.',
    'Gana el premio máximo acertando los 3 dígitos en el orden exacto (apuesta Straight).',
    'La opción "Fireball" te permite reemplazar uno de los números sorteados para formar nuevas combinaciones ganadoras.',
  ],
  multiplierRules: [
    'La opción "Fireball" cuesta el mismo valor que tu apuesta base.',
    'El número Fireball se extrae después del sorteo principal.',
    'Puedes usar el número Fireball para reemplazar uno de los números sorteados y crear nuevas combinaciones ganadoras.',
  ],
  claimPeriod: 'Los premios deben reclamarse dentro de los 180 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a $5,000 están sujetos a impuestos federales y estatales.',
  officialWebsite: 'https://www.flalottery.com/pick3',
  additionalInfo: {
    responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    typesOfPlay: [
      'Straight (Orden Exacto): Ganas si tus números coinciden en el orden exacto.',
      'Box (Cualquier Orden): Ganas si tus números coinciden en cualquier orden.',
      'Straight/Box: Combina apuestas Straight y Box en una sola jugada.',
      'Combo: Juega todas las posibles combinaciones de tus números (equivale a múltiples apuestas Straight).',
      'Front Pair / Back Pair: Apuesta a los dos primeros o dos últimos dígitos.',
    ],
  },
},

  // Florida Noche (Pick 3 Evening)
{
  name: 'Florida Noche',
  slug: 'florida-noche',
  fetchFunction: getFloridaNoche, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'Estados Unidos',
  drawTimes: [
    {
      time: '21:45',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: true, // Opción "Fireball" disponible
  minimumJackpot: '500',
  ticketPrice: '0.5 - 1',
  odds: {
    jackpot: '1 en 1,000',
    anyPrize: 'Varía según el tipo de apuesta',
  },
  numberRange: {
    main: { min: 0, max: 9 },
  },
  prizeCategories: [
    { type: 'Straight (Orden Exacto)', prize: '500' },
    { type: 'Box (Cualquier Orden)', prize: '160' },
    { type: 'Straight/Box', prize: '330' },
    { type: 'Combo', prize: '500' },
    { type: 'Front Pair / Back Pair', prize: '50' },
  ],
  details: {
    description:
      'Pick 3 es un juego de lotería diario ofrecido por la Lotería de Florida, donde los jugadores seleccionan un número de 3 dígitos y eligen el tipo de apuesta.',
    jackpotInfo:
      'El premio máximo es de $500 por una apuesta Straight de $1 (orden exacto).',
    drawInfo:
      'Los sorteos se realizan dos veces al día, todos los días, a las 13:30 (Día) y 21:45 (Noche) ET.',
    multiplierInfo: 'La opción "Fireball" permite reemplazar un número para crear nuevas combinaciones ganadoras.',
  },
  howToPlay: [
    'Selecciona un número de 3 dígitos del 000 al 999.',
    'Elige el tipo de apuesta: Straight, Box, Straight/Box, Combo, Front Pair o Back Pair.',
    'Decide el monto de la apuesta: $0.50 o $1.',
    'Opcionalmente, agrega "Fireball" por un costo adicional para más oportunidades de ganar.',
    'Espera al sorteo para ver si ganaste.',
    'Gana el premio máximo acertando los 3 dígitos en el orden exacto (apuesta Straight).',
    'La opción "Fireball" te permite reemplazar uno de los números sorteados para formar nuevas combinaciones ganadoras.',
  ],
  multiplierRules: [
    'La opción "Fireball" cuesta el mismo valor que tu apuesta base.',
    'El número Fireball se extrae después del sorteo principal.',
    'Puedes usar el número Fireball para reemplazar uno de los números sorteados y crear nuevas combinaciones ganadoras.',
  ],
  claimPeriod: 'Los premios deben reclamarse dentro de los 180 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a $5,000 están sujetos a impuestos federales y estatales.',
  officialWebsite: 'https://www.flalottery.com/pick3',
  additionalInfo: {
    responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    typesOfPlay: [
      'Straight (Orden Exacto): Ganas si tus números coinciden en el orden exacto.',
      'Box (Cualquier Orden): Ganas si tus números coinciden en cualquier orden.',
      'Straight/Box: Combina apuestas Straight y Box en una sola jugada.',
      'Combo: Juega todas las posibles combinaciones de tus números (equivale a múltiples apuestas Straight).',
      'Front Pair / Back Pair: Apuesta a los dos primeros o dos últimos dígitos.',
    ],
  },
},
  
  // República Dominicana
  // Lotería Nacional
{
  name: 'Lotería Nacional',
  slug: 'loteria-nacional',
  fetchFunction: getNacional, // Asegúrate de tener esta función definida para obtener los resultados
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:50',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    },
    {
      time: '18:00',
      days: ['domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía según el juego',
    anyPrize: 'Varía según el juego',
  },
  numberRange: {
    main: { min: 0, max: 99 }, // Números del 00 al 99
  },
  prizeCategories: [
    { match: '1 Número (Quiniela)', prize: '60 veces la apuesta' },
    { match: '2 Números (Pale)', prize: '1000 veces la apuesta' },
    { match: '3 Números (Tripleta)', prize: '20,000 veces la apuesta' },
  ],
  details: {
    description:
      'La Lotería Nacional es una de las más antiguas y populares en la República Dominicana, ofreciendo juegos como Quiniela, Palé y Tripleta.',
    jackpotInfo:
      'El premio máximo depende del tipo de juego y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente, con horarios diferentes según el día de la semana.',
    multiplierInfo: 'No se ofrece una opción de multiplicador en estos juegos.',
  },
  howToPlay: [
    'Selecciona uno o más números del 00 al 99.',
    'Elige el tipo de juego: Quiniela, Palé o Tripleta.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
    'Ganas premios acertando los números según el tipo de juego seleccionado.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes dominicanas.',
  officialWebsite: 'https://www.loterianacional.gob.do/',
  additionalInfo: {
    responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    gameTypes: [
      'Quiniela: Acierta un número entre 00 y 99.',
      'Palé: Acierta dos números en el orden exacto.',
      'Tripleta: Acierta tres números en el orden exacto.',
    ],
  },
},
  // Gana Más
{
  name: 'Gana Más',
  slug: 'gana-mas',
  fetchFunction: getGanaMas, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '14:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía según el juego',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: '1 Número', prize: '60 veces la apuesta' },
  ],
  details: {
    description:
      'Gana Más es una lotería dominicana donde los jugadores seleccionan números del 00 al 99 para ganar premios basados en su apuesta.',
    jackpotInfo:
      'El premio máximo es de 60 veces el monto apostado por acertar un solo número.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 14:30 horas.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Elige un número del 00 al 99.',
    'Decide el monto que deseas apostar.',
    'Espera al sorteo diario para ver si tu número resulta ganador.',
    'Ganas si aciertas el número sorteado.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes dominicanas.',
  officialWebsite: 'No disponible oficialmente.',
  additionalInfo: {
    responsibleGaming: 'Se recomienda jugar de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},

// Lotería Real
{
  name: 'Lotería Real',
  slug: 'loteria-real',
  fetchFunction: getReal, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía según el juego',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 }, // Números del 00 al 99
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1,000 veces la apuesta' },
    { match: 'Tripleta', prize: '20,000 veces la apuesta' },
  ],
  details: {
    description:
      'Lotería Real es una de las loterías más populares en la República Dominicana, ofreciendo juegos tradicionales como Quiniela, Palé y Tripleta, con sorteos diarios.',
    jackpotInfo:
      'El premio máximo depende del tipo de juego y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 12:55 horas.',
    multiplierInfo: 'No se ofrece una opción de multiplicador en estos juegos.',
  },
  howToPlay: [
    'Selecciona uno, dos o tres números del 00 al 99.',
    'Elige el tipo de juego: Quiniela, Palé o Tripleta.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
    'Ganas premios acertando los números según el tipo de juego seleccionado.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes dominicanas.',
  officialWebsite: 'https://www.loteriareal.com.do/',
  additionalInfo: {
    responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    gameTypes: [
      'Quiniela: Acierta un número entre 00 y 99.',
      'Palé: Acierta dos números en el orden exacto.',
      'Tripleta: Acierta tres números en el orden exacto.',
    ],
  },
},
  // Pega Más
{
  name: 'Pega Más',
  slug: 'pega-mas',
  fetchFunction: getPegaMas, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '14:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía según el juego',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 28 },
  },
  prizeCategories: [
    { match: '5 Números', prize: 'Jackpot' },
    { match: '4 Números', prize: 'Segundo Premio' },
    { match: '3 Números', prize: 'Tercer Premio' },
  ],
  details: {
    description:
      'Pega Más es un juego de lotería donde se seleccionan números del 1 al 28, ofreciendo varias categorías de premios.',
    jackpotInfo:
      'El premio mayor se obtiene al acertar los 5 números sorteados.',
    drawInfo:
      'Los sorteos se realizan de lunes a sábado a las 14:30 horas.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona 5 números del 1 al 28.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Ganas premios al acertar 3, 4 o 5 números.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes dominicanas.',
  officialWebsite: 'No disponible oficialmente.',
  additionalInfo: {
    responsibleGaming: 'Se recomienda jugar de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},

// Tu Fecha Real
{
  name: 'Tu Fecha Real',
  slug: 'tu-fecha-real',
  fetchFunction: getQuinielita, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    day: { min: 1, max: 31 },
    month: { min: 1, max: 12 },
  },
  prizeCategories: [
    { match: 'Día y Mes', prize: 'Premio Mayor' },
    { match: 'Día', prize: 'Premio Secundario' },
  ],
  details: {
    description:
      'Tu Fecha Real es un juego de la Lotería Real donde los jugadores eligen un día y un mes, generalmente representando una fecha significativa, para participar en el sorteo.',
    jackpotInfo:
      'El premio mayor se obtiene al acertar tanto el día como el mes sorteados.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 12:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona un número de día entre 1 y 31.',
    'Selecciona un número de mes entre 1 y 12.',
    'Decide el monto que deseas apostar.',
    'Espera al sorteo para ver si tus números coinciden con los números ganadores.',
    'Ganas premios al acertar el día y el mes, o solo el día.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes dominicanas.',
  officialWebsite: 'https://www.loteriareal.com.do/',
  additionalInfo: {
    responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    specialNotes: 'Ideal para jugar fechas significativas como cumpleaños o aniversarios.',
  },
},
  // Loto Pool Real
{
  name: 'Loto Pool Real',
  slug: 'loto-pool-real',
  fetchFunction: getLotoPoolReal, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: '5,000,000', // RD$5 millones
  ticketPrice: '20', // RD$20 por jugada
  odds: {
    jackpot: '1 en 3,262,623',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 31 },
  },
  prizeCategories: [
    { match: '5', prize: 'Jackpot' },
    { match: '4', prize: 'Segundo Premio' },
    { match: '3', prize: 'Tercer Premio' },
  ],
  details: {
    description:
      'Loto Pool Real es un juego de lotería donde los jugadores seleccionan 5 números del 1 al 31 para ganar premios atractivos.',
    jackpotInfo:
      'El premio mayor es de RD$5 millones, que puede incrementarse si no hay ganadores.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 12:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona 5 números del 1 al 31.',
    'Compra tu boleto por RD$20.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana el jackpot acertando los 5 números.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a RD$100,000 están sujetos a impuestos.',
  officialWebsite: 'https://www.loteriareal.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Pega 4 Real
{
  name: 'Pega 4 Real',
  slug: 'pega-4-real',
  fetchFunction: getPega4Real, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: '1 en 10,000',
    anyPrize: 'Varía según el tipo de apuesta',
  },
  numberRange: {
    main: { min: 0, max: 9 }, // Números de 0 a 9 para cada dígito
  },
  prizeCategories: [
    { type: 'Exacto', prize: '50,000 veces la apuesta' },
    { type: 'Combinado', prize: 'Varía' },
  ],
  details: {
    description:
      'Pega 4 Real es un juego donde se selecciona un número de 4 dígitos y se elige el tipo de apuesta.',
    jackpotInfo:
      'El premio máximo es de 50,000 veces la apuesta en una jugada Exacta.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 12:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona un número de 4 dígitos del 0000 al 9999.',
    'Elige el tipo de apuesta: Exacta o Combinada.',
    'Decide el monto que deseas apostar.',
    'Espera al sorteo para ver si tu número resulta ganador.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.loteriareal.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Loto Pool
{
  name: 'Loto Pool',
  slug: 'loto-pool',
  fetchFunction: getLotoPoolLeidsa, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    },
    {
      time: '15:55',
      days: ['domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: '5,000,000', // RD$5 millones
  ticketPrice: '20', // RD$20 por jugada
  odds: {
    jackpot: '1 en 3,262,623',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 31 },
  },
  prizeCategories: [
    { match: '5', prize: 'Jackpot' },
    { match: '4', prize: 'Segundo Premio' },
    { match: '3', prize: 'Tercer Premio' },
  ],
  details: {
    description:
      'Loto Pool es un juego de Leidsa donde los jugadores seleccionan 5 números del 1 al 31 para ganar grandes premios.',
    jackpotInfo:
      'El premio mayor es de RD$5 millones, incrementándose si no hay ganadores.',
    drawInfo:
      'Los sorteos se realizan diariamente en horarios variables.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Elige 5 números del 1 al 31.',
    'Compra tu boleto por RD$20.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana premios acertando 3, 4 o 5 números.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a RD$100,000 están sujetos a impuestos.',
  officialWebsite: 'https://www.leidsa.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Nueva York Real
{
  name: 'Nueva York Real',
  slug: 'nueva-york-real',
  fetchFunction: getNYReal, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1000 veces la apuesta' },
  ],
  details: {
    description:
      'Nueva York Real es un juego que combina resultados de la Lotería de Nueva York y la Lotería Real de República Dominicana.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 12:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona uno o más números del 00 al 99.',
    'Elige el tipo de apuesta: Quiniela o Palé.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.loteriareal.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Loto Real
{
  name: 'Loto Real',
  slug: 'loto-real',
  fetchFunction: getLotoReal, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:50',
      days: ['martes', 'viernes'],
    },
  ],
  hasMultiplier: true, // Opción de multiplicador disponible
  minimumJackpot: 'Variable', // Comienza en RD$10 millones
  ticketPrice: '30', // RD$30 por jugada básica
  odds: {
    jackpot: '1 en 3,838,380',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 37 },
  },
  prizeCategories: [
    { match: '6', prize: 'Jackpot' },
    { match: '5', prize: 'Segundo Premio' },
    { match: '4', prize: 'Tercer Premio' },
  ],
  details: {
    description:
      'Loto Real es un juego donde los jugadores seleccionan 6 números del 1 al 37 para ganar grandes premios.',
    jackpotInfo:
      'El premio mayor comienza en RD$10 millones y se acumula si no hay ganadores.',
    drawInfo:
      'Los sorteos se realizan los martes y viernes a las 12:50 horas.',
    multiplierInfo: 'La opción "Multiplicador" aumenta los premios secundarios.',
  },
  howToPlay: [
    'Selecciona 6 números del 1 al 37.',
    'Opcionalmente, agrega el "Multiplicador" por un costo adicional.',
    'Compra tu boleto por RD$30.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana el jackpot acertando los 6 números.',
  ],
  multiplierRules: [
    'El "Multiplicador" aumenta los premios secundarios (no el jackpot).',
    'El costo adicional es de RD$10 por jugada.',
    'El factor multiplicador se sortea en cada juego.',
  ],
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a RD$100,000 están sujetos a impuestos.',
  officialWebsite: 'https://www.loteriareal.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Super Pale
{
  name: 'Super Pale',
  slug: 'super-pale-real',
  fetchFunction: getSuperPaleReal, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Palé', prize: 'Varía según la apuesta' },
  ],
  details: {
    description:
      'Super Pale es un juego que combina resultados de diferentes loterías para ofrecer premios atractivos.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 12:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona dos números del 00 al 99.',
    'Decide el monto que deseas apostar.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
    'Ganas premios acertando los números seleccionados.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.loteriareal.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Pega 3 Más
{
  name: 'Pega 3 Más',
  slug: 'pega-3-mas',
  fetchFunction: getPega3MasLeidsa, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    },
    {
      time: '15:55',
      days: ['domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: '1 en 1,000',
    anyPrize: 'Varía según el tipo de apuesta',
  },
  numberRange: {
    main: { min: 0, max: 9 },
  },
  prizeCategories: [
    { type: 'Exacto', prize: '500 veces la apuesta' },
    { type: 'Combinado', prize: 'Varía' },
  ],
  details: {
    description:
      'Pega 3 Más es un juego de Leidsa donde se selecciona un número de 3 dígitos y se elige el tipo de apuesta.',
    jackpotInfo:
      'El premio máximo es de 500 veces la apuesta en una jugada Exacta.',
    drawInfo:
      'Los sorteos se realizan diariamente en horarios variables.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona un número de 3 dígitos del 000 al 999.',
    'Elige el tipo de apuesta: Exacta o Combinada.',
    'Decide el monto que deseas apostar.',
    'Espera al sorteo para ver si tu número resulta ganador.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.leidsa.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Quiniela Pale
{
  name: 'Quiniela Pale',
  slug: 'quiniela-leidsa',
  fetchFunction: getQuinielaPale, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    },
    {
      time: '15:55',
      days: ['domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1000 veces la apuesta' },
  ],
  details: {
    description:
      'Quiniela Pale es un juego de Leidsa que ofrece la posibilidad de ganar premios al acertar uno o dos números.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente en horarios variables.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona uno o dos números del 00 al 99.',
    'Elige el tipo de apuesta: Quiniela o Palé.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.leidsa.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Super Kino TV
{
  name: 'Super Kino TV',
  slug: 'super-kino-tv',
  fetchFunction: getSuperKinoTVLeidsa, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    },
    {
      time: '15:55',
      days: ['domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Varía',
  ticketPrice: '25', // RD$25 por jugada
  odds: {
    jackpot: '1 en 2,118,760',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 80 },
  },
  prizeCategories: [
    { match: '10', prize: 'RD$25,000,000' },
    { match: '9', prize: 'RD$100,000' },
    { match: '8', prize: 'RD$10,000' },
    // Otros niveles de premios
  ],
  details: {
    description:
      'Super Kino TV es un juego tipo keno de Leidsa donde los jugadores seleccionan hasta 10 números del 1 al 80.',
    jackpotInfo:
      'El premio mayor es de RD$25 millones al acertar 10 números.',
    drawInfo:
      'Los sorteos se realizan diariamente en horarios variables.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona de 1 a 10 números del 1 al 80.',
    'Compra tu boleto por RD$25.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana premios según la cantidad de números acertados.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a RD$100,000 están sujetos a impuestos.',
  officialWebsite: 'https://www.leidsa.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Loto - Super Loto Más
{
  name: 'Loto - Super Loto Más',
  slug: 'loto-super-loto-mas',
  fetchFunction: getLotomasLeidsa, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:55',
      days: ['miércoles', 'sábado'],
    },
  ],
  hasMultiplier: true, // Opción "Más" disponible
  minimumJackpot: 'Variable', // Comienza en RD$15 millones
  ticketPrice: '30', // RD$30 por jugada básica
  odds: {
    jackpot: '1 en 3,262,623',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 38 },
  },
  prizeCategories: [
    { match: '6', prize: 'Jackpot' },
    { match: '5 + Más', prize: 'Segundo Premio' },
    { match: '5', prize: 'Tercer Premio' },
    // Otros niveles de premios
  ],
  details: {
    description:
      'Loto Más es un juego de Leidsa donde los jugadores seleccionan 6 números del 1 al 38 y tienen la opción de agregar "Más" para mayores premios.',
    jackpotInfo:
      'El premio mayor comienza en RD$15 millones y se acumula si no hay ganadores.',
    drawInfo:
      'Los sorteos se realizan los miércoles y sábados a las 20:55 horas.',
    multiplierInfo: 'La opción "Más" aumenta los premios secundarios.',
  },
  howToPlay: [
    'Selecciona 6 números del 1 al 38.',
    'Opcionalmente, agrega "Más" por un costo adicional.',
    'Compra tu boleto por RD$30.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana el jackpot acertando los 6 números.',
  ],
  multiplierRules: [
    'La opción "Más" cuesta RD$20 adicionales.',
    'Aumenta los premios secundarios al agregar el número "Más".',
    'El número "Más" se extrae del mismo rango de números.',
  ],
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a RD$100,000 están sujetos a impuestos.',
  officialWebsite: 'https://www.leidsa.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Super Pale Leidsa
{
  name: 'Super Pale Leidsa',
  slug: 'super-pale',
  fetchFunction: getSuperPaleLeidsa, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    },
    {
      time: '15:55',
      days: ['domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Palé', prize: 'Varía según la apuesta' },
  ],
  details: {
    description:
      'Super Pale Leidsa es un juego que combina resultados de diferentes loterías para ofrecer premios atractivos.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente en horarios variables.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona dos números del 00 al 99.',
    'Decide el monto que deseas apostar.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
    'Ganas premios acertando los números seleccionados.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.leidsa.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // El Quinielón Día
{
  name: 'El Quinielón Día',
  slug: 'el-quinielon-dia',
  fetchFunction: getQuinielonDia, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:00',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1000 veces la apuesta' },
    { match: 'Tripleta', prize: '20,000 veces la apuesta' },
  ],
  details: {
    description:
      'El Quinielón Día es un juego de la Lotería Nacional donde se pueden ganar premios al acertar uno, dos o tres números.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 12:00 horas.',
    multiplierInfo: 'No hay opción de multiplicador.',
  },
  howToPlay: [
    'Selecciona uno, dos o tres números del 00 al 99.',
    'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.loterianacional.gob.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
 // Primera Noche
{
  name: 'Primera Noche',
  slug: 'primera-noche',
  fetchFunction: getPrimeraNoche, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:00',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía según el juego',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 }, // Números del 00 al 99
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1,000 veces la apuesta' },
    { match: 'Tripleta', prize: '20,000 veces la apuesta' },
  ],
  details: {
    description:
      'La Primera Noche es un sorteo nocturno de la Lotería La Primera, donde los jugadores pueden participar en juegos como Quiniela, Palé y Tripleta.',
    jackpotInfo:
      'El premio máximo depende del tipo de juego y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 20:00 horas.',
    multiplierInfo: 'No se ofrece una opción de multiplicador en estos juegos.',
  },
  howToPlay: [
    'Selecciona uno, dos o tres números del 00 al 99.',
    'Elige el tipo de juego: Quiniela, Palé o Tripleta.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
    'Ganas premios acertando los números según el tipo de juego seleccionado.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes dominicanas.',
  officialWebsite: 'https://www.laprimera.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    gameTypes: [
      'Quiniela: Acierta un número entre 00 y 99.',
      'Palé: Acierta dos números en el orden exacto.',
      'Tripleta: Acierta tres números en el orden exacto.',
    ],
  },
},
  // La Primera Día
{
  name: 'La Primera Día',
  slug: 'la-primera-dia',
  fetchFunction: getPrimeraDia, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:00',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía según el juego',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1,000 veces la apuesta' },
    { match: 'Tripleta', prize: '20,000 veces la apuesta' },
  ],
  details: {
    description:
      'La Primera Día es el sorteo diurno de la Lotería La Primera, ofreciendo juegos como Quiniela, Palé y Tripleta.',
    jackpotInfo:
      'El premio máximo depende del tipo de juego y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 12:00 horas.',
    multiplierInfo: 'No se ofrece una opción de multiplicador en estos juegos.',
  },
  howToPlay: [
    'Selecciona uno, dos o tres números del 00 al 99.',
    'Elige el tipo de juego: Quiniela, Palé o Tripleta.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
    'Ganas premios acertando los números según el tipo de juego seleccionado.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes dominicanas.',
  officialWebsite: 'https://www.laprimera.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
    gameTypes: [
      'Quiniela: Acierta un número entre 00 y 99.',
      'Palé: Acierta dos números en el orden exacto.',
      'Tripleta: Acierta tres números en el orden exacto.',
    ],
  },
},
 // Loto 5
{
  name: 'Loto 5',
  slug: 'loto-5',
  fetchFunction: getLoto5, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:00',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: '1,000,000', // RD$1 millón
  ticketPrice: '20', // RD$20 por jugada
  odds: {
    jackpot: '1 en 201,376',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 36 },
  },
  prizeCategories: [
    { match: '5', prize: 'Jackpot' },
    { match: '4', prize: 'Segundo Premio' },
    { match: '3', prize: 'Tercer Premio' },
  ],
  details: {
    description:
      'Loto 5 es un juego de la Lotería La Primera donde los jugadores seleccionan 5 números del 1 al 36 para tener la oportunidad de ganar grandes premios.',
    jackpotInfo:
      'El premio mayor es de RD$1 millón y se acumula si no hay ganadores.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 20:00 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona 5 números del 1 al 36.',
    'Compra tu boleto por RD$20.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana el jackpot acertando los 5 números.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a RD$100,000 están sujetos a impuestos.',
  officialWebsite: 'https://www.laprimera.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // El Quinielón Noche
{
  name: 'El Quinielón Noche',
  slug: 'el-quinielon-noche',
  fetchFunction: getQuinielonNoche, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '20:00',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1,000 veces la apuesta' },
    { match: 'Tripleta', prize: '20,000 veces la apuesta' },
  ],
  details: {
    description:
      'El Quinielón Noche es el sorteo nocturno de la Lotería Nacional, ofreciendo la oportunidad de ganar grandes premios en juegos como Quiniela, Palé y Tripleta.',
    jackpotInfo:
      'El premio depende del tipo de juego y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 20:00 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona uno, dos o tres números del 00 al 99.',
    'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
    'Decide el monto que deseas apostar.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.loterianacional.gob.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Toca 3
{
  name: 'Toca 3',
  slug: 'toca-3',
  fetchFunction: getToca3Loteka, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '19:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'RD$50,000',
  ticketPrice: '10', // RD$10 por jugada
  odds: {
    jackpot: '1 en 1,000',
    anyPrize: 'Varía según el tipo de apuesta',
  },
  numberRange: {
    main: { min: 0, max: 9 }, // Números del 0 al 9 para cada dígito
  },
  prizeCategories: [
    { type: 'Exacto', prize: 'RD$50,000' },
    { type: 'Combinado', prize: 'Varía' },
  ],
  details: {
    description:
      'Toca 3 es un juego de Loteka donde los jugadores seleccionan un número de 3 dígitos y eligen el tipo de apuesta para ganar premios en efectivo.',
    jackpotInfo:
      'El premio máximo es de RD$50,000 por una apuesta exacta de RD$10.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 19:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona un número de 3 dígitos del 000 al 999.',
    'Elige el tipo de apuesta: Exacta o Combinada.',
    'Compra tu boleto por RD$10.',
    'Espera al sorteo para ver si tu número resulta ganador.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a RD$100,000 están sujetos a impuestos.',
  officialWebsite: 'https://www.loteka.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
 // MC Repartidera
{
  name: 'MC Repartidera',
  slug: 'mc-repartidera',
  fetchFunction: getMCRepatideraLoteka, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '19:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 31 },
  },
  prizeCategories: [
    { match: '5', prize: 'Jackpot' },
    { match: '4', prize: 'Segundo Premio' },
    { match: '3', prize: 'Tercer Premio' },
  ],
  details: {
    description:
      'MC Repartidera es un juego de Loteka donde los jugadores seleccionan 5 números del 1 al 31 para ganar premios en efectivo.',
    jackpotInfo:
      'El premio mayor es variable y depende de la acumulación de fondos.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 19:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona 5 números del 1 al 31.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana el jackpot acertando los 5 números.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes dominicanas.',
  officialWebsite: 'https://www.loteka.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Quini Loteka
{
  name: 'Quiniela Loteka',
  slug: 'quiniela-loteka',
  fetchFunction: getQuinielaLoteka, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '19:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1,000 veces la apuesta' },
    { match: 'Tripleta', prize: '20,000 veces la apuesta' },
  ],
  details: {
    description:
      'Quini Loteka es un juego de lotería que ofrece la oportunidad de ganar premios al acertar uno, dos o tres números del 00 al 99.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 19:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona uno, dos o tres números del 00 al 99.',
    'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.loteka.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Megalotto
{
  name: 'Megalotto',
  slug: 'megalotto',
  fetchFunction: getMegaLotoLoteka, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '19:55',
      days: ['lunes', 'jueves'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: '10,000,000', // RD$10 millones
  ticketPrice: '30', // RD$30 por jugada
  odds: {
    jackpot: '1 en 3,262,623',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 38 },
  },
  prizeCategories: [
    { match: '6', prize: 'Jackpot' },
    { match: '5', prize: 'Segundo Premio' },
    { match: '4', prize: 'Tercer Premio' },
  ],
  details: {
    description:
      'Megalotto es el juego principal de Loteka donde los jugadores seleccionan 6 números del 1 al 38 para tener la oportunidad de ganar grandes premios.',
    jackpotInfo:
      'El premio mayor comienza en RD$10 millones y se acumula si no hay ganadores.',
    drawInfo:
      'Los sorteos se realizan los lunes y jueves a las 19:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona 6 números del 1 al 38.',
    'Compra tu boleto por RD$30.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana el jackpot acertando los 6 números.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios superiores a RD$100,000 están sujetos a impuestos.',
  officialWebsite: 'https://www.loteka.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Mega Chances
{
  name: 'Mega Chances',
  slug: 'mega-chances',
  fetchFunction: getMegaChanceLoteka, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '19:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: '20', // RD$20 por jugada
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: '4 Números', prize: 'Jackpot' },
    { match: '3 Números', prize: 'Segundo Premio' },
    { match: '2 Números', prize: 'Tercer Premio' },
  ],
  details: {
    description:
      'Mega Chances es un juego de Loteka donde los jugadores seleccionan 4 números del 00 al 99 para ganar premios al acertar combinaciones de números.',
    jackpotInfo:
      'El premio mayor es variable y depende de las ventas y el monto acumulado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 19:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona 4 números del 00 al 99.',
    'Compra tu boleto por RD$20.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana premios al acertar 2, 3 o 4 números.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.loteka.com/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
}
,
  // La Suerte 12:30
{
  name: 'La Suerte 12:30',
  slug: 'la-suerte-dia',
  fetchFunction: getSuerteDia, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '12:30',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1,000 veces la apuesta' },
    { match: 'Tripleta', prize: '20,000 veces la apuesta' },
  ],
  details: {
    description:
      'La Suerte 12:30 es el sorteo diurno de la Lotería La Suerte, ofreciendo juegos tradicionales como Quiniela, Palé y Tripleta.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 12:30 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona uno, dos o tres números del 00 al 99.',
    'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.lasuerte.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // La Suerte 18:00
{
  name: 'La Suerte 18:00',
  slug: 'la-suerte-noche',
  fetchFunction: getSuerteNoche, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '18:00',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1,000 veces la apuesta' },
    { match: 'Tripleta', prize: '20,000 veces la apuesta' },
  ],
  details: {
    description:
      'La Suerte 18:00 es el sorteo vespertino de la Lotería La Suerte, ofreciendo juegos como Quiniela, Palé y Tripleta.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 18:00 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona uno, dos o tres números del 00 al 99.',
    'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 60 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.lasuerte.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable y establece un presupuesto de juego.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
 // Quiniela Lotedom
{
  name: 'Quiniela Lotedom',
  slug: 'quiniela-lotedom',
  fetchFunction: getAgarra4Lotedom, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '17:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Quiniela', prize: '60 veces la apuesta' },
    { match: 'Palé', prize: '1,000 veces la apuesta' },
  ],
  details: {
    description:
      'Quiniela Lotedom es un juego de LoteDom donde los jugadores pueden ganar premios al acertar uno o dos números del 00 al 99.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 17:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona uno o dos números del 00 al 99.',
    'Elige el tipo de apuesta: Quiniela o Palé.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.lotedom.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Agarra 4
{
  name: 'Agarra 4',
  slug: 'agarra-4',
  fetchFunction: getAgarra4Lotedom, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '17:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: '20', // RD$20 por jugada
  odds: {
    jackpot: '1 en 10,000',
    anyPrize: 'Varía según el tipo de apuesta',
  },
  numberRange: {
    main: { min: 0, max: 9 },
  },
  prizeCategories: [
    { type: 'Exacto', prize: 'RD$50,000' },
    { type: 'Combinado', prize: 'Varía' },
  ],
  details: {
    description:
      'Agarra 4 es un juego de LoteDom donde los jugadores seleccionan un número de 4 dígitos para ganar premios en efectivo.',
    jackpotInfo:
      'El premio máximo es de RD$50,000 por una apuesta exacta.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 17:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona un número de 4 dígitos del 0000 al 9999.',
    'Elige el tipo de apuesta: Exacta o Combinada.',
    'Compra tu boleto por RD$20.',
    'Espera al sorteo para ver si tu número resulta ganador.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.lotedom.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // El Quemaito Mayor
{
  name: 'El Quemaito Mayor',
  slug: 'el-quemaito-mayor',
  fetchFunction: getQuemaito, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '17:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 1, max: 31 },
  },
  prizeCategories: [
    { match: '5 Números', prize: 'Jackpot' },
    { match: '4 Números', prize: 'Segundo Premio' },
    { match: '3 Números', prize: 'Tercer Premio' },
  ],
  details: {
    description:
      'El Quemaito Mayor es un juego de LoteDom donde los jugadores seleccionan 5 números del 1 al 31 para ganar premios.',
    jackpotInfo:
      'El premio mayor es variable y depende de la acumulación de fondos.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 17:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona 5 números del 1 al 31.',
    'Decide el monto de tu apuesta.',
    'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    'Gana premios al acertar 3, 4 o 5 números.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.lotedom.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},
  // Super Pale Lotedom
{
  name: 'Super Pale Lotedom',
  slug: 'super-pale-lotedom',
  fetchFunction: getSuperPaleLotedom, // Asegúrate de tener esta función definida
  country: 'República Dominicana',
  drawTimes: [
    {
      time: '17:55',
      days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
    },
  ],
  hasMultiplier: false,
  minimumJackpot: 'Variable',
  ticketPrice: 'Varía según la apuesta',
  odds: {
    jackpot: 'Varía',
    anyPrize: 'Varía',
  },
  numberRange: {
    main: { min: 0, max: 99 },
  },
  prizeCategories: [
    { match: 'Palé', prize: 'Varía según la apuesta' },
  ],
  details: {
    description:
      'Super Pale Lotedom es un juego que combina resultados de diferentes loterías para ofrecer premios atractivos al acertar dos números.',
    jackpotInfo:
      'El premio depende del tipo de apuesta y el monto apostado.',
    drawInfo:
      'Los sorteos se realizan diariamente a las 17:55 horas.',
    multiplierInfo: 'No hay opción de multiplicador en este juego.',
  },
  howToPlay: [
    'Selecciona dos números del 00 al 99.',
    'Decide el monto que deseas apostar.',
    'Espera al sorteo para ver si tus números resultan ganadores.',
    'Gana premios acertando los números seleccionados.',
  ],
  multiplierRules: null,
  claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
  taxInfo: 'Los premios pueden estar sujetos a impuestos.',
  officialWebsite: 'https://www.lotedom.com.do/',
  additionalInfo: {
    responsibleGaming: 'Juega de manera responsable.',
    ageRequirement: 'Debes tener al menos 18 años para jugar.',
  },
},

  // Anguila
  {
    name: 'Anguila Mañana',
    slug: 'anguila-manana',
    fetchFunction: getAnguilaManana, // Asegúrate de tener esta función definida
    country: 'Anguila',
    drawTimes: [
      {
        time: '10:00',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Variable',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: 'Varía según el tipo de apuesta',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 0, max: 99 }, // Números del 00 al 99
    },
    prizeCategories: [
      { match: '1 Número', prize: '70 veces la apuesta' },
      { match: '2 Números', prize: '3,500 veces la apuesta' },
      { match: '3 Números', prize: '90,000 veces la apuesta' },
    ],
    details: {
      description:
        'Anguila Mañana es el primer sorteo del día en Anguila, ofreciendo juegos como Quiniela, Palé y Tripleta. Es popular entre los jugadores que prefieren participar en los sorteos matutinos.',
      jackpotInfo:
        'El premio máximo depende del tipo de juego y el monto apostado. Las ganancias pueden ser significativas, especialmente en apuestas de Tripleta.',
      drawInfo:
        'El sorteo se realiza diariamente a las 10:00 AM hora local de Anguila.',
      multiplierInfo: 'No se ofrece una opción de multiplicador en estos juegos.',
    },
    howToPlay: [
      'Selecciona uno, dos o tres números del 00 al 99.',
      'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
      'Decide el monto que deseas apostar.',
      'Espera al sorteo para ver si tus números resultan ganadores.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes locales.',
    officialWebsite: 'N/A',
    additionalInfo: {
      responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Anguila Medio Dia',
    slug: 'anguila-medio-dia',
    fetchFunction: getAnguila12AM, // Asegúrate de tener esta función definida
    country: 'Anguila',
    drawTimes: [
      {
        time: '13:00',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Variable',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: 'Varía según el tipo de apuesta',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 0, max: 99 },
    },
    prizeCategories: [
      { match: '1 Número', prize: '70 veces la apuesta' },
      { match: '2 Números', prize: '3,500 veces la apuesta' },
      { match: '3 Números', prize: '90,000 veces la apuesta' },
    ],
    details: {
      description:
        'Anguila Medio Día es el segundo sorteo diario en Anguila, ofreciendo a los jugadores una oportunidad adicional de ganar premios en efectivo durante el día.',
      jackpotInfo:
        'Los premios varían según el tipo de apuesta y el monto apostado.',
      drawInfo:
        'El sorteo se realiza diariamente a la 1:00 PM hora local de Anguila.',
      multiplierInfo: 'No se ofrece una opción de multiplicador en estos juegos.',
    },
    howToPlay: [
      'Selecciona uno, dos o tres números del 00 al 99.',
      'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
      'Decide el monto que deseas apostar.',
      'Espera al sorteo para ver si tus números resultan ganadores.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes locales.',
    officialWebsite: 'N/A',
    additionalInfo: {
      responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Anguila Tarde',
    slug: 'anguila-tarde',
    fetchFunction: getAnguilaTarde, // Asegúrate de tener esta función definida
    country: 'Anguila',
    drawTimes: [
      {
        time: '18:00',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Variable',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: 'Varía según el tipo de apuesta',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 0, max: 99 },
    },
    prizeCategories: [
      { match: '1 Número', prize: '70 veces la apuesta' },
      { match: '2 Números', prize: '3,500 veces la apuesta' },
      { match: '3 Números', prize: '90,000 veces la apuesta' },
    ],
    details: {
      description:
        'Anguila Tarde ofrece a los jugadores otra oportunidad de participar en juegos emocionantes como Quiniela, Palé y Tripleta durante la tarde.',
      jackpotInfo:
        'Los premios dependen del tipo de apuesta y el monto apostado.',
      drawInfo:
        'El sorteo se realiza diariamente a las 6:00 PM hora local de Anguila.',
      multiplierInfo: 'No se ofrece una opción de multiplicador en estos juegos.',
    },
    howToPlay: [
      'Selecciona uno, dos o tres números del 00 al 99.',
      'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
      'Decide el monto que deseas apostar.',
      'Espera al sorteo para ver si tus números resultan ganadores.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes locales.',
    officialWebsite: 'N/A',
    additionalInfo: {
      responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Anguila Noche',
    slug: 'anguila-noche',
    fetchFunction: getAnguilaNoche, // Asegúrate de tener esta función definida
    country: 'Anguila',
    drawTimes: [
      {
        time: '21:00',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Variable',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: 'Varía según el tipo de apuesta',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 0, max: 99 },
    },
    prizeCategories: [
      { match: '1 Número', prize: '70 veces la apuesta' },
      { match: '2 Números', prize: '3,500 veces la apuesta' },
      { match: '3 Números', prize: '90,000 veces la apuesta' },
    ],
    details: {
      description:
        'Anguila Noche es el último sorteo del día, brindando una oportunidad final para que los jugadores ganen premios antes de que termine el día.',
      jackpotInfo:
        'Los premios varían según el tipo de apuesta y el monto apostado.',
      drawInfo:
        'El sorteo se realiza diariamente a las 9:00 PM hora local de Anguila.',
      multiplierInfo: 'No se ofrece una opción de multiplicador en estos juegos.',
    },
    howToPlay: [
      'Selecciona uno, dos o tres números del 00 al 99.',
      'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
      'Decide el monto que deseas apostar.',
      'Espera al sorteo para ver si tus números resultan ganadores.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes locales.',
    officialWebsite: 'N/A',
    additionalInfo: {
      responsibleGaming: 'Se recomienda jugar de manera responsable y establecer un presupuesto.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },

  // San Martín
  {
    name: 'King Lottery Noche',
    slug: 'king-lottery-noche',
    fetchFunction: getKingLotteryNoche, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '19:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Variable',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: 'Varía',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 1, max: 100 },
    },
    prizeCategories: [
      { match: '1 Número', prize: '75 veces la apuesta' },
      { match: '2 Números', prize: '5,000 veces la apuesta' },
      { match: '3 Números', prize: '100,000 veces la apuesta' },
    ],
    details: {
      description:
        'King Lottery Noche es el sorteo nocturno que brinda una segunda oportunidad diaria de ganar grandes premios en San Martín.',
      jackpotInfo:
        'Los premios máximos dependen del tipo de apuesta y el monto apostado.',
      drawInfo:
        'El sorteo se realiza diariamente a las 7:30 PM hora local de San Martín.',
      multiplierInfo: 'No hay opción de multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona uno, dos o tres números del 01 al 100.',
      'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
      'Decide cuánto deseas apostar.',
      'Espera al sorteo para ver si tus números resultan ganadores.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes de San Martín.',
    officialWebsite: 'https://kinglotterysxm.com/',
    additionalInfo: {
      responsibleGaming: 'Juega de manera responsable y establece límites de gasto.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'King Lottery Día',
    slug: 'king-lottery-dia',
    fetchFunction: getKingLotteryDia, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '12:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Variable',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: 'Varía',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 1, max: 100 }, // Números del 01 al 100
    },
    prizeCategories: [
      { match: '1 Número', prize: '75 veces la apuesta' },
      { match: '2 Números', prize: '5,000 veces la apuesta' },
      { match: '3 Números', prize: '100,000 veces la apuesta' },
    ],
    details: {
      description:
        'King Lottery Día es el sorteo diurno de San Martín, ofreciendo a los jugadores la oportunidad de ganar premios significativos al acertar números en diferentes categorías de juego.',
      jackpotInfo:
        'El premio máximo depende del tipo de apuesta y el monto apostado.',
      drawInfo:
        'El sorteo se realiza diariamente a las 12:30 PM hora local de San Martín.',
      multiplierInfo: 'No hay opción de multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona uno, dos o tres números del 01 al 100.',
      'Elige el tipo de apuesta: Quiniela, Palé o Tripleta.',
      'Decide cuánto deseas apostar.',
      'Espera al sorteo para ver si tus números resultan ganadores.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos según las leyes de San Martín.',
    officialWebsite: 'https://kinglotterysxm.com/',
    additionalInfo: {
      responsibleGaming: 'Juega de manera responsable y establece límites de gasto.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Pick 3 Día',
    slug: 'pick-3-dia',
    fetchFunction: getPick3Dia, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '12:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Hasta $500',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: '1 en 1,000',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 0, max: 9 }, // Números del 0 al 9 para cada dígito
    },
    prizeCategories: [
      { type: 'Exacto', prize: 'Hasta $500' },
      { type: 'Combinado', prize: 'Varía' },
    ],
    details: {
      description:
        'Pick 3 Día es un juego donde los jugadores seleccionan un número de tres dígitos y pueden ganar premios al acertar el número en el orden exacto o en cualquier orden, dependiendo del tipo de apuesta.',
      jackpotInfo:
        'El premio máximo es de hasta $500 por una apuesta exacta.',
      drawInfo:
        'El sorteo se realiza diariamente a las 12:30 PM hora local de San Martín.',
      multiplierInfo: 'No hay multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona un número de tres dígitos del 000 al 999.',
      'Elige el tipo de apuesta: Exacta o Combinada.',
      'Decide el monto de tu apuesta.',
      'Espera al sorteo y verifica si tu número ha sido seleccionado.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos.',
    officialWebsite: 'https://kinglotterysxm.com/',
    additionalInfo: {
      responsibleGaming: 'Juega con moderación y sé consciente de tus límites.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Pick 3 Noche',
    slug: 'pick-3-noche',
    fetchFunction: getPick3Noche, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '19:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Hasta $500',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: '1 en 1,000',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 0, max: 9 },
    },
    prizeCategories: [
      { type: 'Exacto', prize: 'Hasta $500' },
      { type: 'Combinado', prize: 'Varía' },
    ],
    details: {
      description:
        'Pick 3 Noche ofrece una segunda oportunidad diaria para ganar en el juego Pick 3, con las mismas reglas y premios que el sorteo diurno.',
      jackpotInfo:
        'El premio máximo es de hasta $500 por una apuesta exacta.',
      drawInfo:
        'El sorteo se realiza diariamente a las 7:30 PM hora local de San Martín.',
      multiplierInfo: 'No hay multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona un número de tres dígitos del 000 al 999.',
      'Elige el tipo de apuesta: Exacta o Combinada.',
      'Decide el monto de tu apuesta.',
      'Espera al sorteo y verifica si tu número ha sido seleccionado.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos.',
    officialWebsite: 'https://kinglotterysxm.com/',
    additionalInfo: {
      responsibleGaming: 'Juega con moderación y sé consciente de tus límites.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Pick 4 Día',
    slug: 'pick-4-dia',
    fetchFunction: getPick4Dia, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '12:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Hasta $5,000',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: '1 en 10,000',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 0, max: 9 }, // Números del 0 al 9 para cada dígito
    },
    prizeCategories: [
      { type: 'Exacto', prize: 'Hasta $5,000' },
      { type: 'Combinado', prize: 'Varía' },
    ],
    details: {
      description:
        'Pick 4 Día es un juego donde los jugadores eligen un número de cuatro dígitos y pueden ganar grandes premios al acertar el número en el orden exacto o en cualquier orden.',
      jackpotInfo:
        'El premio máximo es de hasta $5,000 por una apuesta exacta.',
      drawInfo:
        'El sorteo se realiza diariamente a las 12:30 PM hora local de San Martín.',
      multiplierInfo: 'No hay multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona un número de cuatro dígitos del 0000 al 9999.',
      'Elige el tipo de apuesta: Exacta o Combinada.',
      'Decide el monto de tu apuesta.',
      'Espera al sorteo y verifica si tu número ha sido seleccionado.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos.',
    officialWebsite: 'https://kinglotterysxm.com/',
    additionalInfo: {
      responsibleGaming: 'Juega con moderación y sé consciente de tus límites.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Pick 4 Noche',
    slug: 'pick-4-noche',
    fetchFunction: getPick4Noche, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '19:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Hasta $5,000',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: '1 en 10,000',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 0, max: 9 },
    },
    prizeCategories: [
      { type: 'Exacto', prize: 'Hasta $5,000' },
      { type: 'Combinado', prize: 'Varía' },
    ],
    details: {
      description:
        'Pick 4 Noche ofrece otra oportunidad diaria para ganar en el juego Pick 4, con los mismos premios y reglas que el sorteo diurno.',
      jackpotInfo:
        'El premio máximo es de hasta $5,000 por una apuesta exacta.',
      drawInfo:
        'El sorteo se realiza diariamente a las 7:30 PM hora local de San Martín.',
      multiplierInfo: 'No hay multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona un número de cuatro dígitos del 0000 al 9999.',
      'Elige el tipo de apuesta: Exacta o Combinada.',
      'Decide el monto de tu apuesta.',
      'Espera al sorteo y verifica si tu número ha sido seleccionado.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro de los 90 días posteriores al sorteo.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos.',
    officialWebsite: 'https://kinglotterysxm.com/',
    additionalInfo: {
      responsibleGaming: 'Juega con moderación y sé consciente de tus límites.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Philipsburg Medio Día',
    slug: 'philipsburg-medio-dia',
    fetchFunction: getPhilipsburgDia, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '12:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Variable',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: 'Varía',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 1, max: 36 }, // Números del 01 al 36
    },
    prizeCategories: [
      { match: '1 Número', prize: 'Premio según apuesta' },
      { match: '2 Números', prize: 'Premio mayor' },
    ],
    details: {
      description:
        'Philipsburg Medio Día es un juego de lotería popular en San Martín, donde los jugadores seleccionan números y pueden ganar premios al acertar uno o más números.',
      jackpotInfo:
        'El premio máximo depende del número de aciertos y el monto apostado.',
      drawInfo:
        'El sorteo se realiza diariamente a las 12:30 PM hora local.',
      multiplierInfo: 'No hay multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona uno o más números del 01 al 36.',
      'Decide el tipo de apuesta y el monto.',
      'Espera al sorteo para ver si tus números son seleccionados.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro del período establecido por la lotería.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos locales.',
    officialWebsite: 'N/A',
    additionalInfo: {
      responsibleGaming: 'Participa de manera responsable y establece límites.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Philipsburg Noche',
    slug: 'philipsburg-noche',
    fetchFunction: getPhilipsburgNoche, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '19:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Variable',
    ticketPrice: 'Varía según la apuesta',
    odds: {
      jackpot: 'Varía',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 1, max: 36 },
    },
    prizeCategories: [
      { match: '1 Número', prize: 'Premio según apuesta' },
      { match: '2 Números', prize: 'Premio mayor' },
    ],
    details: {
      description:
        'Philipsburg Noche ofrece otra oportunidad diaria para ganar, con reglas similares al sorteo diurno pero en horario nocturno.',
      jackpotInfo:
        'Los premios dependen del número de aciertos y el monto apostado.',
      drawInfo:
        'El sorteo se realiza diariamente a las 7:30 PM hora local.',
      multiplierInfo: 'No hay multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona uno o más números del 01 al 36.',
      'Decide el tipo de apuesta y el monto.',
      'Espera al sorteo para ver si tus números son seleccionados.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro del período establecido por la lotería.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos locales.',
    officialWebsite: 'N/A',
    additionalInfo: {
      responsibleGaming: 'Participa de manera responsable y establece límites.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Loto Pool Día',
    slug: 'loto-pool-dia',
    fetchFunction: getLotoPoolMedioDia, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '12:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Premio acumulado',
    ticketPrice: '$1 por jugada',
    odds: {
      jackpot: 'Varía según acumulado',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 1, max: 20 },
    },
    prizeCategories: [
      { match: '4 Números', prize: 'Jackpot' },
      { match: '3 Números', prize: 'Premio secundario' },
      { match: '2 Números', prize: 'Premio menor' },
    ],
    details: {
      description:
        'Loto Pool Día es un juego donde los jugadores seleccionan cuatro números y pueden ganar el jackpot al acertar todos los números.',
      jackpotInfo:
        'El premio mayor es un acumulado que aumenta si no hay ganadores.',
      drawInfo:
        'El sorteo se realiza diariamente a las 12:30 PM hora local.',
      multiplierInfo: 'No hay multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona cuatro números del 01 al 20.',
      'Compra tu boleto por $1.',
      'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro del período establecido por la lotería.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos locales.',
    officialWebsite: 'N/A',
    additionalInfo: {
      responsibleGaming: 'Juega de manera responsable.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
  {
    name: 'Loto Pool Noche',
    slug: 'loto-pool-noche',
    fetchFunction: getLotoPoolNoche, // Asegúrate de tener esta función definida
    country: 'San Martín',
    drawTimes: [
      {
        time: '19:30',
        days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'],
      },
    ],
    hasMultiplier: false,
    minimumJackpot: 'Premio acumulado',
    ticketPrice: '$1 por jugada',
    odds: {
      jackpot: 'Varía según acumulado',
      anyPrize: 'Varía',
    },
    numberRange: {
      main: { min: 1, max: 20 },
    },
    prizeCategories: [
      { match: '4 Números', prize: 'Jackpot' },
      { match: '3 Números', prize: 'Premio secundario' },
      { match: '2 Números', prize: 'Premio menor' },
    ],
    details: {
      description:
        'Loto Pool Noche es el sorteo nocturno del juego Loto Pool, con las mismas reglas y premios que el sorteo diurno.',
      jackpotInfo:
        'El premio mayor es un acumulado que aumenta si no hay ganadores.',
      drawInfo:
        'El sorteo se realiza diariamente a las 7:30 PM hora local.',
      multiplierInfo: 'No hay multiplicador en este juego.',
    },
    howToPlay: [
      'Selecciona cuatro números del 01 al 20.',
      'Compra tu boleto por $1.',
      'Espera al sorteo para ver si tus números coinciden con los ganadores.',
    ],
    multiplierRules: null,
    claimPeriod: 'Los premios deben reclamarse dentro del período establecido por la lotería.',
    taxInfo: 'Los premios pueden estar sujetos a impuestos locales.',
    officialWebsite: 'N/A',
    additionalInfo: {
      responsibleGaming: 'Juega de manera responsable.',
      ageRequirement: 'Debes tener al menos 18 años para jugar.',
    },
  },
];