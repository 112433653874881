export const howToPlay = {
  'loteria-nacional': {
    slug: 'loteria-nacional',
    name: 'Lotería Nacional',
    description: 'La Lotería Nacional es un juego donde los jugadores eligen números del 1 al 100. Los ganadores son aquellos cuyos números coinciden con los números sorteados.',
    instructions: [
      'Selecciona 5 números del 1 al 100.',
      'Los sorteos se realizan cada día a las 8:50 PM de lunes a sábado, y a las 6:00 PM los domingos.',
      'Si tus números coinciden con los sorteados, ganas.',
    ],
    prizeDetails: {
      jackpot: 'Premio mayor garantizado de 20 millones de pesos.',
      probabilities: [
        { category: '5 Números', odds: '1 entre 1,000,000', percentage: '0.0001%' },
        { category: '4 Números', odds: '1 entre 10,000', percentage: '0.01%' },
        { category: '3 Números', odds: '1 entre 500', percentage: '0.2%' },
      ],
      prizes: [
        { category: '5 Números', basePrize: '20,000,000 $', powerPlay: '40,000,000 $' },
        { category: '4 Números', basePrize: '50,000 $', powerPlay: '100,000 $' },
        { category: '3 Números', basePrize: '1,000 $', powerPlay: '2,000 $' },
      ],
    },
      
  },
  'gana-mas': {
    slug: 'gana-mas',
    name: 'Gana Más',
    description: 'Gana Más es un juego de azar donde los jugadores seleccionan un número del 1 al 30. Los premios se otorgan a aquellos que coincidan con los números ganadores.',
    instructions: [
      'Selecciona un número del 1 al 30.',
      'Los sorteos se realizan todos los días a las 2:30 PM.',
      'Ganas si tu número coincide con el número sorteado.',
    ],
    prizeDetails: {
      jackpot: 'Premio mayor de 1,000,000 pesos.',
      probabilities: [
        { category: 'Número Ganador', odds: '1 entre 30', percentage: '3.33%' },
      ],
      prizes: [
        { category: 'Número Ganador', basePrize: '1,000,000 $', powerPlay: '2,000,000 $' },
      ],
    },
    drawTimes: [
      { time: '14:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'] },
    ],
    siteUrl: 'https://www.loteria.gob.do/',
  },
  'super-pale': {
    slug: 'super-pale',
    name: 'Super Pale',
    description: 'Super Pale es un juego de lotería en República Dominicana donde los jugadores seleccionan dos números del 0 al 99.',
    instructions: [
      'Selecciona 2 números del 0 al 99.',
      'Los sorteos se realizan diariamente a las 12:55 PM y 8:55 PM.',
      'Ganas si ambos números coinciden con los números sorteados en el orden exacto.',
    ],
    prizeDetails: {
      jackpot: 'Premio mayor de 500,000 pesos.',
      probabilities: [
        { category: '2 Números en Orden', odds: '1 entre 9,901', percentage: '0.01%' },
        { category: '1 Número', odds: '1 entre 100', percentage: '1%' },
      ],
      prizes: [
        { category: '2 Números en Orden', basePrize: '500,000 $', powerPlay: '1,000,000 $' },
        { category: '1 Número', basePrize: '5,000 $', powerPlay: '10,000 $' },
      ],
    },
   
  },
  'powerball': {
slug: 'powerball',
name: 'Powerball',
description: 'Powerball es una lotería de Estados Unidos conocida en todo el mundo, disponible en 45 estados, el Distrito de Columbia, las Islas Vírgenes de los Estados Unidos y Puerto Rico.',
instructions: [
  'Selecciona 5 números del 1 al 69 y 1 número Powerball del 1 al 26.',
  'Los sorteos se celebran los lunes, miércoles y sábados a las 10:59 PM ET.',
  'Puedes activar la opción Power Play por $1 adicional para multiplicar tus premios (excepto el premio mayor).',
  'Desde agosto de 2021, puedes añadir Double Play a tu boleto por $1 adicional, participando en un sorteo adicional con un premio mayor de $10 millones.'
],
prizeDetails: {
  jackpot: 'Premio mínimo garantizado de $20 millones. El récord del premio mayor fue de $2.04 mil millones en noviembre de 2022.',
  probabilities: [
    { category: '5 + Powerball', odds: '1 entre 292,201,338', percentage: '0.0000003%' },
    { category: '5 Números', odds: '1 entre 11,688,054', percentage: '0.000008%' },
    { category: '4 + Powerball', odds: '1 entre 913,129', percentage: '0.0001%' },
    { category: '4 Números', odds: '1 entre 36,525', percentage: '0.002%' },
    { category: '3 + Powerball', odds: '1 entre 14,494', percentage: '0.006%' },
    { category: '3 Números', odds: '1 entre 580', percentage: '0.17%' },
    { category: '2 + Powerball', odds: '1 entre 701', percentage: '0.14%' },
    { category: '1 + Powerball', odds: '1 entre 92', percentage: '1.08%' },
    { category: 'Solo Powerball', odds: '1 entre 38', percentage: '2.63%' },
  ],
  prizes: [
    { category: '5 + Powerball', basePrize: 'Premio mayor', powerPlay: 'Premio mayor' },
    { category: '5 Números', basePrize: '$1,000,000', powerPlay: '$2,000,000' },
    { category: '4 + Powerball', basePrize: '$50,000', powerPlay: '$100,000 - $500,000' },
    { category: '4 Números', basePrize: '$100', powerPlay: '$200 - $1,000' },
    { category: '3 + Powerball', basePrize: '$100', powerPlay: '$200 - $1,000' },
    { category: '3 Números', basePrize: '$7', powerPlay: '$14 - $70' },
    { category: '2 + Powerball', basePrize: '$7', powerPlay: '$14 - $70' },
    { category: '1 + Powerball', basePrize: '$4', powerPlay: '$8 - $40' },
    { category: 'Solo Powerball', basePrize: '$4', powerPlay: '$8 - $40' },
  ]
},
drawTimes: [
  { time: '22:59', days: ['Miércoles y Sábado ', ] },
],
additionalInfo: {
  taxes: 'Los premios superiores a $600 deben declararse al IRS. Los premios superiores a $5,000 están sujetos a un 24% de retención federal. Las tasas estatales varían entre un 0% y un 8.82% dependiendo del estado.',
  ageRequirement: 'La mayoría de los estados requiere tener al menos 18 años para jugar. En Nebraska, la edad mínima es 19, y en Arizona, Iowa y Luisiana, es 21.',
  history: 'Powerball se lanzó en 1988 como Lotto America y fue rebautizado en 1992. El formato actual incluye un multiplicador x10 desde 2015.'
}
},
  'mega-millions': {
    slug: 'mega-millions',
    name: 'Mega Millions',
    description: 'Mega Millions es una lotería de fama mundial que se juega en 45 estados, el Distrito de Columbia y las Islas Vírgenes de Estados Unidos.',
    instructions: [
      'Selecciona 5 números del 1 al 70 y 1 número Mega Ball del 1 al 25.',
      'Los sorteos se celebran los martes y viernes a las 11:00 PM ET.',
      'Puedes activar el Megaplier por $1 adicional para multiplicar tus premios (excepto el premio mayor).'
    ],
    prizeDetails: {
      jackpot: 'Premio mínimo garantizado de $40 millones, temporalmente reducido a $20 millones en 2020 debido a la pandemia de COVID-19.',
      probabilities: [
        { category: '5 + Mega Ball', odds: '1 entre 302,575,350', percentage: '0.0000003%' },
        { category: '5 Números', odds: '1 entre 12,607,306', percentage: '0.000008%' },
        { category: '4 + Mega Ball', odds: '1 entre 931,001', percentage: '0.0001%' },
        { category: '4 Números', odds: '1 entre 38,792', percentage: '0.0025%' },
        { category: '3 + Mega Ball', odds: '1 entre 14,547', percentage: '0.007%' },
        { category: '3 Números', odds: '1 entre 606', percentage: '0.17%' },
        { category: '2 + Mega Ball', odds: '1 entre 693', percentage: '0.14%' },
        { category: '1 + Mega Ball', odds: '1 entre 89', percentage: '1.12%' },
        { category: 'Solo Mega Ball', odds: '1 entre 37', percentage: '2.7%' },
      ],
      prizes: [
        { category: '5 + Mega Ball', basePrize: 'Premio mayor', megaplier: 'Premio mayor' },
        { category: '5 Números', basePrize: '$1,000,000', megaplier: '$2,000,000 - $5,000,000' },
        { category: '4 + Mega Ball', basePrize: '$10,000', megaplier: '$20,000 - $50,000' },
        { category: '4 Números', basePrize: '$500', megaplier: '$1,000 - $2,500' },
        { category: '3 + Mega Ball', basePrize: '$200', megaplier: '$400 - $1,000' },
        { category: '3 Números', basePrize: '$10', megaplier: '$20 - $50' },
        { category: '2 + Mega Ball', basePrize: '$10', megaplier: '$20 - $50' },
        { category: '1 + Mega Ball', basePrize: '$4', megaplier: '$8 - $20' },
        { category: 'Solo Mega Ball', basePrize: '$2', megaplier: '$4 - $10' },
      ]
    },
    drawTimes: [
      { time: '22:59', days: ['Lunes, Miércoles y Sábado',] },
    ],
    
    additionalInfo: {
      taxes: 'Los premios superiores a $600 deben declararse al IRS. Los premios superiores a $5,000 están sujetos a un 24% de retención federal. Las tasas estatales varían entre un 0% y un 8.82% dependiendo del estado.',
      ageRequirement: 'La mayoría de los estados requiere tener al menos 18 años para jugar. En Nebraska, la edad mínima es 19, y en Arizona, Iowa y Luisiana, es 21.',
      history: 'Mega Millions se lanzó en 1996 como The Big Game y fue rebautizada en 2002 como Mega Millions. A pesar de ser una lotería multiestatal, la marca Mega Millions es propiedad de la Lotería de Illinois.'
    }
  },
  'cash4life': {
    slug: 'cash4life',
    name: 'Cash4Life',
    description: 'Cash4Life es una lotería multijurisdiccional de gran popularidad que se juega en nueve estados de EE. UU.: Nueva York, Nueva Jersey, Pensilvania, Virginia, Tennessee, Maryland, Georgia, Indiana y Florida.',
    instructions: [
      'Selecciona 5 números del 1 al 60 y 1 número Cash Ball del 1 al 4.',
      'Los sorteos se celebran diariamente a las 9:00 PM ET.',
      'Puedes activar la opción "Play It Again" para volver a jugar con una combinación de números elegidos en los 30 días previos.'
    ],
    prizeDetails: {
      jackpot: 'Premio mayor de $1,000 al día de por vida, y un segundo premio de $1,000 a la semana de por vida.',
      probabilities: [
        { category: '5 + Cash Ball', odds: '1 entre 21,846,048', percentage: '0.000005%' },
        { category: '5 Números', odds: '1 entre 7,282,016', percentage: '0.00001%' },
        { category: '4 + Cash Ball', odds: '1 entre 79,440', percentage: '0.001%' },
        { category: '4 Números', odds: '1 entre 26,480', percentage: '0.004%' },
        { category: '3 + Cash Ball', odds: '1 entre 1,471', percentage: '0.07%' },
        { category: '3 Números', odds: '1 entre 490',percentage: '0.2%' },
        { category: '2 + Cash Ball', odds: '1 entre 83', percentage: '1.2%' },
        { category: '2 Números', odds: '1 entre 28', percentage: '3.6%' },
        { category: '1 + Cash Ball', odds: '1 entre 13', percentage: '7.7%' }
      ],
      prizes: [
        { category: '5 + Cash Ball', basePrize: '$1,000 al día de por vida', lumpSum: '$7,000,000' },
        { category: '5 Números', basePrize: '$1,000 a la semana de por vida', lumpSum: '$1,000,000' },
        { category: '4 + Cash Ball', basePrize: '$2,500' },
        { category: '4 Números', basePrize: '$500' },
        { category: '3 + Cash Ball', basePrize: '$100' },
        { category: '3 Números', basePrize: '$25' },
        { category: '2 + Cash Ball', basePrize: '$10' },
        { category: '2 Números', basePrize: '$4' },
        { category: '1 + Cash Ball', basePrize: '$2' }
      ]
    },

    drawTimes: [
      { time: '21:00', days: ['Lunes, Martes, Miércoles, Jueves, Viernes, Sábado y Domingo', ] },
    ],
   
    additionalInfo: {
      taxes: 'Las ganancias superiores a $5,000 están sujetas a un 9% de impuestos estatales y un 24% de impuestos federales. Los residentes en la ciudad de Nueva York también deben pagar un 4% adicional en impuestos municipales.',
      ageRequirement: 'La edad mínima para jugar es de 18 años en todos los estados que participan en Cash4Life. Sin embargo, en algunos lugares como bares, la edad mínima puede ser de 21 años.',
      history: 'Cash4Life se lanzó el 16 de junio de 2014 en Nueva York y Nueva Jersey. Desde entonces, se han unido otros estados, como Pensilvania, Virginia, Tennessee, Maryland, Georgia, Indiana y Florida. Originalmente, los sorteos se realizaban dos veces por semana, pero ahora son diarios.'
    }
  },
  'new-york-tarde': {
slug: 'new-york-tarde',
name: 'New York Tarde',
description: 'New York Tarde es un sorteo americano que se vende en distintas bancas de loterías de la República Dominicana. Los sorteos se realizan en Estados Unidos y los pagos se efectúan en la República Dominicana, basados en la forma de pago de las loterías dominicanas.',
instructions: [
  'Puedes realizar jugadas de tres formas: Quiniela, Palé y Tripleta.',
  'El sorteo se realiza a las 2:30 pm hora de Nueva York, 2:30 o 3:30 hora dominicana, dependiendo de la temporada.',
  'Los resultados están disponibles al instante después del sorteo.',
],
prizeDetails: {
  jackpot: 'Premio mayor de 60 pesos por cada peso apostado.',
  probabilities: [
    { category: 'Premio mayor', odds: 'No especificado', percentage: 'No especificado' },
    { category: 'Segundo premio', odds: 'No especificado', percentage: 'No especificado' },
    { category: 'Tercer premio', odds: 'No especificado', percentage: 'No especificado' },
  ],
  prizes: [
    { category: 'Premio mayor', basePrize: '60 pesos por cada peso apostado' },
    { category: 'Segundo premio', basePrize: '8 pesos por cada peso apostado' },
    { category: 'Tercer premio', basePrize: '4 pesos por cada peso apostado' },
    { category: 'Primera y segunda', basePrize: 'RD$1,000 pesos por cada peso apostado' },
    { category: 'Primera y tercera', basePrize: 'RD$1,000 pesos por cada peso apostado' },
    { category: 'Segunda y tercera', basePrize: 'RD$100 pesos por cada peso apostado' },
    { category: 'Tripleta (tres decenas)', basePrize: 'RD$20,000 pesos por cada peso apostado' },
    { category: 'Tripleta (dos decenas)', basePrize: 'RD$100 pesos por cada peso apostado' },
  ]
},
drawTimes: [
  { time: '14:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'] },
],
additionalInfo: {
  paymentMethods: 'Los pagos se realizan en la República Dominicana, basados en la forma de pago de las loterías dominicanas.',
  gameTypes: 'New York Tarde permite a cada jugador realizar sus jugadas de tres formas: Quiniela, Palé y Tripleta.',
}
},
'new-york-noche': {
slug: 'new-york-noche',
name: 'New York Noche',
description: 'New York Noche es un sorteo americano que se vende en distintas bancas de loterías de la República Dominicana. Los sorteos se realizan en Estados Unidos y los pagos se efectúan en la República Dominicana, basados en la forma de pago de las loterías dominicanas.',
instructions: [
  'Puedes realizar jugadas de tres formas: Quiniela, Palé y Tripleta.',
  'El sorteo se realiza todos los días a las 10:30 pm hora de Nueva York, 10:30 o 11:30 pm hora dominicana, dependiendo de la temporada.',
  'Los resultados están disponibles al instante después del sorteo.',
],
prizeDetails: {
  jackpot: 'Premio mayor de 60 pesos por cada peso apostado.',
  probabilities: [
    { category: 'Premio mayor', odds: 'No especificado', percentage: 'No especificado' },
    { category: 'Segundo premio', odds: 'No especificado', percentage: 'No especificado' },
    { category: 'Tercer premio', odds: 'No especificado', percentage: 'No especificado' },
  ],
  prizes: [
    { category: 'Premio mayor', basePrize: '60 pesos por cada peso apostado' },
    { category: 'Segundo premio', basePrize: '8 pesos por cada peso apostado' },
    { category: 'Tercer premio', basePrize: '4 pesos por cada peso apostado' },
    { category: 'Primera y segunda', basePrize: 'RD$1,000 pesos por cada peso apostado' },
    { category: 'Primera y tercera', basePrize: 'RD$1,000 pesos por cada peso apostado' },
    { category: 'Segunda y tercera', basePrize: 'RD$100 pesos por cada peso apostado' },
    { category: 'Tripleta (tres decenas)', basePrize: 'RD$20,000 pesos por cada peso apostado' },
    { category: 'Tripleta (dos decenas)', basePrize: 'RD$100 pesos por cada peso apostado' },
  ]
},
drawTimes: [
  { time: '22:30', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'] },
],
additionalInfo: {
  paymentMethods: 'Los pagos se realizan en la República Dominicana, basados en la forma de pago de las loterías dominicanas.',
  gameTypes: 'New York Noche permite a cada jugador realizar sus jugadas de tres formas: Quiniela, Palé y Tripleta.',
}
},
};