export const numberInterpretations = {
    '00': { dream: 'Huevos', interpretation: 'Simboliza nuevos comienzos, fertilidad y potencial. Puede representar vulnerabilidad o algo en desarrollo.' },
    '01': { dream: 'Agua', interpretation: 'Representa las emociones, la purificación, y el flujo de la vida. Puede reflejar la claridad o turbulencias emocionales.' },
    '02': { dream: 'Niños', interpretation: 'Indica inocencia, nuevos comienzos, o preocupaciones familiares. También puede simbolizar aspectos juveniles o la vulnerabilidad.' },
    '03': { dream: 'Santos', interpretation: 'Refleja la conexión espiritual, protección divina o la necesidad de orientación y guía en la vida.' },
    '04': { dream: 'Cama', interpretation: 'Representa descanso, intimidad, o la necesidad de seguridad y confort. Puede sugerir también problemas de salud o recuperación.' },
    '05': { dream: 'Gato', interpretation: 'Simboliza la independencia, la intuición, y el misterio. También puede reflejar engaños o la necesidad de protegerse.' },
    '06': { dream: 'Perro', interpretation: 'Representa la lealtad, la amistad y la protección. También puede reflejar sentimientos de seguridad o advertencias de traición.' },
    '07': { dream: 'Revolver', interpretation: 'Indica conflictos internos, peligro inminente, o la necesidad de defensa. Puede reflejar agresión reprimida.' },
    '08': { dream: 'Incendio', interpretation: 'Representa la pasión, la destrucción o la purificación. Puede indicar cambios radicales o emociones intensas.' },
    '09': { dream: 'Arroyo', interpretation: 'Simboliza el flujo de la vida, las emociones y la transición. Puede representar la calma o la agitación emocional.' },
    '10': { dream: 'Leche', interpretation: 'Indica nutrición, maternidad, o la necesidad de cuidado. Puede reflejar pureza o preocupaciones por la salud.' },
    '11': { dream: 'Minero', interpretation: 'Representa el trabajo duro, la búsqueda de algo valioso o la introspección. Puede indicar la necesidad de explorar más profundamente.' },
    '12': { dream: 'Soldado', interpretation: 'Simboliza la disciplina, el sacrificio y la lucha. Puede reflejar conflictos personales o la necesidad de defender tus principios.' },
    '13': { dream: 'Mala suerte', interpretation: 'Indica advertencias sobre decisiones, situaciones adversas o miedos profundos. Puede simbolizar supersticiones.' },
    '14': { dream: 'Borracho', interpretation: 'Refleja la pérdida de control, comportamientos autodestructivos o una advertencia sobre excesos.' },
    '15': { dream: 'Mujer bonita', interpretation: 'Simboliza el deseo, la tentación o los ideales de belleza. Puede reflejar inseguridades o aspiraciones románticas.' },
    '16': { dream: 'Anillo', interpretation: 'Representa compromiso, unión o promesas. Puede simbolizar relaciones importantes o la necesidad de fidelidad.' },
    '17': { dream: 'Desgracia', interpretation: 'Refleja miedos o preocupaciones sobre pérdidas o tragedias. Puede ser una advertencia sobre decisiones importantes.' },
    '18': { dream: 'Sangre', interpretation: 'Simboliza la vida, energía o sacrificio. También puede indicar la pérdida de algo valioso o la necesidad de resolver conflictos.' },
    '19': { dream: 'Pescado', interpretation: 'Representa abundancia, prosperidad o secretos ocultos. Puede indicar situaciones fluidas o cambios en la vida.' },
    '20': { dream: 'Fiesta', interpretation: 'Indica felicidad, celebración o la necesidad de socializar. Puede reflejar deseos de escapismo o de alegría en la vida.' },
    '21': { dream: 'Mujer', interpretation: 'Refleja aspectos de la feminidad o relaciones con mujeres en la vida real. Puede simbolizar intuición, emoción o sensibilidad.' },
    '22': { dream: 'Loco', interpretation: 'Simboliza comportamientos irracionales, pérdida de control mental o la liberación de normas sociales.' },
    '23': { dream: 'Cocinero', interpretation: 'Representa la creatividad, el cuidado o la necesidad de nutrir a otros. Puede indicar el desarrollo de nuevas ideas o proyectos.' },
    '24': { dream: 'Caballo', interpretation: 'Simboliza libertad, fuerza y pasión. Puede reflejar el deseo de independencia o la necesidad de superar obstáculos.' },
    '25': { dream: 'Serpiente', interpretation: 'Representa transformación, renacimiento, tentación o peligro oculto. Puede simbolizar miedos profundos o la necesidad de enfrentar desafíos.' },
    '26': { dream: 'Gallina', interpretation: 'Puede representar chismes, temores o fertilidad. También puede simbolizar la cobardía o la necesidad de actuar.' },
    '27': { dream: 'Peine', interpretation: 'Simboliza la organización, la preparación o el cuidado personal. Puede reflejar preocupaciones por la apariencia o la necesidad de ordenar la vida.' },
    '28': { dream: 'Cerro', interpretation: 'Representa desafíos, aspiraciones o barreras que deben superarse. Puede reflejar metas personales o la necesidad de alcanzar nuevos niveles.' },
    '29': { dream: 'San Pedro', interpretation: 'Conexión espiritual, protección o la búsqueda de orientación. Puede reflejar la necesidad de fe o apoyo divino.' },
    '30': { dream: 'Santa Rosa', interpretation: 'Simboliza la protección divina, la fe o la esperanza en tiempos difíciles. Puede indicar la necesidad de ayuda espiritual.' },
    '31': { dream: 'Luz', interpretation: 'Indica claridad, revelaciones o la búsqueda de verdad. Puede simbolizar el conocimiento, la iluminación o la esperanza.' },
    '32': { dream: 'Dinero', interpretation: 'Representa poder, seguridad o preocupaciones económicas. Puede reflejar la necesidad de estabilidad financiera o deseos de éxito material.' },
    '33': { dream: 'Cristo', interpretation: 'Conexión profunda con la espiritualidad, sacrificio o necesidad de guía. Puede indicar la búsqueda de redención o paz interior.' },
    '34': { dream: 'Cabeza', interpretation: 'Puede representar el intelecto, la identidad o preocupaciones sobre uno mismo. Puede indicar la necesidad de reflexión o autoconocimiento.' },
    '35': { dream: 'Pájaro', interpretation: 'Simboliza libertad, aspiraciones o mensajes del subconsciente. Puede indicar el deseo de escapar o la búsqueda de nuevas oportunidades.' },
    '36': { dream: 'Manteca', interpretation: 'Refleja suavidad, confort o nutrición emocional. Puede simbolizar la necesidad de cuidar o ser cuidado.' },
    '37': { dream: 'Dentista', interpretation: 'Indica preocupación por la salud o el deseo de corregir algo en la vida. Puede reflejar miedos o la necesidad de sanar.' },
    '38': { dream: 'Piedras', interpretation: 'Simboliza estabilidad, obstáculos o resistencia. Puede indicar la necesidad de ser fuerte o la presencia de barreras en la vida.' },
    '39': { dream: 'Lluvia', interpretation: 'Representa limpieza emocional, tristeza o fertilidad. Puede reflejar la liberación de tensiones o la llegada de nuevas oportunidades.' },
    '40': { dream: 'Cura', interpretation: 'Refleja moralidad, espiritualidad o la necesidad de consejo. Puede simbolizar la búsqueda de guía o protección divina.' },
    '41': { dream: 'Cuchillo', interpretation: 'Simboliza conflictos, separación o peligro. Puede reflejar la necesidad de cortar lazos o resolver conflictos.' },
    '42': { dream: 'Silla', interpretation: 'Indica descanso, necesidad de estabilidad o situaciones pasivas. Puede reflejar la necesidad de apoyo o reflexión.' },
    '43': { dream: 'Balcón', interpretation: 'Refleja una perspectiva elevada, nuevas oportunidades o exposición. Puede simbolizar la necesidad de ver las cosas desde una nueva perspectiva.' },
    '44': { dream: 'Cárcel', interpretation: 'Simboliza sentimientos de confinamiento, limitaciones o culpa. Puede indicar la necesidad de liberarse de restricciones o miedos.' },
    '45': { dream: 'Vino', interpretation: 'Representa celebración, placer o deseos de relajarse. Puede reflejar la indulgencia o la necesidad de disfrutar la vida.' },
    '46': { dream: 'Tomates', interpretation: 'Simboliza salud, pasión o fertilidad. Puede reflejar el deseo de vitalidad o la necesidad de nutrirse.' },
    '47': { dream: 'Muerto', interpretation: 'Puede reflejar el cierre de un ciclo, recuerdos o transición. Puede simbolizar la necesidad de dejar atrás el pasado o enfrentarse a la mortalidad.' },
    '48': { dream: 'Muerto que habla', interpretation: 'Indica la necesidad de resolver asuntos pendientes o recibir un mensaje importante. Puede simbolizar la necesidad de cerrar un ciclo o recibir consejo.' },
    '49': { dream: 'Carne', interpretation: 'Simboliza la nutrición, el deseo físico o la necesidad de sustento. Puede reflejar preocupaciones por la salud o la satisfacción de necesidades básicas.' },
    '50': { dream: 'Pan', interpretation: 'Representa la abundancia, la necesidad de sustento o la hospitalidad. Puede simbolizar el sustento básico o la gratitud.' },
    '51': { dream: 'Serrucho', interpretation: 'Simboliza trabajo duro, la división de algo o la necesidad de hacer cambios. Puede reflejar la separación o la preparación para algo nuevo.' },
    '52': { dream: 'Madre', interpretation: 'Representa la figura maternal, el cuidado, o preocupaciones familiares. Puede simbolizar el amor incondicional o la necesidad de protección.' },
    '53': { dream: 'Barco', interpretation: 'Simboliza viajes, nuevas oportunidades o el deseo de escapar. Puede reflejar la transición entre diferentes etapas de la vida.' },
    '54': { dream: 'Vaca', interpretation: 'Representa fertilidad, prosperidad o sacrificio. Puede simbolizar la nutrición y la abundancia.' },
    '55': { dream: 'Personas extranjeras', interpretation: 'Puede simbolizar algo nuevo, desconocido o distante. Puede indicar la llegada de cambios o la interacción con lo diferente.' },
    '56': { dream: 'Caída', interpretation: 'Refleja inseguridad, miedo al fracaso o pérdida de control. Puede simbolizar la vulnerabilidad o la necesidad de aferrarse a algo firme.' },
    '57': { dream: 'Jorobado', interpretation: 'Simboliza cargas emocionales, secretos o vulnerabilidad. Puede reflejar la presión o la necesidad de liberar algo que te está afectando.' },
    '58': { dream: 'Ahogado', interpretation: 'Indica sentirse abrumado, atrapado o emociones reprimidas. Puede reflejar la sensación de estar sumergido en problemas o preocupaciones.' },
    '59': { dream: 'Plantas', interpretation: 'Representa crecimiento, desarrollo personal o conexión con la naturaleza. Puede simbolizar la esperanza, el renacimiento o la paciencia.' },
    '60': { dream: 'Virgen', interpretation: 'Conexión espiritual, pureza o protección divina. Puede simbolizar la inocencia, la fe o la necesidad de orientación espiritual.' },
    '61': { dream: 'Escopeta', interpretation: 'Simboliza conflictos, necesidad de defensa o peligro inminente. Puede reflejar la agresión o la necesidad de protegerse a uno mismo.' },
    '62': { dream: 'Inundación', interpretation: 'Refleja emociones desbordadas, conflictos o situaciones abrumadoras. Puede simbolizar la pérdida de control o la necesidad de enfrentarse a sentimientos reprimidos.' },
    '63': { dream: 'Casamiento', interpretation: 'Indica unión, compromiso o nuevas etapas en la vida. Puede simbolizar la armonía, la estabilidad o el deseo de formar una familia.' },
    '64': { dream: 'Llanto', interpretation: 'Refleja tristeza, alivio emocional o la liberación de tensiones. Puede simbolizar la purificación o el reconocimiento de sentimientos ocultos.' },
    '65': { dream: 'Cazador', interpretation: 'Simboliza la búsqueda de algo importante, metas u oportunidades. Puede reflejar el deseo de alcanzar un objetivo o la necesidad de ser perseverante.' },
    '66': { dream: 'Lombrices', interpretation: 'Indica preocupaciones menores, la presencia de algo subterráneo o la renovación. Puede simbolizar la transformación o la inquietud.' },
    '67': { dream: 'Mordedura', interpretation: 'Simboliza agresión, traición o advertencia de peligro. Puede reflejar una situación de riesgo o la necesidad de protegerse.' },
    '68': { dream: 'Sobrinos', interpretation: 'Refleja preocupaciones familiares, nuevas responsabilidades o lazos familiares. Puede simbolizar la continuidad o la conexión con las generaciones futuras.' },
    '69': { dream: 'Vicios', interpretation: 'Representa tentaciones, malos hábitos o advertencias sobre comportamientos autodestructivos. Puede reflejar la lucha interna entre el deber y el deseo.' },
    '70': { dream: 'Loco', interpretation: 'Indica confusión, miedo a perder el control o situaciones inesperadas. Puede simbolizar la liberación de las normas sociales o la necesidad de confrontar el caos interno.' },
    '71': { dream: 'Excremento', interpretation: 'Simboliza ganancias materiales, prosperidad o algo desagradable que necesitas enfrentar. Puede reflejar la purificación o la eliminación de lo innecesario.' },
    '72': { dream: 'Sorpresa', interpretation: 'Refleja lo inesperado, noticias o cambios repentinos. Puede simbolizar la necesidad de adaptarse rápidamente o la llegada de nuevas oportunidades.' },
    '73': { dream: 'Hospital', interpretation: 'Indica necesidad de curación, preocupación por la salud o situaciones que requieren atención. Puede simbolizar el deseo de sanar o la preocupación por el bienestar.' },
    '74': { dream: 'Persona de piel oscura', interpretation: 'Puede simbolizar aspectos ocultos, desconocidos o una conexión cultural. Puede reflejar miedos, prejuicios o la necesidad de explorar lo desconocido.' },
    '75': { dream: 'Besos', interpretation: 'Refleja afecto, amor, reconciliación o deseo de intimidad. Puede simbolizar la armonía en las relaciones o el deseo de conexión emocional.' },
    '76': { dream: 'Llamas', interpretation: 'Simboliza pasión, purificación o peligros inminentes. Puede reflejar la necesidad de cambios o el reconocimiento de sentimientos intensos.' },
    '77': { dream: 'Piernas', interpretation: 'Indica movimiento, independencia o la necesidad de avanzar. Puede simbolizar el progreso, la estabilidad o el deseo de superar obstáculos.' },
    '78': { dream: 'Prostíbulo', interpretation: 'Refleja deseos reprimidos, cuestiones de moralidad o la búsqueda de placer. Puede simbolizar la tentación o el conflicto interno sobre valores personales.' },
    '79': { dream: 'Ladrón', interpretation: 'Indica miedo a perder algo valioso, traición o desconfianza. Puede simbolizar la necesidad de proteger tus pertenencias o la inseguridad sobre las relaciones.' },
    '80': { dream: 'Bola', interpretation: 'Representa competencia, juegos o situaciones circulares en tu vida. Puede simbolizar la necesidad de enfoque o la repetición de patrones en la vida.' },
    '81': { dream: 'Flores', interpretation: 'Simboliza belleza, crecimiento, amor o la brevedad de la vida. Puede reflejar la apreciación por la naturaleza o la expresión de sentimientos.' },
    '82': { dream: 'Pelea', interpretation: 'Refleja conflictos internos, tensiones con otros o la necesidad de resolver disputas. Puede simbolizar la lucha por el poder o la afirmación personal.' },
    '83': { dream: 'Mal tiempo', interpretation: 'Indica conflictos emocionales, turbulencias en la vida o cambios inminentes. Puede simbolizar la agitación interna o la necesidad de enfrentar desafíos.' },
    '84': { dream: 'Iglesia', interpretation: 'Refleja la espiritualidad, la necesidad de paz interior o la búsqueda de consuelo. Puede simbolizar la necesidad de fe o el refugio en momentos de incertidumbre.' },
    '85': { dream: 'Linterna', interpretation: 'Simboliza guía, revelación o la necesidad de claridad en una situación. Puede reflejar la búsqueda de la verdad o la necesidad de iluminar áreas oscuras de la vida.' },
    '86': { dream: 'Humo', interpretation: 'Indica confusión, incertidumbre o la necesidad de aclarar una situación. Puede simbolizar la ambigüedad o la presencia de algo oculto.' },
    '87': { dream: 'Piojos', interpretation: 'Simboliza preocupaciones menores, problemas persistentes o una situación incómoda. Puede reflejar la sensación de estar invadido o la necesidad de limpieza.' },
    '88': { dream: 'León', interpretation: 'Representa fuerza, poder, coraje o liderazgo. Puede simbolizar la autoridad o el deseo de dominar una situación.' },
    '89': { dream: 'Ratón', interpretation: 'Indica preocupaciones menores, miedos irracionales o la necesidad de atención a pequeños detalles. Puede simbolizar la timidez o la vulnerabilidad.' },
    '90': { dream: 'Miedo', interpretation: 'Refleja inseguridades, ansiedades o la necesidad de enfrentar tus temores. Puede simbolizar la parálisis emocional o la necesidad de superar barreras internas.' },
    '91': { dream: 'Excusado', interpretation: 'Simboliza la necesidad de purificación, liberar emociones o dejar ir lo innecesario. Puede reflejar la eliminación de lo tóxico o la preparación para algo nuevo.' },
    '92': { dream: 'Médico', interpretation: 'Indica preocupación por la salud, necesidad de curación o consejos importantes. Puede simbolizar la necesidad de atención o el deseo de sanar.' },
    '93': { dream: 'Enamorado', interpretation: 'Refleja deseos de afecto, conexión emocional o satisfacción personal. Puede simbolizar la armonía en las relaciones o el deseo de amor verdadero.' },
    '94': { dream: 'Cementerio', interpretation: 'Representa el cierre de ciclos, recordar el pasado o la transición hacia algo nuevo. Puede simbolizar la necesidad de dejar ir o el respeto por lo que ya ha pasado.' },
    '95': { dream: 'Ancla', interpretation: 'Simboliza estabilidad, seguridad o la necesidad de permanecer firme en una situación. Puede reflejar la necesidad de aferrarse a algo estable en momentos de incertidumbre.' },
    '96': { dream: 'Mariposas', interpretation: 'Refleja transformación, belleza o la temporalidad de las cosas. Puede simbolizar el cambio, la evolución personal o la fragilidad de la vida.' },
    '97': { dream: 'Mesa', interpretation: 'Indica reuniones, compartir o la base para futuras decisiones. Puede simbolizar la cooperación, la discusión o la planificación.' },
    '98': { dream: 'Lavandera', interpretation: 'Simboliza purificación, preparación o el deseo de deshacerse de cargas emocionales. Puede reflejar la necesidad de renovación o de comenzar de nuevo.' },
    '99': { dream: 'Hermano', interpretation: 'Refleja lazos familiares, apoyo o conflictos fraternos. Puede simbolizar la unión, la rivalidad o la necesidad de reconciliación.' },
  };
  