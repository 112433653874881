import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Alert, 
  Grid, 
  Box, 
  Skeleton, 
  useTheme, 
  Divider, 
  Container,
  Fade,
  Tooltip,
  Card,
  CardContent,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  getReal, 
  getPega4Real, 
  getLotoPoolReal, 
  getNYReal, 
  getLotoReal, 
  getSuperPaleReal, 
  getQuinielita 
} from '../services/api';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AppleIcon from '@mui/icons-material/Apple';
import LaunchIcon from '@mui/icons-material/Launch';
import TimerProgressBar from '../components/TimerProgressBar';
import JackpotDisplay from '../components/JackpotDisplay';
import LotteryLogo from '../components/LotteryLogo';
import CountryInfo from '../components/CountryInfo';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
}));

const NumberBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const LotteryNumber = styled(Box)(({ theme, istufechareal }) => ({
  width: istufechareal === 'true' ? 40 : 50,
  height: istufechareal === 'true' ? 40 : 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  fontSize: istufechareal === 'true' ? 16 : 20,
}));

const DateDisplay = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}));

const SkeletonCard = () => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Skeleton variant="rectangular" height={32} width={32} sx={{ borderRadius: '50%', mb: 1 }} />
      <Skeleton variant="text" width="60%" sx={{ mb: 1 }} />
      <Skeleton variant="rectangular" height={60} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="40%" sx={{ mb: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 1 }}>
        {[...Array(5)].map((_, i) => (
          <Skeleton key={i} variant="circular" width={50} height={50} />
        ))}
      </Box>
      <Skeleton variant="rectangular" height={40} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="80%" />
    </CardContent>
  </Card>
);

const lotteries = [
  { 
    name: 'Lotería Real', 
    fetchFunction: getReal, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '12:55', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'] },
    ],
    hasJackpot: false,
  },
  { 
    name: 'Pega 4 Real', 
    fetchFunction: getPega4Real, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '12:55', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'] },
    ],
    hasJackpot: false,
  },
  { 
    name: 'Loto Pool Real', 
    fetchFunction: getLotoPoolReal, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '12:55', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'] },
    ],
    hasJackpot: true,
  },
  { 
    name: 'Nueva York Real', 
    fetchFunction: getNYReal, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '12:55', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'] },
    ],
    hasJackpot: false,
  },
  { 
    name: 'Loto Real', 
    fetchFunction: getLotoReal, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '12:50', days: ['martes', 'viernes'] },
    ],
    hasJackpot: true,
  },
  { 
    name: 'Super Pale Real', 
    fetchFunction: getSuperPaleReal, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '12:55', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'] },
    ],
    hasJackpot: false,
  },
  { 
    name: 'Tu Fecha Real', 
    fetchFunction: getQuinielita, 
    country: 'República Dominicana',
    drawTimes: [
      { time: '12:55', days: ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'] },
    ],
    hasJackpot: false,
  },
];

const LotteryCard = ({ result, formatDate }) => {
  const getColorFromContainer = (container) => {
    const colorMap = {
      'rojo': '#FF0000',
      'verde': '#008000',
      'amarillo': '#FFFF00',
      'azul': '#0000FF',
      'naranja': '#FFA500',
      'morado': '#800080',
      'rosa': '#FFC0CB',
      'marrón': '#A52A2A',
      'gris': '#808080',
      'negro': '#000000',
      'blanco': '#FFFFFF',
    };

    const lowerCaseContainer = container.toLowerCase();
    for (const [colorName, colorValue] of Object.entries(colorMap)) {
      if (lowerCaseContainer.includes(colorName)) {
        return colorValue;
      }
    }
    return '#FF0000'; // Default to red if no color is found
  };

  const renderLotteryNumbers = () => {
    if (result.name === 'Nueva York Real') {
      const containerColor = getColorFromContainer(result.numbers[3] || '');

      return (
        <NumberBox>
          {result.numbers.slice(0, 3).map((number, idx) => (
            <LotteryNumber key={idx}>
              {number}
            </LotteryNumber>
          ))}
          {result.numbers[3] && (
            <Tooltip title={`${result.numbers[3]}`}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 50, height: 50 }}>
                <AppleIcon sx={{ color: containerColor, fontSize: 40 }} />
              </Box>
            </Tooltip>
          )}
        </NumberBox>
      );
    } else {
      return (
        <NumberBox>
          {result.numbers && result.numbers.length > 0 ? (
            result.numbers.map((number, idx) => (
              <LotteryNumber key={idx} istufechareal={result.name === 'Tu Fecha Real' ? 'true' : 'false'}>
                {number}
              </LotteryNumber>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No hay resultados disponibles
            </Typography>
          )}
        </NumberBox>
      );
    }
  };

  return (
    <StyledCard>
      <CardHeader>
        <CountryInfo country={result.country} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
            {result.name}
          </Typography>
          {result.hasJackpot && (
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              <JackpotDisplay name={result.name} />
            </Typography>
          )}
        </Box>
      </CardHeader>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
        <LotteryLogo name={result.name} />
        
        <Divider sx={{ width: '100%', my: 2 }} />
        
        <Box>
          <Typography variant="subtitle2" fontWeight="bold" color="primary" gutterBottom>
            Último Sorteo
          </Typography>
        </Box>
        <DateDisplay>
          <CalendarTodayIcon sx={{ fontSize: 16, mr: 0.5 }} />
          <Typography variant="body2">{formatDate(result.date)}</Typography>
        </DateDisplay>
        
        {renderLotteryNumbers()}
        
        <Divider sx={{ width: '100%', my: 2 }} />
        
        <Box sx={{ width: '100%' }}>
          <TimerProgressBar 
            drawTimes={result.drawTimes} 
            name={result.name}
          />
        </Box>
      </CardContent>
    </StyledCard>
  );
};

const LoteriaReal = () => {
  const [lotteryResults, setLotteryResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const results = await Promise.all(
          lotteries.map(async (lottery) => {
            try {
              const data = await lottery.fetchFunction();
              let numbers = [];
              let date = 'No disponible';
              let containerName = '';

              if (Array.isArray(data)) {
                const latestResult = data[0];
                numbers = latestResult.numbers || [];
                date = latestResult.date || 'No disponible';
                containerName = latestResult.containerName || '';
              } else if (typeof data === 'object' && data !== null) {
                numbers = data.numbers || [];
                date = data.date || 'No disponible';
                containerName = data.containerName || '';
              }

              return {
                ...lottery,
                numbers: numbers,
                date: date,
                containerName: containerName,
              };
            } catch (error) {
              console.error(`Error al obtener ${lottery.name}:`, error);
              return { ...lottery, numbers: [], date: 'No disponible', containerName: '' };
            }
          })
        );

        setLotteryResults(results);
      } catch (error) {
        setError('Error al obtener los resultados de la lotería. Inténtalo más tarde.');
        console.error('Error al obtener los resultados de la lotería:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString || dateString === 'No disponible') return 'Fecha no disponible';
    try {
      const [day, month, year] = dateString.split('-');
      const date = new Date(year, month - 1, day);
      const formattedDate = date.toLocaleDateString('es-ES', { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      });
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    } catch (error) {
      console.error('Error al formatear la fecha:', error);
      return 'Fecha inválida';
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography 
        variant="h2" 
        component="h1"
        gutterBottom 
        align="center" 
        sx={{ 
          fontWeight: 700, 
          color: 'rgb(58, 71, 84)', 
          mb: 4,
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
        }}
      >
        Resultados de Lotería Real
      </Typography>

      <Grid container spacing={3}>
        {loading
          ? [...Array(7)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <SkeletonCard />
              </Grid>
            ))
          : error
          ? (
              <Grid item xs={12}>
                <Alert 
                  severity="error" 
                  sx={{ 
                    mt: 2, 
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[1],
                  }}
                >
                  {error}
                </Alert>
              </Grid>
            )
          : lotteryResults.map((result, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Fade in={true} style={{ transitionDelay: `${index * 100}ms` }}>
                  <Box>
                    <LotteryCard result={result} formatDate={formatDate} />
                  </Box>
                </Fade>
              </Grid>
            ))
        }
      </Grid>

      <Box mt={6} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          endIcon={<LaunchIcon />}
          href="https://loteria-real.com/"
          target="_blank"
          rel="noopener noreferrer"
          size="large"
        >
          Visitar sitio oficial de Lotería Real
        </Button>
      </Box>

    </Container>
  );
};

export default LoteriaReal;