import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Typography,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  Link,
  Paper,
  Button,
  Alert,
  AlertTitle,
  Pagination,
  Skeleton,
  Fade,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import {
  AccessTime,
  Info as InfoIcon,
  HelpOutline,
  MonetizationOn,
  CalendarToday,
  Casino,
  EmojiEvents,
  Language,
  ShowChart,
  TrendingUp,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
} from '@mui/icons-material';

import ChartComponent from './ChartComponent';
import JackpotTrendChart from './JackpotTrendChart';

const COLORS = ['#4a90e2', '#63b3ed', '#4299e1', '#3182ce', '#2b6cb0', '#2c5282', '#2a4365', '#1a365d', '#00C49F', '#FFBB28'];

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const LotteryNumber = styled(Chip)(({ theme }) => ({
  margin: '2px',
  fontWeight: 'bold',
  background: 'linear-gradient(45deg, #4a90e2 30%, #63b3ed 90%)',
  color: 'white',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.15)',
  },
}));

const SpecialNumber = styled(Chip)(({ theme }) => ({
  margin: '2px',
  fontWeight: 'bold',
  background: 'linear-gradient(45deg, #f6ad55 30%, #ed8936 90%)',
  color: 'white',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.15)',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 15px 25px rgba(0,0,0,0.3)',
  },
}));

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 400,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    borderRadius: '4px',
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: '0 6px 10px rgba(0,0,0,0.2)',
  },
}));

const PulseChip = styled(Chip)(({ theme }) => ({
  animation: `${pulseAnimation} 2s infinite`,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiTab-root': {
    minWidth: 'auto',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      minWidth: 100,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 120,
    },
  },
}));

const GeneralInfo = React.memo(({ lotteryInfo }) => (
  <StyledCard>
    <StyledCardContent>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h6" ml={2}>
          Información General
        </Typography>
      </Box>
      <StyledTableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell><strong>Nombre</strong></TableCell>
              <TableCell>{lotteryInfo.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>País</strong></TableCell>
              <TableCell>{lotteryInfo.country}</TableCell>
            </TableRow>
            {lotteryInfo.minimumJackpot && (
              <TableRow>
                <TableCell><strong>Jackpot Mínimo</strong></TableCell>
                <TableCell>
                  <MonetizationOn sx={{ verticalAlign: 'middle', mr: 1, color: 'success.main' }} />
                  ${parseInt(lotteryInfo.minimumJackpot).toLocaleString()}
                </TableCell>
              </TableRow>
            )}
            {lotteryInfo.ticketPrice && (
              <TableRow>
                <TableCell><strong>Precio del Boleto</strong></TableCell>
                <TableCell>
                  <MonetizationOn sx={{ verticalAlign: 'middle', mr: 1, color: 'primary.main' }} />
                  ${lotteryInfo.ticketPrice}
                </TableCell>
              </TableRow>
            )}
            {lotteryInfo.numberRange && (
              <TableRow>
                <TableCell><strong>Rango de Números</strong></TableCell>
                <TableCell>
                  <Casino sx={{ verticalAlign: 'middle', mr: 1 }} />
                  {lotteryInfo.numberRange.main && (
                    <>
                      Principal: {lotteryInfo.numberRange.main.min} - {lotteryInfo.numberRange.main.max}<br />
                    </>
                  )}
                  {lotteryInfo.numberRange.day && (
                    <>
                      Día: {lotteryInfo.numberRange.day.min} - {lotteryInfo.numberRange.day.max}<br />
                    </>
                  )}
                  {lotteryInfo.numberRange.month && (
                    <>
                      Mes: {lotteryInfo.numberRange.month.min} - {lotteryInfo.numberRange.month.max}<br />
                    </>
                  )}
                  {lotteryInfo.numberRange.powerball && (
                    <>
                      Powerball: {lotteryInfo.numberRange.powerball.min} - {lotteryInfo.numberRange.powerball.max}<br />
                    </>
                  )}
                  {lotteryInfo.numberRange.megaBall && (
                    <>
                      Mega Ball: {lotteryInfo.numberRange.megaBall.min} - {lotteryInfo.numberRange.megaBall.max}<br />
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </StyledCardContent>
  </StyledCard>
));

const DrawTimes = React.memo(({ drawTimes, odds }) => (
  <StyledCard>
    <StyledCardContent>
      <Typography variant="h6" gutterBottom>
        <AccessTime sx={{ verticalAlign: 'middle', mr: 1 }} />
        Horarios de sorteo
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {drawTimes.map((drawTime, index) => (
          <PulseChip
            key={index}
            icon={<AccessTime />}
            label={`${drawTime.time} - ${drawTime.days.join(', ')}`}
            color="primary"
            variant="outlined"
            aria-label={`Horario de sorteo: ${drawTime.time} - ${drawTime.days.join(', ')}`}
          />
        ))}
      </Box>
      {odds && (
        <>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            <Casino sx={{ verticalAlign: 'middle', mr: 1 }} />
            Probabilidades
          </Typography>
          <Typography variant="body2">
            <EmojiEvents sx={{ verticalAlign: 'middle', mr: 1, color: 'warning.main' }} />
            Jackpot: {odds.jackpot}
          </Typography>
          <Typography variant="body2">
            <EmojiEvents sx={{ verticalAlign: 'middle', mr: 1, color: 'success.main' }} />
            Cualquier Premio: {odds.anyPrize}
          </Typography>
        </>
      )}
    </StyledCardContent>
  </StyledCard>
));

const LotteryResults = React.memo(({ results, lotteryInfo, renderLotteryNumbers, page, handlePageChange }) => (
  <StyledCard>
    <StyledCardContent>
      <Typography variant="h6" gutterBottom>
        <CalendarToday sx={{ verticalAlign: 'middle', mr: 1 }} />
        Últimos Resultados
      </Typography>
      <StyledTableContainer component={Paper}>
        <Table size="small" aria-label="Tabla de resultados recientes">
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Números ganadores</TableCell>
              {lotteryInfo.name === 'Powerball' && <TableCell>Powerball</TableCell>}
              {lotteryInfo.name === 'Mega Millions' && <TableCell>Mega Ball</TableCell>}
              {lotteryInfo.hasMultiplier && <TableCell>Multiplicador</TableCell>}
              {(lotteryInfo.name === 'Powerball' || lotteryInfo.name === 'Mega Millions') && <TableCell>Jackpot</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.slice((page - 1) * 10, page * 10).map((draw, index) => (
              <TableRow key={index}>
                <TableCell>{new Date(draw.draw_date).toLocaleDateString()}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {renderLotteryNumbers(draw)}
                  </Box>
                </TableCell>
                {lotteryInfo.name === 'Powerball' && (
                  <TableCell>
                    <SpecialNumber label={draw.powerball} size="small" />
                  </TableCell>
                )}
                {lotteryInfo.name === 'Mega Millions' && (
                  <TableCell>
                    <SpecialNumber label={draw.mega_ball} size="small" />
                  </TableCell>
                )}
                {lotteryInfo.hasMultiplier && <TableCell>{draw.multiplier}x</TableCell>}
                {(lotteryInfo.name === 'Powerball' || lotteryInfo.name === 'Mega Millions') && (
                  <TableCell>
                    <MonetizationOn sx={{ verticalAlign: 'middle', mr: 1, color: 'success.main' }} />
                    ${draw.jackpot ? draw.jackpot.toLocaleString() : 'N/A'}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={Math.ceil(results.length / 10)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          aria-label="Paginación de resultados"
        />
      </Box>
    </StyledCardContent>
  </StyledCard>
));

const LotteryDetails = ({ lotteryInfo }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lotteryResults, setLotteryResults] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [chartType, setChartType] = useState('bar');
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleError = useCallback((err) => {
    if (err.message.includes('network')) {
      setError('Error de conexión. Por favor, verifica tu conexión a internet.');
    } else if (err.message.includes('API')) {
      setError('Error en la API. Por favor, inténtalo de nuevo más tarde.');
    } else {
      setError(`Error al obtener los resultados: ${err.message}`);
    }
  }, []);

  const fetchLotteryResults = useCallback(async () => {
    if (!lotteryInfo || !lotteryInfo.fetchFunction) {
      setError('Información de lotería no encontrada o función de obtención no disponible');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const results = await lotteryInfo.fetchFunction({ all: true });
      setLotteryResults(results);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }, [lotteryInfo, handleError]);

  useEffect(() => {
    fetchLotteryResults();
  }, [fetchLotteryResults]);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const frequentNumbers = useMemo(() => {
    const numberCounts = {};
    lotteryResults.forEach(draw => {
      if (Array.isArray(draw.winning_numbers)) {
        draw.winning_numbers.forEach(num => {
          numberCounts[num] = (numberCounts[num] || 0) + 1;
        });
      } else if (typeof draw.winning_numbers === 'string') {
        draw.winning_numbers.split(/[,\s]+/).forEach(num => {
          numberCounts[num] = (numberCounts[num] || 0) + 1;
        });
      }
      if (draw.powerball) {
        numberCounts[draw.powerball] = (numberCounts[draw.powerball] || 0) + 1;
      }
      if (draw.mega_ball) {
        numberCounts[draw.mega_ball] = (numberCounts[draw.mega_ball] || 0) + 1;
      }
    });

    return Object.entries(numberCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([number, count]) => ({ number: Number(number), count }));
  }, [lotteryResults]);

  const jackpotTrend = useMemo(() => {
    return lotteryResults.slice(0, 10).map(draw => ({
      date: new Date(draw.draw_date).toLocaleDateString(),
      jackpot: draw.jackpot || 0,
    })).reverse();
  }, [lotteryResults]);

  const renderLotteryNumbers = useCallback((draw) => {
    if (Array.isArray(draw.winning_numbers)) {
      return draw.winning_numbers.map((num, idx) => (
        <LotteryNumber key={idx} label={num} size="small" />
      ));
    } else if (typeof draw.winning_numbers === 'string') {
      return draw.winning_numbers.split(/[,\s]+/).map((num, idx) => (
        <LotteryNumber key={idx} label={num} size="small" />
      ));
    } else if (draw.evening_winning_numbers || draw.midday_winning_numbers) {
      return (
        <>
          {draw.evening_winning_numbers && (
            <Box>
              <Typography variant="caption">Evening:</Typography>
              {(typeof draw.evening_winning_numbers === 'string' 
                ? draw.evening_winning_numbers.split(/[,\s]+/) 
                : Array.isArray(draw.evening_winning_numbers) 
                  ? draw.evening_winning_numbers 
                  : [draw.evening_winning_numbers]
              ).map((num, idx) => (
                <LotteryNumber key={`evening-${idx}`} label={num} size="small" />
              ))}
            </Box>
          )}
          {draw.midday_winning_numbers && (
            <Box>
              <Typography variant="caption">Midday:</Typography>
              {(typeof draw.midday_winning_numbers === 'string' 
                ? draw.midday_winning_numbers.split(/[,\s]+/) 
                : Array.isArray(draw.midday_winning_numbers) 
                  ? draw.midday_winning_numbers 
                  : [draw.midday_winning_numbers]
              ).map((num, idx) => (
                <LotteryNumber key={`midday-${idx}`} label={num} size="small" />
              ))}
            </Box>
          )}
        </>
      );
    }
    return null;
  }, []);

  const latestResult = useMemo(() => {
    return lotteryResults.length > 0 ? lotteryResults[0] : null;
  }, [lotteryResults]);

  const renderLatestNumbers = useCallback(() => {
    if (!latestResult) return null;

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
        {renderLotteryNumbers(latestResult)}
        {lotteryInfo.name === 'Powerball' && latestResult.powerball && (
          <SpecialNumber label={latestResult.powerball} size="small" />
        )}
        {lotteryInfo.name === 'Mega Millions' && latestResult.mega_ball && (
          <SpecialNumber label={latestResult.mega_ball} size="small" />
        )}
        {lotteryInfo.hasMultiplier && latestResult.multiplier && (
          <Chip label={`x${latestResult.multiplier}`} size="small" color="secondary" />
        )}
      </Box>
    );
  }, [latestResult, lotteryInfo, renderLotteryNumbers]);

  const renderLoadingSkeleton = () => (
    <Box sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
      <Skeleton variant="rectangular" height={48} sx={{ mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={150} />
        </Grid>
      </Grid>
    </Box>
  );

  const renderContent = useMemo(() => {
    switch (tabValue) {
      case 0:
        return (
          <Fade in={tabValue === 0}>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <GeneralInfo lotteryInfo={lotteryInfo} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DrawTimes drawTimes={lotteryInfo.drawTimes} odds={lotteryInfo.odds} />
                </Grid>
                {lotteryInfo.details && (
                  <Grid item xs={12}>
                    <StyledCard>
                      <StyledCardContent>
                        <Typography variant="h6" gutterBottom>
                          <InfoIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                          Detalles
                        </Typography>
                        <Typography variant="body2">{lotteryInfo.details.description}</Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>{lotteryInfo.details.jackpotInfo}</Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>{lotteryInfo.details.drawInfo}</Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>{lotteryInfo.details.multiplierInfo}</Typography>
                      </StyledCardContent>
                    </StyledCard>
                  </Grid>
                )}
              </Grid>
            </div>
          </Fade>
        );
      case 1:
        return (
          <Fade in={tabValue === 1}>
            <div>
              <LotteryResults 
                results={lotteryResults} 
                lotteryInfo={lotteryInfo} 
                renderLotteryNumbers={renderLotteryNumbers}
                page={page}
                handlePageChange={handlePageChange}
              />
            </div>
          </Fade>
        );
      case 2:
        return (
          <Fade in={tabValue === 2}>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <StyledCardContent>
                      <Typography variant="h6" gutterBottom>
                        <BarChartIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Números más Frecuentes
                      </Typography>
                      <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        <AnimatedButton onClick={() => setChartType('bar')} startIcon={<BarChartIcon />}>
                          {isSmallScreen ? 'Barra' : 'Gráfico de Barras'}
                        </AnimatedButton>
                        <AnimatedButton onClick={() => setChartType('line')} startIcon={<ShowChart />}>
                          {isSmallScreen ? 'Línea' : 'Gráfico de Líneas'}
                        </AnimatedButton>
                        <AnimatedButton onClick={() => setChartType('pie')} startIcon={<PieChartIcon />}>
                          {isSmallScreen ? 'Circular' : 'Gráfico Circular'}
                        </AnimatedButton>
                      </Box>
                      <ChartComponent chartType={chartType} data={frequentNumbers} colors={COLORS} />
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
                {(lotteryInfo.name === 'Powerball' || lotteryInfo.name === 'Mega Millions') && (
                  <Grid item xs={12} md={6}>
                    <StyledCard>
                      <StyledCardContent>
                        <Typography variant="h6" gutterBottom>
                          <TrendingUp sx={{ verticalAlign: 'middle', mr: 1 }} />
                          Tendencia del Jackpot
                        </Typography>
                        <JackpotTrendChart data={jackpotTrend} />
                      </StyledCardContent>
                    </StyledCard>
                  </Grid>
                )}
              </Grid>
            </div>
          </Fade>
        );
      case 3:
        return (
          <Fade in={tabValue === 3}>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <StyledCardContent>
                      <Typography variant="h6" gutterBottom>
                        <HelpOutline sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Cómo Jugar
                      </Typography>
                      <List>
                        {lotteryInfo.howToPlay.map((step, index) => (
                          <ListItem key={index}>
                            <ListItemText primary={step} />
                          </ListItem>
                        ))}
                      </List>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
                {lotteryInfo.multiplierRules && (
                  <Grid item xs={12} md={6}>
                    <StyledCard>
                      <StyledCardContent>
                        <Typography variant="h6" gutterBottom>
                          <Casino sx={{ verticalAlign: 'middle', mr: 1 }} />
                          Reglas del Multiplicador
                        </Typography>
                        <List>
                          {lotteryInfo.multiplierRules.map((rule, index) => (
                            <ListItem key={index}>
                              <ListItemText primary={rule} />
                            </ListItem>
                          ))}
                        </List>
                      </StyledCardContent>
                    </StyledCard>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <StyledCard>
                    <StyledCardContent>
                      <Typography variant="h6" gutterBottom>
                        <InfoIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Información Adicional
                      </Typography>
                      {lotteryInfo.claimPeriod && (
                        <Typography variant="body2">
                          <AccessTime sx={{ verticalAlign: 'middle', mr: 1 }} />
                          Período de Reclamo: {lotteryInfo.claimPeriod}
                        </Typography>
                      )}
                      {lotteryInfo.taxInfo && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          <MonetizationOn sx={{ verticalAlign: 'middle', mr: 1 }} />
                          Información de Impuestos: {lotteryInfo.taxInfo}
                        </Typography>
                      )}
                      {lotteryInfo.officialWebsite && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          <Language sx={{ verticalAlign: 'middle', mr: 1 }} />
                          Sitio Web Oficial: <Link href={lotteryInfo.officialWebsite} target="_blank" rel="noopener noreferrer">{lotteryInfo.officialWebsite}</Link>
                        </Typography>
                      )}
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            </div>
          </Fade>
        );
      default:
        return null;
    }
  }, [tabValue, lotteryInfo, lotteryResults, renderLotteryNumbers, page, handlePageChange, frequentNumbers, jackpotTrend, isSmallScreen, chartType]);

  if (loading) {
    return renderLoadingSkeleton();
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4" component="h1" align="center">
          {lotteryInfo.name}
        </Typography>
        {latestResult && (
          <Box sx={{ textAlign: 'center', mt: 1 }}>
            <Typography variant="subtitle1">
              Último Resultado ({new Date(latestResult.draw_date).toLocaleDateString()}):
            </Typography>
            {renderLatestNumbers()}
          </Box>
        )}
      </Box>

      <StyledTabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        aria-label="Tabs de información de lotería"
      >
        <Tab label={isSmallScreen ? 'Info' : 'Información'} icon={<InfoIcon />} aria-label="Información" />
        <Tab label={isSmallScreen ? 'Resultados' : 'Resultados'} icon={<CalendarToday />} aria-label="Resultados" />
        <Tab label={isSmallScreen ? 'Stats' : 'Estadísticas'} icon={<BarChartIcon />} aria-label="Estadísticas" />
        {lotteryInfo.howToPlay && <Tab label={isSmallScreen ? 'Jugar' : 'Cómo Jugar'} icon={<HelpOutline />} aria-label="Cómo Jugar" />}
      </StyledTabs>

      <Box sx={{ mt: 3 }}>
        {renderContent}
      </Box>
    </Box>
  );
};

export default React.memo(LotteryDetails);