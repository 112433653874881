import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Typography, 
  Box, 
  Container, 
  Paper,
  Snackbar,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { addJackpot } from '../services/jackpotapi';

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 200,
}));

const AddJackpotForm = () => {
  const [lotteryType, setLotteryType] = useState('');
  const [drawDate, setDrawDate] = useState('');
  const [jackpotAmount, setJackpotAmount] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const jackpot = Number(jackpotAmount);
      if (isNaN(jackpot) || jackpot <= 0) {
        throw new Error('El monto del jackpot debe ser un número válido mayor a cero.');
      }

      const result = await addJackpot(lotteryType, drawDate, jackpot);
      setSuccessMessage(result.message);
      setErrorMessage('');
      setLotteryType('');
      setDrawDate('');
      setJackpotAmount('');
    } catch (error) {
      const errorResponse = error.response?.data?.message || error.message;
      setErrorMessage(`Error: ${errorResponse}`);
      setSuccessMessage('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Agregar Jackpot
        </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <StyledFormControl>
            <InputLabel id="lottery-type-label">Tipo de Lotería</InputLabel>
            <Select
              labelId="lottery-type-label"
              value={lotteryType}
              onChange={(e) => setLotteryType(e.target.value)}
              required
              label="Tipo de Lotería"
            >
              <MenuItem value="">Selecciona una opción</MenuItem>
              <MenuItem value="nylotto">New York Lotto</MenuItem>
              <MenuItem value="LotomasLeidsa">Loto - Super Loto Más</MenuItem>
              <MenuItem value="LotoPoolLeidsa">Loto Pool</MenuItem>
              <MenuItem value="Pega4Real">Pega 4 Real</MenuItem>
              <MenuItem value="megamillions">Mega Millions</MenuItem>
              <MenuItem value="powerball">Powerball</MenuItem>
              <MenuItem value="cash4life">Cash4Life</MenuItem>
            </Select>
          </StyledFormControl>
          <TextField
            type="date"
            label="Fecha del Sorteo"
            value={drawDate}
            onChange={(e) => setDrawDate(e.target.value)}
            required
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="number"
            label="Jackpot (Monto)"
            value={jackpotAmount}
            onChange={(e) => setJackpotAmount(e.target.value)}
            required
            inputProps={{ min: "1" }}
          />
          <Box sx={{ position: 'relative' }}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              disabled={isSubmitting}
              fullWidth
            >
              {isSubmitting ? 'Enviando...' : 'Agregar Jackpot'}
            </Button>
            {isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </StyledForm>
      </Paper>
      <Snackbar
        open={!!successMessage || !!errorMessage}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage('');
          setErrorMessage('');
        }}
        message={successMessage || errorMessage}
        ContentProps={{
          sx: { 
            backgroundColor: successMessage ? 'green' : 'red'
          }
        }}
      />
    </Container>
  );
};

export default AddJackpotForm;