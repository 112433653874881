import React from 'react';
import { 
  Typography, 
  Container, 
  Box,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import LoteriaPremium from '../components/wingames/LoteriaPremium';

const StyledContainer = styled(Container)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  minHeight: '100vh',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
  fontSize: '1.5rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
}));

export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledContainer maxWidth="lg" disableGutters={isMobile}>
      <Box sx={{ width: '100%' }}>
        <StyledTypography >

        </StyledTypography>
        <LoteriaPremium />
      </Box>
    </StyledContainer>
  );
}