import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme, useMediaQuery, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import kingLotteryImage from '../Imagen/kinglottery.png';
import laSuerteDominicanaImage from '../Imagen/lasuertedominicana.png';
import leidsaImage from '../Imagen/leidsa.jpeg';
import lotekaImage from '../Imagen/loteka.jpg';
import loteriaRealImage from '../Imagen/loteriareal.jpeg';
import loteriaAnguilaImage from '../Imagen/loteriaanguila.jpg';
import loteriaNacionalImage from '../Imagen/loterianacional.jpg';
import usaImage from '../Imagen/usa.jpg';
import lotedomImage from '../Imagen/lotedom.jpg';
import laPrimeraImage from '../Imagen/laprimera.jpeg';

const SliderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  padding: theme.spacing(2, 0),
  marginBottom: theme.spacing(0.5),
}));

const SliderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const SliderItem = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: '25%', // Set to 25% to show 4 items
  scrollSnapAlign: 'start',
  padding: theme.spacing(0, 0.5),
  transition: 'transform 0.2s ease-in-out',
  '&:active': {
    transform: 'scale(0.95)',
  },
}));

const CircularImageContainer = styled(Box)(({ theme }) => ({
  width: '62px',
  height: '62px',
  borderRadius: '50%',
  padding: '3px',
  background: 'linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '2px',
    left: '2px',
    right: '2px',
    bottom: '2px',
    borderRadius: '50%',
    background: theme.palette.background.paper,
  },
}));

const CircularImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '50%',
  position: 'relative',
  zIndex: 1,
});

const LotteryName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  fontSize: '0.7rem',
  fontWeight: '500',
  textAlign: 'center',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.text.primary,
}));

const lotteries = [
  { image: kingLotteryImage, route: '/king-lottery', name: 'King Lottery' },
  { image: laSuerteDominicanaImage, route: '/la-suerte-dominicana', name: 'La Suerte Dominicana' },
  { image: leidsaImage, route: '/leidsa', name: 'Leidsa' },
  { image: lotekaImage, route: '/loteka', name: 'Loteka' },
  { image: loteriaRealImage, route: '/loteria-real', name: 'Lotería Real' },
  { image: loteriaAnguilaImage, route: '/anguila', name: 'Lotería Anguila' },
  { image: loteriaNacionalImage, route: '/loteria-nacional', name: 'Lotería Nacional' },
  { image: usaImage, route: '/americanas', name: 'Americanas' },
  { image: lotedomImage, route: '/lotedom', name: 'Lotedom' },
  { image: laPrimeraImage, route: '/la-primera', name: 'La Primera' },
];

const LotterySlider = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      const handleWheel = (e) => {
        e.preventDefault();
        slider.scrollLeft += e.deltaY;
      };
      slider.addEventListener('wheel', handleWheel, { passive: false });
      return () => slider.removeEventListener('wheel', handleWheel);
    }
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleItemClick = (route) => {
    navigate(route);
  };

  if (!isMobile) {
    return <div inert></div>;
  }  

  return (
    <SliderContainer>
      <SliderWrapper
        ref={sliderRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleMouseUp}
      >
        {lotteries.map((lottery, index) => (
          <SliderItem
            key={index}
            onClick={() => handleItemClick(lottery.route)}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CircularImageContainer>
                <CircularImage
                  src={lottery.image}
                  alt={`Logo de ${lottery.name}`}
                />
              </CircularImageContainer>
              <LotteryName variant="body2">{lottery.name}</LotteryName>
            </Box>
          </SliderItem>
        ))}
      </SliderWrapper>
      <Divider sx={{ mt: 0.5 }} />
    </SliderContainer>
  );
};

export default LotterySlider;