import axios from 'axios';

// Define the base URL of the API
const API_URL = 'https://www.tutawin.com/api';

// Custom header to identify legitimate requests from the frontend
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'x-requested-with': 'TutawinFrontend'
  }
});

// Generic function to handle errors
function handleError(error) {
  if (error.response) {
    console.error('Server response error:', error.response.data);
    throw new Error('Server error while fetching lottery results');
  } else if (error.request) {
    console.error('No response from server:', error.request);
    throw new Error('No response from server while fetching lottery results');
  } else {
    console.error('Request setup error:', error.message);
    throw new Error('Request error while fetching lottery results');
  }
}

// Generic function to fetch lottery results
async function fetchLotteryResults(endpoint, date, all, limit) {
  try {
    const response = await axiosInstance.get(endpoint, {
      params: { date, all, limit }
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

// Functions to fetch results for specific lotteries
export const getNYLotto = (date, all, limit) => fetchLotteryResults('/nylotto', date, all, limit);
export const getPick10 = (date, all, limit) => fetchLotteryResults('/pick10', date, all, limit);
export const getTake5 = (date, all, limit) => fetchLotteryResults('/take5', date, all, limit);
export const getWin4NY = (date, all, limit) => fetchLotteryResults('/win4ny', date, all, limit);
export const getMegaMillions = (date, all, limit) => fetchLotteryResults('/megamillions', date, all, limit);
export const getPowerball = (date, all, limit) => fetchLotteryResults('/powerball', date, all, limit);
export const getCash4life = (date, all, limit) => fetchLotteryResults('/cash4life', date, all, limit);

// Assign the object to a variable before exporting as default
const lotteryAPI = {
  getNYLotto,
  getPick10,
  getTake5,
  getWin4NY,
  getMegaMillions,
  getPowerball,
  getCash4life
};

export default lotteryAPI;