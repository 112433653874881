import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, useTheme, CssBaseline } from '@mui/material';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import LotterySlider from './LotterySlider';
import Hotdaynumber from './Hotdaynumber';
import ResponsibleGamingMessage from './ResponsibleGamingMessage';

const SIDEBAR_WIDTH = 240; // Fixed width for the sidebar

export default function Layout({ children }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSidebarOpen, setIsSidebarOpen] = useState(isLargeScreen);

  useEffect(() => {
    setIsSidebarOpen(isLargeScreen);
  }, [isLargeScreen]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: `linear-gradient(180deg, rgba(245, 245, 245, 0.9) 0%, rgb(200, 220, 240) 40%, rgba(230, 230, 230, 1) 100%)`,
        backgroundAttachment: 'fixed',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />
      <Navbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <Sidebar open={isSidebarOpen} onToggle={toggleSidebar} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'auto',
          mt: { xs: '54px', sm: '64px' },
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(0,0,0,0.1)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(100,100,100,0.5)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(100,100,100,0.7)',
          },
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(100,100,100,0.5) rgba(0,0,0,0.1)',
        }}
      >
        {isMobile && (
          <Box sx={{ width: '100%' }}>
            <LotterySlider />
          </Box>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { xs: 0, sm: 3 },
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: {
              xs: 0,
              md: isSidebarOpen ? `${SIDEBAR_WIDTH}px` : 0,
            },
            width: {
              xs: '100%',
              md: `calc(100% - ${isSidebarOpen ? SIDEBAR_WIDTH : 0}px)`,
            },
          }}
        >
          <Hotdaynumber />
          <Box
            sx={{
              width: '100%',
              '& > *': {
                width: '100%',
                maxWidth: '100%',
                borderRadius: { xs: 0, sm: theme.shape.borderRadius },
                my: { xs: 2, sm: 3 },
                '&:first-of-type': {
                  mt: 0,
                },
                '&:last-child': {
                  mb: 0,
                },
              },
            }}
          >
            {children}
          </Box>
          <ResponsibleGamingMessage />
        </Box>
      </Box>
    </Box>
  );
}