import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { 
  Typography, 
  Alert, 
  Grid, 
  Box, 
  Skeleton, 
  Container, 
  Divider, 
  Tooltip, 
  Button,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import EventIcon from '@mui/icons-material/Event';
import TimerProgressBar from '../components/TimerProgressBar';
import JackpotDisplay from '../components/JackpotDisplay';
import LotteryLogo from '../components/LotteryLogo';
import CountryInfo from '../components/CountryInfo';
import { lotteries } from '../components/LotteryData';

const StyledCard = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.06)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  background: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2), 0 1px 10px rgba(0, 0, 0, 0.12)',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0 auto',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#47515a',
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
}));

const HeaderContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const LotteryInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

const LotteryTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '0.985rem',
  textAlign: 'right',
}));

const StyledResultNumber = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSpecial' && prop !== 'isMultiplier'
})(({ theme, isSpecial, isMultiplier }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  fontWeight: 'bold',
  fontSize: '1rem',
  borderRadius: '50%',
  position: 'relative',
  overflow: 'hidden',
  background: isSpecial
    ? 'linear-gradient(135deg, #FFD700 0%, #FFA500 100%)'
    : isMultiplier
    ? 'linear-gradient(135deg, #4CAF50 0%, #45a049 100%)'
    : 'linear-gradient(135deg, #1976d2 0%, #3C3B6E 100%)',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const ResultContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  backgroundColor: 'transparent',
  margin: theme.spacing(1, 0),
}));

const SpecialNumberLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.6rem',
  fontWeight: 'bold',
  textAlign: 'center',
  marginTop: theme.spacing(0.5),
  color: theme.palette.text.secondary,
}));


const LotteryCard = React.memo(({ result, formatDate }) => {
  if (!result || !result.numbers) {
    return (
      <StyledCard>
        <Box p={2}>
          <Typography>No data available</Typography>
        </Box>
      </StyledCard>
    );
  }

  const renderNumbers = () => {
    if (result.name === 'Take 5' || result.name === 'Win 4 NY') {
      return (
        <>
          <ResultContainer>
            <Typography variant="subtitle2" fontWeight="bold" mb={1}>Evening</Typography>
            <Box display="flex" justifyContent="center" flexWrap="wrap">
              {result.name === 'Take 5' 
                ? result.numbers.evening.map((number, idx) => (
                    <StyledResultNumber key={`evening-${idx}`}>
                      {number}
                    </StyledResultNumber>
                  ))
                : String(result.numbers.evening).split('').map((digit, idx) => (
                    <StyledResultNumber key={`evening-${idx}`}>
                      {digit}
                    </StyledResultNumber>
                  ))
              }
            </Box>
          </ResultContainer>
          <ResultContainer>
            <Typography variant="subtitle2" fontWeight="bold" mb={1}>Midday</Typography>
            <Box display="flex" justifyContent="center" flexWrap="wrap">
              {result.name === 'Take 5'
                ? result.numbers.midday.map((number, idx) => (
                    <StyledResultNumber key={`midday-${idx}`}>
                      {number}
                    </StyledResultNumber>
                  ))
                : String(result.numbers.midday).split('').map((digit, idx) => (
                    <StyledResultNumber key={`midday-${idx}`}>
                      {digit}
                    </StyledResultNumber>
                  ))
              }
            </Box>
          </ResultContainer>
        </>
      );
    } else {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {result.numbers.map((number, idx) => {
              let isSpecial = false;
              let isMultiplier = false;
              let label = '';

              if (result.name === 'Powerball' && idx === 5) {
                isSpecial = true;
                label = 'Powerball';
              } else if (result.name === 'Powerball' && idx === 6) {
                isMultiplier = true;
                label = 'Power Play';
              } else if (result.name === 'Mega Millions' && idx === 5) {
                isSpecial = true;
                label = 'Mega Ball';
              } else if (result.name === 'Mega Millions' && idx === 6) {
                isMultiplier = true;
                label = 'Megaplier';
              } else if (result.name === 'Cash4Life' && idx === 5) {
                isSpecial = true;
                label = 'Cash Ball';
              } else if (result.name === 'New York Lotto' && idx === 6) {
                isSpecial = true;
                label = 'Bonus Number';
              }

              return (
                <Tooltip key={idx} title={label || `Número ${idx + 1}`} arrow>
                  <Box display="flex" flexDirection="column" alignItems="center" m={0.5}>
                    <StyledResultNumber 
                      isSpecial={isSpecial}
                      isMultiplier={isMultiplier}
                    >
                      {number}
                    </StyledResultNumber>
                    {label && <SpecialNumberLabel>{label}</SpecialNumberLabel>}
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
        </Box>
      );
    }
  };

  const hasJackpot = ['Powerball', 'Mega Millions', 'Cash4Life', 'New York Lotto'].includes(result.name);

  return (
    <StyledCard>
      <Link to={`/lottery/${encodeURIComponent(result.name.toLowerCase().replace(/\s+/g, '-'))}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <CardHeader>
          <HeaderContent>
            <CountryInfo country={result.country} />
          </HeaderContent>
          <LotteryInfo>
            <LotteryTitle variant="h6" component="h2">
              {result.name}
            </LotteryTitle>
            {hasJackpot && <JackpotDisplay name={result.name} />}
          </LotteryInfo>
        </CardHeader>
      </Link>
      <Box p={2} display="flex" flexDirection="column" alignItems="center">
        <Link to={`/lottery/${encodeURIComponent(result.name.toLowerCase().replace(/\s+/g, '-'))}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <LotteryLogo name={result.name} />
        </Link>
        
        <Divider sx={{ width: '100%', my: 2 }} />
        
        <Typography variant="subtitle1" fontWeight="bold" mb={1}>
          Último Resultado
        </Typography>
        
        <Box display="flex" alignItems="center" mb={2}>
          <EventIcon sx={{ mr: 1 }} />
          <Typography variant="body2">
            {formatDate(result.date)}
          </Typography>
        </Box>
        
        <Box sx={{ mt: 2, mb: 2 }}>
          {renderNumbers()}
        </Box>
        
        <Divider sx={{ width: '100%', my: 2 }} />
        
        <Box sx={{ width: '100%' }}>
          <TimerProgressBar 
            drawTimes={result.drawTimes} 
            name={result.name}
          />
        </Box>
      </Box>
    </StyledCard>
  );
});

const SkeletonLotteryCard = () => {
  return (
    <StyledCard>
      <CardHeader>
        <Skeleton variant="rectangular" width={120} height={30} />
        <Skeleton variant="rectangular" width={100} height={30} />
      </CardHeader>
      <Box p={2} display="flex" flexDirection="column" alignItems="center">
        <Skeleton variant="circular" width={80} height={80} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="80%" height={30} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="60%" height={24} sx={{ mb: 2 }} />
        <Box display="flex" flexWrap="wrap" justifyContent="center" my={2}>
          {[...Array(6)].map((_, idx) => (
            <Skeleton key={idx} variant="circular" width={40} height={40} sx={{ m: 0.5 }} />
          ))}
        </Box>
        <Skeleton variant="rectangular" width="100%" height={60} sx={{ mt: 2 }} />
      </Box>
    </StyledCard>
  );
};

const Americanas = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const americanLotteries = useMemo(() => [
    'Powerball',
    'Mega Millions',
    'Cash4Life',
    'Pick 10',
    'Take 5',
    'Win 4 NY',
    'New York Lotto',
    'New York Tarde',
    'New York Noche',
    'Florida Día',
    'Florida Noche'
  ], []);

  const parseNumbers = useCallback((numbersString) => {
    if (typeof numbersString === 'string') {
      return numbersString.split(/[,\s]+/).map(Number);
    } else if (Array.isArray(numbersString)) {
      return numbersString.map(Number);
    }
    return [];
  }, []);

  const formatDate = useCallback((dateString) => {
    if (!dateString || dateString === 'No disponible') return 'Fecha no disponible';
    try {
      // First, try to parse the date assuming it's in DD-MM-YYYY format
      let [day, month, year] = dateString.split('-');
      let date = new Date(year, month - 1, day);

      // If the date is invalid, try parsing it as YYYY-MM-DD
      if (isNaN(date.getTime())) {
        [year, month, day] = dateString.split('-');
        date = new Date(year, month - 1, day);
      }

      // If it's still invalid, throw an error
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }

      const formatter = new Intl.DateTimeFormat('es-ES', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      return formatter.format(date);
    } catch (error) {
      console.error('Error al formatear la fecha:', error, 'Fecha recibida:', dateString);
      return 'Fecha inválida';
    }
  }, []);

  const processLotteryData = useCallback((lottery, latestDraw) => {
    let numbers, date;

    switch (lottery.name) {
      case 'Powerball':
        numbers = [
          ...parseNumbers(latestDraw.winning_numbers),
          Number(latestDraw.powerball),
          Number(latestDraw.multiplier)
        ];
        date = latestDraw.draw_date;
        break;
      case 'Mega Millions':
        numbers = [
          ...parseNumbers(latestDraw.winning_numbers),
          Number(latestDraw.mega_ball),
          Number(latestDraw.multiplier)
        ];
        date = latestDraw.draw_date;
        break;
      case 'Cash4Life':
        numbers = [
          ...parseNumbers(latestDraw.winning_numbers),
          Number(latestDraw.cash_ball)
        ];
        date = latestDraw.draw_date;
        break;
      case 'New York Lotto':
        numbers = [
          ...parseNumbers(latestDraw.winning_numbers),
          Number(latestDraw.bonus_number)
        ];
        date = latestDraw.draw_date;
        break;
      case 'Take 5':
        numbers = {
          evening: parseNumbers(latestDraw.evening_winning_numbers),
          midday: parseNumbers(latestDraw.midday_winning_numbers)
        };
        date = latestDraw.draw_date;
        break;
      case 'Win 4 NY':
        numbers = {
          evening: latestDraw.numeros_ganadores_evening ? latestDraw.numeros_ganadores_evening[0] : '',
          midday: latestDraw.numeros_ganadores_midday ? latestDraw.numeros_ganadores_midday[0] : ''
        };
        date = latestDraw.fecha_sorteo;
        break;
      case 'Pick 10':
        numbers = parseNumbers(latestDraw.winning_numbers);
        date = latestDraw.draw_date;
        break;
      case 'New York Tarde':
      case 'New York Noche':
      case 'Florida Día':
      case 'Florida Noche':
        numbers = parseNumbers(latestDraw.numbers || latestDraw.winning_numbers || latestDraw.numeros_ganadores);
        date = latestDraw.date || latestDraw.draw_date || latestDraw.fecha_sorteo;
        break;
      default:
        numbers = parseNumbers(latestDraw.winning_numbers);
        date = latestDraw.draw_date;
    }

    console.log(`Fecha original para ${lottery.name}:`, date);
    console.log(`Fecha formateada para ${lottery.name}:`, formatDate(date));

    return {
      ...lottery,
      numbers,
      date,
    };
  }, [parseNumbers, formatDate]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const fetchedResults = await Promise.all(
          americanLotteries.map(async (lotteryName) => {
            try {
              let data;
              const lottery = lotteries.find(l => l.name === lotteryName);
              if (lottery && lottery.fetchFunction) {
                data = await lottery.fetchFunction();
              }

              if (!data || (Array.isArray(data) && data.length === 0)) {
                console.error(`No data returned for lottery ${lotteryName}`);
                return null;
              }

              const latestDraw = Array.isArray(data) ? data[0] : data;
              console.log(`Datos crudos para ${lotteryName}:`, latestDraw);
              return processLotteryData(lottery, latestDraw);
            } catch (error) {
              console.error(`Error fetching data for lottery ${lotteryName}:`, error);
              return null;
            }
          })
        );
        const validResults = fetchedResults.filter(result => result !== null);
        console.log('Valid results:', validResults);
        setResults(validResults);
      } catch (error) {
        setError('Error al obtener los resultados de las loterías Americanas. Intenta nuevamente más tarde.');
        console.error('Error fetching Americanas results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [americanLotteries, processLotteryData]);

  return (
    <Container maxWidth="lg" disableGutters={isMobile}>
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        p: { xs: 1, sm: 3 },
        minHeight: '100vh',
      }}>
        <Typography 
          variant="h2" 
          component="h1"
          gutterBottom 
          align="center"
          sx={{ 
            mb: 4, 
            fontWeight: 'bold', 
            color: 'rgb(58, 71, 84)',
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          Resultados de Loterías Americanas
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : (
          <Grid container spacing={isMobile ? 2 : 3} justifyContent="center">
            {loading
              ? Array.from({ length: 11 }).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <SkeletonLotteryCard />
                  </Grid>
                ))
              : results.map((result, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <LotteryCard result={result} formatDate={formatDate} />
                  </Grid>
                ))
            }
          </Grid>
        )}
        <Divider sx={{ my: 3 }} />
        <Box mt={4} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            endIcon={<LaunchIcon />}
            href="https://www.usamega.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visitar sitio oficial de USA Mega
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Americanas;