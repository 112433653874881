import React from 'react';
import { useParams } from 'react-router-dom';
import { 
  Typography, 
  Box, 
  Container, 
  ThemeProvider, 
  createTheme, 
  CssBaseline,
  useMediaQuery,
  Alert,
  Paper,
  Fade
} from '@mui/material';
import LotteryDetails from './LotteryDetails';
import { lotteries } from './LotteryData';
import LotteryLogo from './LotteryLogo';
import CountryInfo from './CountryInfo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4a90e2',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f7fa',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: 1.2,
      letterSpacing: '-0.01562em',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#f5f7fa',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
          },
        },
      },
    },
  },
});

const LotteryStatistics = () => {
  const { slug } = useParams();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const lotteryInfo = lotteries.find(
    lottery => lottery.slug === slug
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Fade in={true} timeout={1000}>
          <Paper
            elevation={3}
            sx={{
              py: { xs: 4, md: 6 },
              px: { xs: 2, md: 3 },
              borderRadius: '16px',
              mt: 4,
              mb: 4,
              overflow: 'hidden',
            }}
          >
            {!lotteryInfo ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                Error: Lotería no encontrada
              </Alert>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 0,
                  }}
                >
                  <LotteryLogo lotteryName={lotteryInfo.name} width={80} height={80} />
                  <Box sx={{ ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 }, textAlign: { xs: 'center', sm: 'left' } }}>
                    <Typography 
                      variant="h4" 
                      component="h1" 
                      gutterBottom 
                      sx={{
                        fontSize: isSmallScreen ? '1.8rem' : '2.2rem',
                        color: theme.palette.primary.main,
                        textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                      }}
                    >
                      Estadísticas de {lotteryInfo.name}
                    </Typography>
                    <CountryInfo 
                      countryCode={lotteryInfo.countryCode || 'US'} // Valor por defecto si no está definido
                      countryName={lotteryInfo.country} 
                    />
                  </Box>
                </Box>
                <LotteryDetails lotteryInfo={lotteryInfo} />
              </>
            )}
          </Paper>
        </Fade>
      </Container>
    </ThemeProvider>
  );
};

export default LotteryStatistics;
