import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

// Importar las imágenes de las banderas
import drFlag from '../Imagen/dr.jpg';
import usaFlag from '../Imagen/usa.jpg';
import anguilaFlag from '../Imagen/anguila.png';
import sanMartinFlag from '../Imagen/sanmartin.png';

const FlagContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: '50%',
  overflow: 'hidden',
  marginRight: theme.spacing(1),
}));

const FlagImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const countryInfo = {
  'República Dominicana': {
    capital: 'Santo Domingo',
    population: '10.8 millones',
    language: 'Español',
    currency: 'Peso dominicano',
    flag: drFlag,
  },
  'Estados Unidos': {
    capital: 'Washington, D.C.',
    population: '331 millones',
    language: 'Inglés',
    currency: 'Dólar estadounidense',
    flag: usaFlag,
  },
  'Anguila': {
    capital: 'The Valley',
    population: '15,000',
    language: 'Inglés',
    currency: 'Dólar del Caribe Oriental',
    flag: anguilaFlag,
  },
  'San Martín': {
    capital: 'Philipsburg',
    population: '41,000',
    language: 'Neerlandés, Inglés',
    currency: 'Florín antillano neerlandés',
    flag: sanMartinFlag,
  },
};

const CountryInfo = ({ country, showName = false }) => {
  const info = countryInfo[country];

  if (!info) {
    return null;
  }

  return (
    <Tooltip
      title={
        <React.Fragment>
          <Typography color="inherit">País: {country}</Typography>
          <Typography color="inherit">Capital: {info.capital}</Typography>
          <Typography color="inherit">Población: {info.population}</Typography>
          <Typography color="inherit">Idioma: {info.language}</Typography>
          <Typography color="inherit">Moneda: {info.currency}</Typography>
        </React.Fragment>
      }
      arrow
    >
      <Box display="flex" alignItems="center">
        <FlagContainer>
          <FlagImage src={info.flag} alt={`Bandera de ${country}`} />
        </FlagContainer>
        {showName && <Typography variant="body2">{country}</Typography>}
      </Box>
    </Tooltip>
  );
};

export default CountryInfo;